import { modeChoose } from "../../../../../../../shared_lsi_sg/modeDataForm";
import { createModeValidation } from "./commonFunctionValidateTemplate";

export const useValidationSleep = (t: any, dataForm: any) => {
  const validations = [
    ...(modeChoose[dataForm?.mode] ? createModeValidation(dataForm?.mode, t) : [])
  ];

  return validations;

};
