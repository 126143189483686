import React from "react";
import HeadingH3 from "../../../../shared/ui/headings/headingH3/HeadingH3";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function TotalOrder({ total }) {
  const { t } = useLanguageForShared();

  return (
    <HeadingH3 className="">
      <span className="me-5">{t("sg.totOrder")}</span>
      <span className="me-l">€{total.toFixed(2)}</span>
    </HeadingH3>
  );
}
