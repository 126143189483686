import React from "react";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function TotalProduct({ total }) {
	const { t } = useLanguageForShared();

	return (
		<HeadingH4>
			<span className="mr-5">{t("sg.totProducts")} </span>
			<span className="ms-5">€{total.toFixed(2)}</span>
		</HeadingH4>
	);
}
