import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {useDispatch} from "react-redux";
import {setIsLsi} from "../../../redux/order/orderSlice";
import {setPatient} from "../../../redux/patient/patientSlice";
import {setEditOrderDataAction} from "../../../redux/order/orderActions";
import * as routeName from "../../routes/routeNameAuthType";
import {getOrder} from "../../../redux/order/orderCrud";
import {setErrorTypeAction} from "../../../redux/errors/errorsActions";
import {useRoute} from "../../utils/useRoute";
import {clearOrder} from "../../utils/ordersUtils";
import {GetPatientAction} from "../../../redux/patient/patientActions";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function RedirectEditOrderPage({ history }) {
  const { t } = useLanguageForShared();

  const {id, type} = useParams()
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  useEffect(() => {
    //todo Massy qui sarà da effettuare la nuova chiamata per recuperare i dati dell'ordine
    getOrder(id)
        .then((res) => {
          setLoadOrder(clearOrder(res));
        })
        .catch((error) => {
          dispatch(setErrorTypeAction(t('sg.msg.errorSearchOrder')));
        });
  }, [id])
  const setLoadOrder = (order) =>{
    console.log(document.referrer);
    dispatch(setIsLsi())
    dispatch(GetPatientAction(t, order.dettaglioOrdine.patient.id));
    dispatch(setEditOrderDataAction({ ...order, type }));
    changeRoute(routeName.selectEditProduct, undefined, undefined, true);
  }
  return (
    <div className={'text-uppercase'}>{t('sg.loading')} {id}</div>
  );
}
