import moment from 'moment'

export const stringDateToTimestamp = (stringDate: any): any =>
  typeof stringDate === 'string' ? new Date(stringDate).getTime() : stringDate

export const getTodayDate = () => new Date()
export const getNBackDate = (n: number) => {
  return new Date(getTodayDate().setDate(getTodayDate().getDate() - n))
}

export const getTodayTimestamp = () => stringDateToTimestamp(getTodayDate())

export const isSmallestDate = (date1: any, date2: any) => {
  return stringDateToTimestamp(date2) > stringDateToTimestamp(date1)
}

export const copyDate = (date: any) => {
  return new Date(date)
}


export const isOldestDate = (date1: any, date2: any) => {
  return stringDateToTimestamp(date2) < stringDateToTimestamp(date1)
}
export const minDate = (date1: any, date2: any) => {
  return stringDateToTimestamp(date2) < stringDateToTimestamp(date1)
    ? date2
    : date1
}
export const maxDate = (date1: any, date2: any) => {
  return stringDateToTimestamp(date2) > stringDateToTimestamp(date1)
    ? date2
    : date1
}
export const noZeroString = (value: string) => parseInt(value, 10).toString()
export const padNumber = (value: number) => `0${value}`.slice(-2)
export const padNumberYear = (value: number) => `0000${value}`.slice(-4)

export const localdateString = (date: string | number | Date) => {
  return new Date(date).toLocaleDateString('it-IT')
}

export const toEnDateString = (date: string | number | Date) => {
  const d = new Date(date)
  return `${padNumberYear(d.getFullYear())}-${padNumber(d.getMonth() + 1)}-${padNumber(
    d.getDate(),
  )}`
}

export const getHours = (date: string | number | Date) => {
  const d = new Date(date)
  return d.getHours()
}
export const getMinutes = (date: string | number | Date) => {
  const d = new Date(date)
  return d.getMinutes()
}

export const nextDayDate = (date: string | number | Date, days = 1) => {
  const copy = new Date(date)
  return new Date(copy.setDate(copy.getDate() + days))
}

export const prevDayDate = (d: string | number | Date, days = 1) => {
  const copy = copyDate(d)
  return new Date(copy.setDate(copy.getDate() - days))
}
export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000)
}

export const addHours = (date: Date, hours = 1) => {
  return new Date(date.getTime() + (hours * 60 * 60 * 1000))
}

export const subtractMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() - minutes * 60000)
}

export const subtractHours = (date: Date, hours: number) => {
  return new Date(date.getTime() - (hours * 60 * 60 * 1000))
}

export const toEnDateStringWithHours = (date: string | number | Date) => {
  const d = new Date(date)
  return `${padNumberYear(d.getFullYear())}-${padNumber(d.getMonth() + 1)}-${padNumber(d.getDate())}T${padNumber(d.getHours())}:${padNumber(d.getMinutes())}`
}

export const getHoursAndMinuteByDate = (date: string | number) => {
  const d = new Date(date)
  return padNumber(d.getHours()) + ':' + padNumber(d.getMinutes())
}

export const dateFormatGGmmmYYYY = (date: string | number | Date, locales: string = 'it-IT') =>
  new Date(date).toLocaleString(locales, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
export const dateFormatGGmmmYYYYhhMM = (date: string | number | Date, locales: string = 'it-IT') =>
  new Date(date).toLocaleString(locales, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
export const dateFormatGGmmmYY = (date: string | number | Date, locales: string = 'it-IT') =>
  new Date(date).toLocaleString(locales, {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
  })
export const dateFormatGGmmm = (date: string | number | Date, locales: string = 'it-IT') =>
  new Date(date).toLocaleString(locales, {
    day: '2-digit',
    month: 'short',
  })
export const dateFormatGGmmmYYhhMM = (date: string | number | Date, locales: string = 'it-IT') =>
  new Date(date).toLocaleString(locales, {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  })
export const formatDateggmmyyhhmm = (date: Date) => {
  var day = ('0' + date.getDate()).slice(-2)
  var month = ('0' + (date.getMonth() + 1)).slice(-2)
  var year = date.getFullYear().toString().substr(-2)
  var hours = ('0' + date.getHours()).slice(-2)
  var minutes = ('0' + date.getMinutes()).slice(-2)

  return day + month + year + hours + minutes
}

export const dateStartDayTime = (d: string | number | Date) => {
  const date = new Date(d)
  date.setHours(0, 0, 0, 0)
  return date
}

export const dateEndDayTime = (d: string | number | Date) => {
  const date = new Date(d)
  date.setHours(23, 59, 59, 999)
  return date
}

export const isSameYear = (date: Date, dateCompare: Date) => {
  return date.getFullYear() === dateCompare.getFullYear()
}

export const isSameMonth = (date: Date, dateCompare: Date) => {
  return date.getMonth() === dateCompare.getMonth() && isSameYear(date, dateCompare)
}

export const isSameDay = (date: Date, dateCompare: Date) => {
  return date.getDate() === dateCompare.getDate() && isSameMonth(date, dateCompare)
}

export const isSameDayAndHours = (date: Date, dateCompare: Date) => {
  return isSameDay(date, dateCompare) && date.getHours() === dateCompare.getHours()
}


export const millisToHoursMinutes = (date: any, withoutPadNumber: boolean = false) => {
  const days = Math.floor(date / (1000 * 60 * 60 * 24))
  const hours = Math.floor((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((date % (1000 * 60)) / (1000))
  return {
    days,
    hours: withoutPadNumber ? hours : padNumber(hours),
    minutes: withoutPadNumber ? minutes : padNumber(minutes),
    seconds: withoutPadNumber ? seconds : padNumber(seconds),
  }
}

export const getMillisecondsByHours = (hours: number) => {
  return hours * 3600000
}
export const numberToHHMM = (number: number) => {
  const h = Math.floor(number)
  const m = Math.round((number % 1) * 60)

  return padNumber(h) + ':' + padNumber(m)
}

export const calcArrayDate = (startDate: any, endDate: any) => {
    const endDateMoment = moment(endDate).startOf('day')
    const end = endDateMoment.valueOf()
    const firstDateMoment = moment(startDate).startOf('day')
    const firstDate = firstDateMoment.valueOf()
    let currentDate = firstDate
    const dates: any[] = []
    while (currentDate <= end) {
        dates.push(currentDate)
        currentDate = currentDate + (24 * 60 * 60 * 1000)
    }
    return dates
}

