import React from "react";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../shared/configuration";
import { customerTypeName } from "../../../../redux/customer/customerData";
import { useSelector } from "react-redux";
import { selectCustomerRole, selectCustomerType } from "../../../modules/Auth/_redux/authRedux";
import * as routeName from "../../../routes/routeNameAuthType";
import { useRoute } from "../../../utils/useRoute";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import { usePermissions } from "../../../shared/utils/usePemissions";
import { permissionsData } from "../../../modules/Auth/_redux/permissionsData";

const WelcomeComponent = () => {
  const { t } = useLanguageForShared();
  const customerTypeSel = useSelector(selectCustomerType);
  const customer_role = useSelector(selectCustomerRole);
  const { issetPermission } = usePermissions();

  const isBuyer = issetPermission([permissionsData.hasBuyer]);
  const isApprover = issetPermission([permissionsData.hasApprover]);
  const isBo = customer_role === customerTypeName.bo;

  const { changeRoute } = useRoute();
  const goToNewPrescription = () => {
    changeRoute(routeName.patientsPage);
  };

  return (
    <section>

      <p className={"mt-2 font-size-lg"} style={{ maxWidth: "800px", lineHeight: "28px" }}><b
        className={"font-size-lg"} style={{ fontSize: "21px" }}>Benvenuto in ServiceGo</b>
        <br />
        {isBuyer && <div>
          {customerTypeSel === customerTypeName.prescriber &&
            <span>La piattaforma dedicata alla gestione delle richieste d'ordine per contenitori
          farmaceutici, gas ed elettromedicali.<br />
          Qui puoi visualizzare gli ordini esistenti e inserire nuove prescrizioni. <br />
            Seleziona uno degli ordini esistenti per esaminare gli ordini attuali o <b>{t("sg.newPrescription")}</b> per garantire un
          approvvigionamento efficiente dei prodotti necessari al supporto respiratorio domiciliare.</span>}

          {customerTypeSel === customerTypeName.pharmacy &&
            <span>La piattaforma dedicata alla gestione delle richieste d'ordine per contenitori
          farmaceutici, gas ed elettromedicali.<br />
          </span>}
        </div>}


        {customerTypeSel === customerTypeName.rdomanager &&
          <span>La piattaforma dedicata alla gestione delle richieste d'ordine per contenitori
          farmaceutici, gas ed elettromedicali.<br />
          </span>}

        {isBo &&
          <span>Benvenuto in ServiceGo, la piattaforma dedicata alla gestione delle richieste d'ordine per contenitori farmaceutici, gas ed elettromedicali.<br />
          Seleziona un ordine dalla lista oppure seleziona ‘Storico’ per assicurare una gestione precisa e veloce delle richieste.</span>}

        {(isApprover && !isBuyer) &&
          <span>La piattaforma dedicata alla gestione delle richieste d'ordine per contenitori
          farmaceutici, gas ed elettromedicali.<br />
          </span>}
      </p>

      {isBuyer && customerTypeSel === customerTypeName.prescriber && <div>
        <BtnDefault
          className="border-0 p-2 h-auto text-capitalize"
          onClick={goToNewPrescription}
          icon={"report"}
          tintColor={configuration.theme.primary}
          iconSize={"24px"}
        >
          <span className={"font-size-lg"}>
          {t("sg.newPrescription")}
          </span>
        </BtnDefault>
      </div>}
    </section>
  );
};

export default WelcomeComponent;
