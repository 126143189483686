import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";

export function CheckboxSingleForm({ ele, formik }) {
  const changeData = (event) => {
    formik.setFieldValue(ele.name, event.target.checked);
  };
  return (
    <Row
      className={`${ele.display ? ele.display : ""} ${
        ele.className ? ele.className : ""
      }`}
    >
      <Col xs="auto" {...(ele.col || {})} key={ele.name}>
        <InputGroup className="shared-checkbox">
          <InputGroup.Checkbox
            checked={formik.values[ele.name]}
            className="checkbox-sh"
            value={ele.value}
            name={ele.name}
            onChange={changeData}
          ></InputGroup.Checkbox>
          <label>{ele?.dataElement?.label}</label>
        </InputGroup>
      </Col>
    </Row>
  );
}
