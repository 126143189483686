import configuration from "../../shared/configuration";
import { customerTypeName } from "../../redux/customer/customerData";

export default function useStatesAction(t) {

  const states = [
    /*{
      code: "Draft.DaInviare",
      tagBgColor: configuration.theme.purple,
      tagColor: configuration.theme.light
    },*/
    {
      code: "Final.Aperto.Inapprovazione",
      tagBgColor: configuration.theme.info,
      tagColor: configuration.theme.alert_color,
    },
    {
      code: "Final.Chiuso.Nonapprovata",
      tagBgColor: configuration.theme.danger,
      tagColor: configuration.theme.danger_color,
    },
    {
      code: "Final.Aperta.Inviata",
      tagBgColor: configuration.theme.success,
      tagColor: configuration.theme.success_color,
    },
    {
      code: "Final.Chiusa.Cancellata.Cliente",
      tagBgColor: configuration.theme.danger,
      tagColor: configuration.theme.danger_color,
    },
    {
      code: "Final.Chiusa.Cancellata.BO",
      tagBgColor: configuration.theme.danger,
      tagColor: configuration.theme.danger_color,
    },
    {
      code: "Final.Aperta.Inlavorazione",
      tagBgColor: configuration.theme.cyan,
      tagColor: configuration.theme.light,
    },
    /*{
      code: "Final.Aperta.Inlavorazione.processing",
      tagBgColor: configuration.theme.cyan,
      tagColor: configuration.theme.light
    },*/


    {
      code: "Final.Aperta.Programmata",
      tagBgColor: configuration.theme.light_green,
      tagColor: configuration.theme.light_green_color,
      flag: [customerTypeName.prescriber, customerTypeName.pharmacy, customerTypeName.bo]
    },
    {
      code: "Final.Aperta.Evasione.Parziale",
      tagBgColor: configuration.theme.alert,
      tagColor: configuration.theme.alert_color,
      flag: [customerTypeName.pharmacy, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Conclusa.Parziale",
      tagBgColor: configuration.theme.alert,
      tagColor: configuration.theme.alert_color,
      flag: [customerTypeName.pharmacy, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Conclusa",
      tagBgColor: configuration.theme.success,
      tagColor: configuration.theme.success_color,
      flag: [customerTypeName.pharmacy, customerTypeName.bo]
    },


    {
      code: "Final.Chiusa.Attiva",
      tagBgColor: configuration.theme.primaryDark,
      tagColor: configuration.theme.light,
      flag: [customerTypeName.prescriber, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Sospeso",
      tagBgColor: configuration.theme.primaryDark,
      tagColor: configuration.theme.light,
      flag: [customerTypeName.prescriber, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Disattivo",
      tagBgColor: configuration.theme.primaryDark,
      tagColor: configuration.theme.light,
      flag: [customerTypeName.prescriber, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Inattivazione",
      tagBgColor: configuration.theme.info,
      tagColor: configuration.theme.alert_color,
      flag: [customerTypeName.prescriber, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Indisattivazione",
      tagBgColor: configuration.theme.grayDark,
      tagColor: configuration.theme.light,
      flag: [customerTypeName.prescriber, customerTypeName.bo]
    },
    {
      code: "Final.Chiusa.Generica",
      tagBgColor: configuration.theme.grayDark,
      tagColor: configuration.theme.light,
      flag: [customerTypeName.prescriber, customerTypeName.bo]
    }

  ];

  const findStates = (code: string) => {
    return states.find((state) => state.code === code);
  };
  const findStatesLabel = (code: string, role: string) => {
    const state = findStates(code)?.code || "";
    return t(`sg.stateAction.${role}.${state?.toLowerCase()?.replaceAll(".", "_")}`);
  };

  return {
    states,
    findStates,
    findStatesLabel
  };
}
