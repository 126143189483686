import React from "react";
import ToastListError from "../../../app/shared/ui/errors/toast/toastListError/ToastListError";
import {useDispatch, useSelector} from "react-redux";
import {removeError, selectErrors} from "../../../redux/errors/errorsSlice";

const ErrorsManagement = () => {
	const dispatch = useDispatch();
	const errors = useSelector(selectErrors)
	return (
		<ToastListError
			list={errors}
			closeAction={(err) => dispatch(removeError(err.id))}
		/>
	);
};

export default ErrorsManagement;
