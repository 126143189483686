import React, { ReactChild } from "react";
import configuration from "../../configuration";
import { IconSVG } from "./IconSVG";

interface IconLabelProps {
  icon: string | boolean;
  size?: string;
  label?: string | ReactChild;
  labelClass?: string;
  tintColor?: string;
  className?: string;
  title?: string;
  onClick?:(data: any) => void;
}

const IconLabel = ({
                     icon, label, labelClass = "",
                     size = "20px", tintColor, className, onClick, ...rest
                   }: IconLabelProps) => {
  return (
    <div className={`icon-label ${className ? className : ""}`} onClick={onClick} {...rest}>
      {icon && <IconSVG
        icon={icon as string}
        size={size}
        {...rest}
        tintColor={tintColor || configuration.theme.primary}
      />}

        {label && <div className={labelClass}>{label}</div>}
    </div>
  );
};

export default IconLabel;
