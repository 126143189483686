import React from "react";
import LabelValue from "../custom/labelValue/LabelValue";
import { useLanguageForShared } from "../utils/languages/useLanguageForShared";
import { IconSVG } from "../custom/icon/IconSVG";

const IDPatient = ({ cfPatient }: { cfPatient: string }) => {
  const { t } = useLanguageForShared();

  return (

    cfPatient ?
      <div className={"d-flex"}>
        <IconSVG icon={"cf"} size={"20px"} className={"me-2"} />
        <LabelValue label={cfPatient} className={"mb-0 "}
                    value={t("registry.fiscalCode")}
                    labelClass={"lg medium text-primary-dark"} valueClass={"sm medium"} />
      </div> : null

  );
};

export default IDPatient;
