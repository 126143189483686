export const useTemplateFormConcentratorTransportable = (t: any, dataForm: any) => {


  const formStructurePrescription = [
    {
      name: "flowRest",
      type: "input",
      label: `${t("prescription.template.flowRest")}*`,
      dataElement: {
        placeholder: t(`prescription.template.flowRest_placeholder`),
        type: "text"
      },
      col: { xs: "8", sm: "8", lg: "3", xl: "3" }
    },
    {
      name: "flowRestHourDie",
      type: "input",
      label: t("prescription.template.hourDie"),
      dataElement: {
        placeholder: t(`prescription.template.hourDie_placeholder`),
        type: "text"
      },
      col: { xs: "4", sm: "4", lg: "1", xl: "1" },
      display: {
        form: "me-lg-5"
      }
    },
    {
      name: "underStressFlow",
      type: "input",
      label: `${t("prescription.template.underStressFlow")}*`,
      dataElement: {
        placeholder: t(`prescription.template.underStressFlow_placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "6", lg: "3", xl: "3" }
    },
    {
      name: "underStressFlowHourDie",
      type: "input",
      label: t("prescription.template.hourDie"),
      dataElement: {
        placeholder: t(`prescription.template.hourDie_placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "6", lg: "1", xl: "1" },
      display: {
        form: "me-lg-5"
      }
    },
    {
      name: "nightFlow",
      type: "input",
      label: `${t("prescription.template.nightFlow")}*`,
      dataElement: {
        placeholder: t(`prescription.template.nightFlow_placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "6", lg: "3", xl: "3" }
    },
    {
      name: "nightFlowHourDie",
      type: "input",
      label: t("prescription.template.hourDie"),
      dataElement: {
        placeholder: t(`prescription.template.hourDie_placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "6", lg: "1", xl: "1" },
      display: {
        form: "me-lg-5"
      }
    }
  ];

  return {
    formStructurePrescription
  };
};
