import { concentratorType } from "../../../../../../../shared_lsi_sg/concentratorData";

export const useTemplateFormConcentrator = (t: any, dataForm: any) => {


  const formStructureConcentratorChoose = [
    {
      name: "concentratorType",
      type: "select",
      label: `${t("common.concentrator")}*`,
      options: Object.keys(concentratorType).map(ele => ({
          label: t(`concentrator.${ele}`),
          value: ele
        })
      ),
      dataElement: {
        type: "text",
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" }
    }
  ];

  return { formStructureConcentratorChoose };
};
