import jsPDF from "jspdf";

export const exportPdf = (fileName, ref) => {

  const doc = new jsPDF(
    {
      format: "a4",
      unit: "px",
      precision: 100
    }
  );

  doc.html(ref.current, {
    margin: [16, 12],

    async callback(doc) {
      doc.save(`${fileName}.pdf`);
    }
  });

};