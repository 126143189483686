import React from "react";
import moment from "moment/moment";

const PatientRowInfo = ({ patient }: any) => {
  const birthdate = patient?.birthDate ? moment(patient?.birthDate).format("DD MMM YY") : patient?.DateOfBirth ? moment(patient?.DateOfBirth).format("DD MMM YY") : "";
  const gender = patient?.gender?.slice(0, 1) || "";

  return (
    <div className={"list-key-value"}>
      <span>
      {patient?.name} {patient?.surname} {!!gender && `(${gender})`} {birthdate} {patient?.cf ? "| " + patient?.cf : ""}
      </span>
    </div>
  );
};

export default PatientRowInfo;
