import React, { useEffect } from "react";

import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { setEditOrderData } from "../../../../../redux/order/orderSlice";
import { useRoute } from "../../../../utils/useRoute";
import * as routeName from "../../../../routes/routeNameAuthType";
import { Col, Row } from "react-bootstrap";
import { saveOrder } from "../../../../../redux/order/orderCrud";
import { GetCartAction } from "../../../../../redux/cart/cartActions";
import { clearOrder } from "../../../../utils/ordersUtils";
import {
  resetModal,
  setModal
} from "../../../../shared/redux/modal/modalSlice";
import {
  setErrorTypeAction,
  setSuccessErrorAction
} from "../../../../../redux/errors/errorsActions";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { setPatient } from "../../../../../redux/patient/patientSlice";
import { setEditOrderDataAction } from "../../../../../redux/order/orderActions";
import { ActionsName } from "./ActionsData";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import { GetPatientAction } from "../../../../../redux/patient/patientActions";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
import modalEvents from "../../../../shared/redux/modal/modalEvent";
import FormSuspension from "./FormSuspension";
import { useForm } from "../../../../../shared/utils/forms/useForm";
import useFormModalContext from "../../../../shared/redux/modal/context/UseFormModalContext";

export function ProductActionOrder({ actions, order, suspensionCallback }) {
  const { t } = useLanguageForShared();

  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  const customer = useSelector(selectSelectedCustomer);
  const createNewOrder = (type) => {
    type === "suspension" ? confirmSuspensionModal(type) : editOrder(type);
  };
  const buttonList = [
    {
      label: t("action.cancel"),
      action: "closeModal",
      type: "secondary",
      dataType: {}
    },
    {
      label: t("action.confirm"),
      action: "startOrder",
      type: "primary",
      dataType: {}
    }
  ];
  const {dataForm} = useFormModalContext()

/*  const changeValueHandler = (data) => {
    setData(data)
  }*/

  modalEvents.templates.formVariation = <FormSuspension />

  const closeModal = () => {
    dispatch(resetModal());
  };

  const startOrder = (type) => {
     saveOrder(clearOrder({ ...order, dettaglioOrdine: {...order.dettaglioOrdine, suspension: modalEvents.data}, type, customerId: customer.id })).then(
       (res) => {
         dispatch(GetCartAction(t));
         dispatch(setSuccessErrorAction(t("sg.msg.suspensionSuccess")));
         suspensionCallback && suspensionCallback(res);
         dispatch(resetModal());
       },
       (err) => {
         dispatch(setErrorTypeAction(t("sg.msg.errorSuspension")));       }
     );
  };

  const confirmSuspensionModal = (type) => {
    dispatch(
        setModal(
            buttonList,
            {
              title: t("sg.msg.modals.confirmSuspension"),
              templateBody: 'formVariation'
            },
            {
              startOrder: () => startOrder(type),
              closeModal
            }
        )
    );
  };
  const editOrder = (type) => {
    dispatch(GetPatientAction(t, order.dettaglioOrdine.patient.id));
    dispatch(setEditOrderDataAction({ ...order, type }));
    changeRoute(routeName.selectEditProduct);
  };

  return (
    <Row className="justify-content-end prod-action mt-2 flex-nowrap">
      {actions?.map((ele, key) => (
        <Col xs="auto" key={key}>
          <BtnPrimary className="sm" onClick={() => createNewOrder(ele)}>
            {ActionsName(t)[ele]}
          </BtnPrimary>
        </Col>
      ))}
    </Row>
  );
}
