import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";

export function RadioForm({ ele, formik, changeValue }) {
	const changeValueHandler = (event, ele) => {
		const value = ele.format === "boolean"
			? event.target.value === "true"
			: event.target.value
		changeValue ? changeValue(ele.name, value) : formik.setFieldValue(
			ele.name,
			value
		)
	}
	return (
		<Row className={'gap-2'}>
			{ele.options.map((opt) => (
				<Col xs="auto" {...(opt.col || {})} key={opt.label}>
					<InputGroup className="shared-radio">
						<InputGroup.Radio
							checked={formik.values[ele.name] === opt.value}
							className="radio-sh"
							value={opt.value}
							name={ele.name}
							onChange={(data) =>
								changeValueHandler(data, ele)
							}></InputGroup.Radio>
						<label>{opt.label}</label>
					</InputGroup>
				</Col>
			))}
		</Row>
	);
}
