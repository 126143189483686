import React, { useState } from "react";
import { useRoute } from "../../../../app/utils/useRoute";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import EditRegistryPatientModal from "./modal/EditRegistryPatientModal";
import RowPatientDetailTemplate from "./RowPatientDetailTemplate";

const InnerPatientDetailTemplate = ({ patient }: { patient: any }) => {
  const { changeRoute } = useRoute();
  const { t } = useLanguageForShared();
  const [showModalEdit, setShowModalEdit] = useState(undefined);
  const goEdit = () => {
    setShowModalEdit(true);
    //changeRoute(routeName.patientEditPage);
  };


  return (
    <>
      <RowPatientDetailTemplate patient={patient} clickEditRegistryHandler={goEdit} />
      {showModalEdit && <EditRegistryPatientModal patient={patient} cancelHandler={() => setShowModalEdit(false)} />}
    </>
  );
};

export default InnerPatientDetailTemplate;
