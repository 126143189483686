import FormModalContext from './FormModalContext';
import {useForm} from "../../../../../shared/utils/forms/useForm";
import {useEffect} from "react";
import modalEvents from "../modalEvent";

const ProviderFormModalContext = ({ children }) => {
    const {
        dataForm,
        changeValue,
        errors,
        setValidations,
        isValid,
        submitAction,
        setData,
        isSubmit
    } = useForm(() => null, {});
    useEffect(() => {
        modalEvents.data = dataForm
    }, [dataForm])
    return (
        <FormModalContext.Provider value={{ dataForm, changeValue, setData, errors, setValidations, isValid, submitAction, isSubmit }}>
            {children}
        </FormModalContext.Provider>
    );
};
export default ProviderFormModalContext
