import React from "react";

const Loader = ({ className = "", size = "" }) => {
	return (
		<div className={`lds-spinner ${size} ${className}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};
export default Loader;
