export const templateRdoType = {
    GENFAR: 'GENFAR',
    GENPUB: 'GENPUB'
}

export const orderType = {
    delivery: 'delivery',
    activation: 'activation',
    renewal: 'renewal',
    change: 'change',
    suspension: 'suspension'
}

export const orderTypeByTemplate = {
    [templateRdoType.GENFAR]: orderType.delivery,
    [templateRdoType.GENPUB]: orderType.activation,
}
