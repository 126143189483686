import { useModeData } from "./useModeData";
import { modeVentiloName } from "./modeData";

export const useModeVentiloData = (t: any, dataForm: any) => {
  const { formStructureModeChoose } = useModeData(t);


  const formStructureMode = [
    {
      name: "mode",
      type: "select",
      label: `${t("prescription.template.mode.label")}*`,
      options: Object.keys(modeVentiloName).map(ele => ({
          label: t(`prescription.template.${ele}`),
          value: ele
        })
      ),
      dataElement: {
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "2", xl: "3" }
    },
    ...(dataForm.mode ? formStructureModeChoose[dataForm.mode] : [])
  ];
  return {
    formStructureMode
  };
};
