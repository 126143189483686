import currencies from './currencies'

const formatType: {
  [key: string]: {
    model: (value: any, extraData: any) => any
    view: (value: any, extraData: any) => any
  }
} = {
  number: {
    model: (
      num: string,
      extraData: any = {}
    ): { value?: number; extraData: any } => {
      if (num && num.slice(-1) === ',') {
        extraData.comma = ','
      } else {
        extraData.comma = ''
      }
      const value = parseFloat(num.replace(/\./g, '').replace(/,/g, '.'))
      return {
        value: isNaN(value)
          ? undefined
          : parseFloat(num.replace(/\./g, '').replace(/,/g, '.')),
        extraData
      }
    },
    view: (num: number | string, extradata: any = {}): string => {
      if (num !== '' && typeof num === 'string') num = parseFloat(num)
      const n =
        num !== undefined && num !== null && num !== ''
          ? num.toLocaleString('it-IT')
          : num
      return n ? n + (extradata.comma || '') : n
    }
  },
  integer: {
    model: (
      num: string,
      extraData: any = {}
    ): { value: number; extraData: any } => {
      return {
        value: parseInt(num),
        extraData
      }
    },
    //  Warning: Passing a value over Number.MAX_SAFE_INTEGER will cause issues...  //
    view: (num: number | string, extradata: any = {}): number | string => {
      if (num !== '' && typeof num === 'string') {
        num = parseInt(num)
      }
      return isNaN(num as number) ? '' : num
    }
  }
}

export const format = (type: string, value: any, extraData: any = {}) => {
  return formatType[type] ? formatType[type].view(value, extraData) : { value }
}
export const formatModel = (type: string, value: any, extraData: any = {}) => {
  return formatType[type] ? formatType[type].model(value, extraData) : { value }
}

export function formatCurrency (
  value: number | string,
  targetCurrency: string = 'EU'
): string {
  return `${currencies[targetCurrency].currency} ${format('number', value)}`
}
