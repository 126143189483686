import React, { ForwardedRef } from "react";
import { IconSVG } from "../icon/IconSVG";
import configuration from "../../configuration";

const PanelBottom = React.forwardRef(({
                                        children,
                                        closePanelHandler, maxHeight
                                      }: { children?: React.ReactNode, closePanelHandler?: () => void, maxHeight?: string }, ref: ForwardedRef<any>) => {

    return (
      <div className={"panel-bottom overflow-auto"} ref={ref} style={{ maxHeight: maxHeight }}>
        <div className={"close-btn"}>
          {closePanelHandler && <IconSVG icon={"close pointer"} size={"20px"} tintColor={configuration.theme.primary}
                                         onClick={closePanelHandler} />}
        </div>
        {children}
      </div>
    );
  }
);

export default PanelBottom;
