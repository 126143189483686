import React from "react";
import { Row } from "react-bootstrap";
import { FormElement } from "./FormElement";

export function FormElements({ structure, formik, changeValue, rightTemplate, style }) {
  return (
    <Row className="align-items-center row-form" style={style}>
      {structure.map((ele, k) => (
        <FormElement key={k} ele={ele} formik={formik} changeValue={changeValue} />
      ))}
      {rightTemplate && rightTemplate}
    </Row>
  );
}
