import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { columnsContainers } from "./containersTableData";
import { DeliveryColumnFormatter } from "./DeliveryColumnFormatter";
import {DeliveryDateColumnFormatter} from "./DeliveryDateColumnFormatter";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function TableContainers({ containers, setSelectedContainerHandler, setCollect }) {
	const { t } = useLanguageForShared();


	const dataTable = [
		...columnsContainers(t),
		{
			dataField: "delivery",
			text: t('sg.delivery'),
			formatter: DeliveryColumnFormatter,
			formatExtraData: {

			},
			classes: "",
			style: {
				maxWidth: "100px",
			},
		},
		{
			dataField: "deliveryDate",
			text: t('sg.dataDelivery'),
			formatter: DeliveryDateColumnFormatter,
			formatExtraData: {

			},
			classes: "",
			style: {
				maxWidth: "100px",
			},
		},
		{
			dataField: "action",
			text: "",
			formatter: ActionsColumnFormatter,
			formatExtraData: {
				associate: setSelectedContainerHandler,
				setCollect: setCollect,
				t:t
			},
			classes: "",
			style: {
				maxWidth: "100px",
			},
		},
	];
	return (
		<>
			<BootstrapTable
				wrapperClasses="table-responsive"
				bordered={false}
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				remote
				hover={false}
				keyField="id"
				data={containers}
				columns={dataTable}></BootstrapTable>
		</>
	);
}
