import React, { useEffect } from "react";
import { SelectProductForm } from "./SelectProductForm";
import { SelectProductButtons } from "./SelectProductButtons";
import { useFormik } from "formik";
import { validationTemplatesForm } from "./templates/templateValidation";
import { useForm } from "../../../../../shared/utils/forms/useForm";
import { useValidationProduct } from "./templates/validations/useValidationProduct";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";


export function SelectProductFullForm({
                                        product,
                                        changeQuantityProduct,
                                        changeRouteProducts,
                                        addToCart,
                                        createOrder,
                                        isInCart,
                                        isPharmacy
                                      }) {
  /*  const formik = useFormik({
      initialValues: product?.detail || {},
      validationSchema: validationTemplatesForm[product?.template],
      onSubmit: (values) => {
      }
    });
    const isValid = (callback) => {
      const valid = validationTemplatesForm[product?.template].isValidSync(
        formik.values
      );
      formik.submitForm();
      if (valid) {
        callback && callback();
      }
    };
   */
  const { t } = useLanguageForShared();
  useEffect(() => {
    setData(product?.detail || {});
  }, [product?.detail]);


  const {
    dataForm, dataFormatted,
    changeValue,
    errors,
    setData,
    setValidations,
    isSubmit,
    isValid,
    submitAction
  } = useForm(submitHandler, validations);//TODO MAX  aggiungere validation

  const { validations } = useValidationProduct(product?.template, t, dataForm);

  useEffect(() => {
    setValidations(validations);
  }, [validations]);


  const addToCartHandler = () => {
    const callback = () => {
      addToCart(dataFormatted);//TODO MAX  controllare prima era formik.value
    };
    if (isValid()) {
      callback();
    } else {
      submitAction();
    }
    //isValid(callback);
  };
  const createOrderHandler = () => {
    const callback = () => {
      createOrder(dataFormatted);//TODO MAX  controllare prima era formik.value
    };
    if (isValid()) {
      callback();
    } else {
      submitAction();
    }
    //isValid(callback);
  };


  const submitHandler = (event, data) => {
  };
  console.log("dataform", dataForm);
  return (
    <>
      <SelectProductForm
        product={product}
        changeQuantityProduct={changeQuantityProduct}
        dataForm={dataForm}
        setData={setData}
        changeValue={changeValue}
        isSubmit={isSubmit}
        errors={errors}
      />
      <SelectProductButtons
        changeRouteProducts={changeRouteProducts}
        addToCart={addToCartHandler}
        createOrder={createOrderHandler}
        isInCart={isInCart}
        isPharmacy={isPharmacy}
      />
    </>
  );
}
