import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
	errors: [],
};

export const errorsSlice = createSlice({
	name: "errors",
	initialState: initialCustomersState,
	reducers: {
		addError: (state, action) => {
			state.errors = [...state.errors, action.payload];
		},
		removeError: (state, action) => {
			state.errors = state.errors.filter(
				(error) => error.id !== action.payload
			);
		},
		resetErrors: (state) => {
			state.errors = [];
		},
	},
});

export const { addError, removeError, resetErrors } = errorsSlice.actions;

export const selectErrors = (state) => state.errors.errors;
