import React from "react";
import ConcentratorChoose from "./templateConcentrator/ConcentratorChoose";
import ConcentratorTemplateFixed from "./templateConcentrator/ConcentratorTemplateFixed";
import ConcentratorTemplatePortable from "./templateConcentrator/ConcentratorTemplatePortable";
import ConcentratorTemplateTransportable from "./templateConcentrator/ConcentratorTemplateTransportable";
import { concentratorType } from "../../../../../../shared_lsi_sg/concentratorData";
import ConcentratorTemplateFixedPortable from "./templateConcentrator/ConcentratorTemplateFixedPortable";


export function TemplateConcentrator({ dataForm, changeValue, errors, setData, isSubmit }) {
  const changeValueHandler = (data) => {
    if(data.name === 'concentratorType'){
      setData({ concentratorType: data.value })
    }else {
      changeValue && changeValue(data)
    }
  }
  return (
    <>
      <ConcentratorChoose errors={errors} isSubmit={isSubmit} changeValue={changeValueHandler} dataForm={dataForm} />
      {dataForm?.concentratorType === concentratorType.fixed &&
        <ConcentratorTemplateFixed isSubmit={isSubmit} errors={errors} changeValue={changeValue} dataForm={dataForm} setData={setData} />}
      {dataForm?.concentratorType === concentratorType.portable &&
        <ConcentratorTemplatePortable isSubmit={isSubmit} errors={errors} changeValue={changeValue} dataForm={dataForm}
                                      setData={setData} />}
      {dataForm?.concentratorType === concentratorType.fixed_portable &&
        <ConcentratorTemplateFixedPortable isSubmit={isSubmit} errors={errors} changeValue={changeValue} dataForm={dataForm}
                                      setData={setData} />}
      {dataForm?.concentratorType === concentratorType.transportable &&
        <ConcentratorTemplateTransportable isSubmit={isSubmit} errors={errors} changeValue={changeValue} dataForm={dataForm}
                                           setData={setData} />}

    </>
  );
}
