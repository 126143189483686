import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { isArray } from "../../shared/utils/arrayUtils";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";
import TemplateConcentratorPrescriptionRead from "./TemplateConcentratorPrescriptionRead";
import { TemplateConcentratorPulsedFlowRead } from "./TemplateConcentratorPulsedFlowRead";


export function TemplateConcentratorRead({ data }: any) {
  const { t } = useLanguageForShared();

  const checkPathologyType = () => {
    return isArray(data?.pathology) ? data?.pathology?.map((ele: string) => t(`pathology.${ele}`)).join(", ") : data?.pathology || "-";
  };

  return (
    <div className={"template-sg ventilo-template row row-gap-4"}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
        <div className={"row row-gap-4"}>
          <div className="col-sm-12 col-md-12">
            <LabelValue label={t("common.concentrator")}
                        value={t(`concentrator.${data?.concentratorType}`)} />
          </div>

          {data?.pathology && data?.pathology.length > 0 && <div className="col-sm-12 col-md-12">
            <LabelValue label={t("common.pathologies")}
                        value={checkPathologyType()} />
            {data?.otherPathologies && <LabelValue
              label={t("common.otherPathologies")}
              value={data?.otherPathologies} />}
          </div>}
          <>
            <TemplateConcentratorPrescriptionRead data={data} />
            <TemplateConcentratorPulsedFlowRead data={data} />
          </>

        </div>
      </div>


      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
        <div className={"row row-gap-4"}>
          <div className="col-sm-12 col-md-12">
            <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>

            <LabelValue label={t("prescription.template.cannulaNasal.label")}
                        value={data?.cannulaNasal ? t("common.yes") : t("common.no")} className={"me-5"} />
            {data?.cannulaNasal && <>
              <LabelValue
                label={t("prescription.template.cannulaType.label")}
                value={data?.cannulaType || "-"} className={"me-5"} />
              <LabelValue
                label={t("prescription.template.cannulaModel.label")}
                value={data?.cannulaModel || "-"} className={"me-5"} />
            </>}
          </div>


          <div className="col-sm-12 col-md-12">
            <LabelValue label={t("prescription.template.mask.label")}
                        value={data?.mask ? t("common.yes") : t("common.no")} className={"me-5"} />
            {data?.mask && <>
              <LabelValue
                label={t("prescription.template.maskType.label")}
                value={data?.maskType || "-"} className={"me-5"} />
              <LabelValue
                label={t("prescription.template.maskModel.label")}
                value={data?.maskModel || "-"} className={"me-5"} />
            </>}
          </div>
        </div>
      </div>

      <div className={"row row-gap-4"}>
        <div className="col-sm-12 col-md-12">
          <LabelValue label={t("prescription.template.note.label")}
                      value={data?.note} />

        </div>
      </div>
    </div>
  );
}
