import React, { useState } from "react";
import { FormElements } from "../../../../shared/bootstrap/form/FormElements";
import { concentratorType } from "../../../../shared_lsi_sg/concentratorData";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import { IconSVG } from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import RowTag from "../../order/component/summary/RowTag";
import RowDelivery from "../../order/component/summary/RowDelivery";

const ValidateForm = ({
                        order,
                        newListTags,
                        setNewListTags,
                        deliveryDateNotes,
                        setDeliveryDateNotes,
                        updateCallHandler,
                        deleteListTags,
                        setdeleteListTags
                      }: any) => {
  const { t } = useLanguageForShared();


  return (
    <div>
      <div className="col-12">
        {order.orderCodeType && <p>{t("sg.msg.rdoNumber")}</p>}
        {/*
        {!order.orderCodeType && order?.ordersCodes?.length > 0 && <p>{t("sg.orderNumbers")}</p>}
*/}
        <RowTag
          //setOrderInEdit={setOrderInEdit}
          paramValidation={order?.orderCodeType?.type}
          isOnlyRead={!order.orderCodeType}
          listNR={order?.ordersCodes || []}
          idOrder={order.codiceRDO}
          // updateOrderHandler={updateOrderHandler}
          providerList={order?.availableProviders}
          newListTags={newListTags}
          setNewListTags={setNewListTags}
          updateCallHandler={updateCallHandler}
          deleteListTags={deleteListTags}
          setdeleteListTags={setdeleteListTags}
        />
      </div>
      <div className="col-12 mt-5">
        {order?.deliveryNotesEditable && <p>{t("sg.msg.deliveryNote")}</p>}
        <RowDelivery order={order} deliveryDateNotes={deliveryDateNotes} setDeliveryDateNotes={setDeliveryDateNotes}
        />{/*orderDetail={orderDetail} updateDateHandler={updateDateHandler}*/}
      </div>

    </div>
  );
};

export default ValidateForm;
