import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import {
  formStructureMedicoRow,
  formStructurePrescrizioneRow,
  validationsTemplateGenPub
} from "./templateGenPubData";

import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { selectIsPharmacy } from "../../../../redux/customer/customerSlice";
import { selectUser } from "../../../modules/Auth/_redux/authRedux";
import { toEnDateString } from "../../../utils/dateUtils";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import BoxSearchPatients from "../templateGenFar/component/BoxSearchPatients";
import {
  formStructureContattiRow,
  formStructureIndirizzoConsegnaRow, formStructureIndirizzoResidenceRow,
  formStructureNominativeRow
} from "../templateGenFar/templateGenFarData";
import Checkbox from "../../../../shared/bootstrap/form/checkbox/Checkbox";
import { IconSVG } from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import {
  formStructureEditPatientRegistryRow, formStructureEditPatientRegistryRowDomicile,
  formStructureEditPatientRegistryRowResidence
} from "../anagraficaPaziente/patientRegistryData";
import { useIsUpdatePatient } from "../useIsUpdatePatient";
import { updatePatient } from "../../../../redux/order/orderCrud";
import { setErrorTypeAction } from "../../../../redux/errors/errorsActions";
import HeadingH3 from "../../../shared/ui/headings/headingH3/HeadingH3";


const TemplateGenPub = ({ createOrder, order, goBack, patient }) => {
  const { t } = useLanguageForShared();
  const isPharmacy = useSelector(selectIsPharmacy);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      prescription: {
        date: toEnDateString(new Date().getTime())
      },
      prescriber: {
        name: user.firstname,
        surname: user.lastname
      }
    },
    validationSchema: validationsTemplateGenPub,
    onSubmit: (values) => {
      const applicant = {
        name: user.firstname,
        surname: user.lastname,
        data: toEnDateString(new Date().getTime())
      };
      const callback = (data = {}) => {
        createOrder({ ...values, applicant, ...data });
      };
      updateRegistry ? updatePatientAction(callback) : callback();
    }
  });
  const updatePatientAction = (callback) => {
    updatePatient(formik.values.patient).then(
      (res) => {
        callback && callback({ patient: res });
      },
      (err) => {
        const message = err?.response?.data?.Message;
        if (message === "ERR_CF_DUPLICATE") {
          dispatch(setErrorTypeAction(t("sg.msg.cfAlreadyRegistered")));
        } else if (message === "ERR_CF_INCOHERENT") {
          dispatch(setErrorTypeAction(t("sg.msg.cfWrong")));
        } else if (message === "ERR_EMAIL_DUPLICATE") {
          dispatch(setErrorTypeAction(t("sg.msg.emailAlreadyRegistered")));
        } else if (message === "ERR_EMAIL_INCOHERENT") {
          dispatch(setErrorTypeAction(t("sg.msg.emailWrong")));
        } else {
          dispatch(
            setErrorTypeAction(t("sg.msg.errorUpdatePatient"))
          );
        }
      }
    );
  };
  useEffect(() => {
    order && formik.setValues({
      ...order,
      prescription: order.prescription || {
        date: toEnDateString(new Date().getTime())
      },
      prescriber: order.prescriber || {
        name: user.firstname,
        surname: user.lastname
      }
    });
  }, [order]);
  useEffect(() => {
    (!order?.patient) && formik.setFieldValue("patient", patient);
  }, [patient]);
  const { updateRegistry, setUpdateRegistry, isUpdatePatient } = useIsUpdatePatient(patient, formik.values.patient);
  return (
    <div className={"templates-wrap template-gen-pub"}>
      <Form autocomplete="off" onSubmit={formik.handleSubmit}>
        {/*<HeadingH4>Richiedente</HeadingH4>*/}
        {/*<FormElements*/}
        {/*	structure={formStructureRichiedenteRow}*/}
        {/*	formik={formik}></FormElements>*/}
        <HeadingH3 className={"mb-3"}>{t("sg.infoPrescription")}</HeadingH3>

        <HeadingH4>{t("sg.order.prescriber.prescriberOrder")}</HeadingH4>

        <FormElements
          structure={formStructureMedicoRow(t)}
          formik={formik}></FormElements>
        <div className={"mt-3"}>
        <HeadingH4>{t("sg.order.prescriber.prescriptionRight")}</HeadingH4>
          <FormElements
            structure={formStructurePrescrizioneRow(t)}
            formik={formik}></FormElements>
        </div>
        {/*	<HeadingH4>Diagnosi</HeadingH4>
				<FormElements
					structure={formStructureDiagnosiRow}
					formik={formik}></FormElements>*/}
        <hr />
        <HeadingH3 className={"mt-3 mb-3"}>{t("sg.infoPatientPrescription")}</HeadingH3>

        <HeadingH4>{t("sg.patientLabel")}</HeadingH4>
        <FormElements
          structure={
            formStructureNominativeRow(t)
          }
          formik={formik}
        ></FormElements>
        <HeadingH4 className={"mt-3"}>{t("sg.residenceAddress")}</HeadingH4>
        <FormElements
          structure={formStructureIndirizzoResidenceRow(t)}
          formik={formik}
        ></FormElements>
        <HeadingH4 className={"mt-3"}>{t("sg.homeAddress")}</HeadingH4>
        <FormElements
          structure={formStructureIndirizzoConsegnaRow(t)}
          formik={formik}
        ></FormElements>
        <HeadingH4 className={"mt-3"}>{t("sg.contacts")}</HeadingH4>
        <FormElements
          structure={formStructureContattiRow(t)}
          formik={formik}
        ></FormElements>


        {isUpdatePatient && <div className={"update-registry-check py-5 mb-5"}>
          <Checkbox name="updateRegistry"
                    value={updateRegistry}
                    label={t("sg.updateRegistry")}
                    onChange={() => setUpdateRegistry(!updateRegistry)}
                    checked={updateRegistry} />
        </div>}


        <hr />
        <Row className="justify-content-end mt-3">
          <Col xs="auto">
            <BtnSecondary onClick={goBack}>{isPharmacy ? t("action.cancel")
              : t("action.back")}</BtnSecondary>
          </Col>
          <Col xs="auto">
            <BtnPrimary type="submit">{t("action.next")}</BtnPrimary>
          </Col>
        </Row>
      </Form>

    </div>
  );
};

export default TemplateGenPub;
