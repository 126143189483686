import React, { ReactChild } from "react";
import BadgeInfo from "./BadgeInfo";

const InfoMsg = ({
                   label,
                   icon = "alert",
                   iconColor,
                   variant = "warning",
                   clickAction
                 }: { label: string | ReactChild, variant?: string, icon?: string, iconColor?: string, clickAction?: () => void }) => {
  return (
    <BadgeInfo clickAction={clickAction} icon={icon} iconSize={"20px"} tintColor={iconColor} variant={variant}>
      <span className={"ms-2"}>{label}</span></BadgeInfo>
  );
};

export default InfoMsg;
