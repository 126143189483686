export const templateNameType = {
    note: 'note',
    ossigeno: 'ossigeno',
    ventiloterapia: 'ventiloterapia',
    sleep: 'sleep',
    concentrator: 'concentrator',
}

export const templateNameId = {
    [templateNameType.note]: 'a175b9f6-dfce-45d5-8f84-c823331e18fc',
    [templateNameType.ossigeno]: 'a175b9f6-dfce-45d5-8f84-c823331e18fb',
    [templateNameType.ventiloterapia]: 'c175b9f6-dfce-45d5-8f84-c823331e18fa',
    [templateNameType.sleep]: '3e0ee5a5-7d91-4189-aca4-0f10e6a66f72',
    [templateNameType.concentrator]: 'eb5a65f8-92ba-4bbc-925a-e3abd90c8568'
}
export const templateData = [
    {
        id: templateNameId[templateNameType.ventiloterapia],
        descrizione: 'Ventiloterapia Generico',
        name: 'Template003',
        template: templateNameType.ventiloterapia
    },
    {
        id: templateNameId[templateNameType.ossigeno],
        descrizione: 'Ossigeno terapia generico',
        name: 'Template001',
        template: templateNameType.ossigeno
    },
    {
        id: templateNameId[templateNameType.note],
        descrizione: 'note generico',
        name: 'Template002',
        template: templateNameType.note
    },
    {
        id: templateNameId[templateNameType.sleep],
        descrizione: 'sonno',
        name: 'Template004',
        template: templateNameType.sleep
    }
]
