import i18n from "i18next";

export const typeData = () => ({
  activation: {
    name: "activation",
    label: i18n.t("sg.stateAction.typology.activationTypology")
  },
  delivery: {
    name: "delivery",
    label: i18n.t("sg.stateAction.typology.deliveryTypology")
  },
  renewal: {
    name: "renewal",
    label: i18n.t("sg.stateAction.typology.renewalTypology")
  },
  change: {
    name: "variation",
    label: i18n.t("sg.stateAction.typology.variationTypology")
  },
  suspension: {
    name: "suspension",
    label: i18n.t("sg.stateAction.typology.suspensiontypology")
  }
});
