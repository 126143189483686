import React from 'react';
import { IconSVG } from "../../icon/IconSVG";

interface BadgeInfoProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: string,
    iconSize: string,
    variant?: string,
    tintColor?: string,
    children: React.ReactNode,
    clickAction?: () => void
}

const BadgeInfo = ({
                       icon,
                       iconSize,
                       variant = "warning",
                       tintColor,
                       children,
                        clickAction
                   }: BadgeInfoProps) => {
    return (
        <div className={`badgeInfo ${variant} ${clickAction? 'pointer' : ''}`}  onClick={clickAction}>
            <IconSVG icon={icon} size={iconSize} tintColor={tintColor} className={'d-flex align-items-center justify-content-center'}/>{children}
        </div>
    );
};

export default BadgeInfo;
