import React, { useEffect, useState } from "react";
import { getContainersAction } from "../../../redux/container/containerActions";
import { useDispatch, useSelector } from "react-redux";
import { selectContainers } from "../../../redux/container/containerSlice";
import { TableContainers } from "./components/table/TableContainers";
import { Col, Row } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import { typeViewContainer } from "./components/table/containersTableData";
import { SwitchViewContainers } from "./components/view/SwitchViewContainers";
import IconBtn from "../../shared/ui/buttons/icon-button/IconButton";
import { containerPharmacyCommunications } from "../../../redux/container/containerCrud";
import { selectSelectedCustomer } from "../../../redux/customer/customerSlice";
import {
  setErrorTypeAction,
  setSuccessErrorAction
} from "../../../redux/errors/errorsActions";
import { resetModal, setModal } from "../../shared/redux/modal/modalSlice";
import configuration from "../../../shared/configuration";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function Containers() {
  const { t } = useLanguageForShared();

  const containers = useSelector(selectContainers);
  const customer = useSelector(selectSelectedCustomer);
  const [selectContainer, setSelectContainer] = useState();
  const [showView, setShowView] = useState();
  const dispatch = useDispatch();
  const setSelectedContainerHandler = (ele) => {
    setSelectContainer({ ...ele });
    setShowView(typeViewContainer.trace);
  };
  const resetShowTrace = () => {
    setShowView(undefined);
    setSelectContainer(undefined);
  };
  const setCollect = (ele) => {
    setSelectContainer({ ...ele });
    setShowView(typeViewContainer.collect);
  };
  useEffect(() => {
    dispatch(getContainersAction(t));
  }, []);

  const buttonList = [
    {
      label: t("action.cancel"),
      action: "cancelCommunicationPharmacy",
      type: "secondary",
      dataType: {}
    },
    {
      label: t("action.confirm"),
      action: "communicationsPharmacy",
      type: "primary",
      dataType: {}
    }
  ];
  const communicationsPharmacyModal = () => {
    dispatch(
      setModal(
        buttonList,
        {
          title: t("sg.msg.requestPickup"),
        },
        { cancelCommunicationPharmacy, communicationsPharmacy }
      )
    );
  };
  const communicationsPharmacy = () => {
    containerPharmacyCommunications(customer.id)
      .then((res) => {
        dispatch(
          setSuccessErrorAction(
            t("sg.msg.confirmPickupSuccess"),
          )
        );
      })
      .catch(() =>
        dispatch(
          setErrorTypeAction(
            t("sg.msg.errorPickupSuccess"),
          )
        )
      );
    dispatch(resetModal());
  };
  const cancelCommunicationPharmacy = () => {
    dispatch(resetModal());
  };

  return (
    <div className="container">


      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <h3 className={"title-page w-100"}>
                <div className={"d-flex justify-content-between align-items-end"}>
                  {!showView
                    ? t("sg.magazineStatus")
                    : showView === typeViewContainer.trace
                      ? t("sg.traceContainer")
                      : showView === typeViewContainer.collect
                        ? t("sg.requestDomicilePickup")
                        : ""}
                  <div className={"d-flex align-items-center gap-5"}>
                    {!showView && (
                      <>
                        <div
                          className=" ms-auto d-flex gap-3"
                          style={{ marginTop: "-20px" }}>
                          <IconBtn
                            icon="pharmacy"
                            className={"mr-3 text-primary"}
                            iconColor={configuration.theme.primary}
                            onClick={communicationsPharmacyModal}>
                            {t("sg.requestPharmaPickup")}
                          </IconBtn>
                          <IconBtn
                            icon="track"
                            className={"text-primary"}
                            iconColor={configuration.theme.primary}
                            sizeBtn="16px"
                            onClick={() => setShowView(typeViewContainer.trace)}>
                            {t("sg.track")}
                          </IconBtn>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </h3>
            </CardHeader>
            <CardBody>
              <Row className="mb-5 justify-content-center">
                <Col xs="12">
                  <div>
                    {!showView ? (
                      <>
                        {/*<div
                          className="ms-auto d-flex justify-content-end gap-3 mb-2"
                          style={{ marginTop: "-20px" }}>
                          <IconBtn
                            icon="pharmacy"
                            className={"mr-3 text-primary"}
                            iconColor={configuration.theme.primary}
                            onClick={communicationsPharmacyModal}>
                            Richiesta ritiro in farmacia
                          </IconBtn>
                          <IconBtn
                            icon="track"
                            className={"text-primary"}
                            iconColor={configuration.theme.primary}
                            sizeBtn="16px"
                            onClick={() => setShowView(typeViewContainer.trace)}>
                            Traccia
                          </IconBtn>
                        </div>*/}
                        <TableContainers
                          containers={containers}
                          setSelectedContainerHandler={
                            setSelectedContainerHandler
                          }
                          setCollect={setCollect}
                        />
                      </>
                    ) : null}
                    {showView && (
                      <SwitchViewContainers
                        resetShowTrace={resetShowTrace}
                        selectContainer={selectContainer}
                        showView={showView}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
