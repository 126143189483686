import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import * as routeName from "../../routes/routeNameAuthType";
import {useRoute} from "../../utils/useRoute";
import {GetPatientAction} from "../../../redux/patient/patientActions";
import {useDispatch} from "react-redux";
import {setIsLsi} from "../../../redux/order/orderSlice";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function RedirectPatientPage({ history }) {
  const { t } = useLanguageForShared();

  const {id} = useParams()
  const dispatch = useDispatch();
  const { changeRoute } = useRoute();
  useEffect(() => {
    const callback = () => {
      dispatch(setIsLsi())
      changeRoute(routeName.selectedPatientPage, undefined, undefined, true)
    };
    dispatch(GetPatientAction(t, id, callback));
  })
  return (
    <div className={'text-uppercase'}>{t('sg.loading')}  {id}</div>
  );
}
