import React from "react";
import Icon from "../../../../shared/ui/icon/Icon";
import iconAssociate from "./../../../../assets/img/icon/associate.svg";
import iconDisassociate from "./../../../../assets/img/icon/disassociate.svg";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { associate, setCollect, t }
) {

  return (
    <div className="d-flex justify-content-end align-items-center">
      {row.deliveryPlace ? (
        <img
          src={iconDisassociate}
          title={t("sg.comeBack")}
          alt={t("sg.comeBack")}
          className="me-3 pointer"
          onClick={(event) => {
            event.stopPropagation();
            associate(row);
          }}
        />
      ) : (
        <img
          src={iconAssociate}
          title={t("sg.delivery")}
          alt={t("sg.delivery")}
          className="mr-3 pointer"
          onClick={(event) => {
            event.stopPropagation();
            associate(row);
          }}
        />
      )}
      <Icon
        className="justify-content-end"
        icon="truck"
        disabled={!row.deliveryPlace}
        clickAction={(event) => {
          event.stopPropagation();
          setCollect(row);
        }}></Icon>
    </div>
  );
}
