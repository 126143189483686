import React, { useEffect, useState } from "react";
import IconBtn from "../../../../shared/ui/buttons/icon-button/IconButton";
import { InputCustom } from "../../../../shared/ui/forms/input/InputCustom";
import Caption from "../../../../shared/ui/headings/caption/Caption";
import { updateOrders } from "../../../../../redux/order/orderCrud";
import {
  setErrorTypeAction,
  setSuccessErrorAction
} from "../../../../../redux/errors/errorsActions";
import { useDispatch } from "react-redux";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { optionDelivery } from "../../../sample/templateGenFar/templateGenFarData";
import IconLabel from "../../../../../shared/custom/icon/IconLabel";
import configuration from "../../../../../shared/configuration";
import { useEffectOnlyUpdate } from "../../../../shared/utils/use_custom/useEffectCustom";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export default function RowDelivery({
                                      //orderDetail,
                                      order,
                                      deliveryDateNotes,
                                      setDeliveryDateNotes
                                      //updateDateHandler,
                                      //setOrderInEdit,
                                    }) {
  const { t } = useLanguageForShared();


  //const [showEdit, setShowEdit] = useState(true);
  /*  useEffect(() => {
     // setOrderInEdit && setOrderInEdit(showEdit);
    }, [showEdit]);*/

  useEffectOnlyUpdate(() => {
    setDeliveryDateNotes(order?.dettaglioOrdine?.deliveryNotes || "");
  }, [order?.dettaglioOrdine?.deliveryNotes]);

  const dispatch = useDispatch();
  const changeValueInputDeliveryDate = (value) => {
    setDeliveryDateNotes(value);
  };

  /*
    const addDeliveryDate = () => {
      updateOrders(order.codiceRDO, {
        dettaglioOrdine: {
          ...order.dettaglioOrdine,
          deliveryNotes: deliveryDateNotes
        }
      }).then(
        (res) => {
          dispatch(setSuccessErrorAction(t("sg.msg.deliveryDateUploadSuccess")));
          // updateDateHandler && updateDateHandler(res);
        },
        (err) => {
          dispatch(setErrorTypeAction(t("sg.msg.errorDeliveryDateUpload")));
        }
      );
    };
  */


  return (
    <div className={"row"}>
      <div className={"col-md-12 col-lg-7"}>
        <div className={"list-key-value"}>
          <span className={"w-100"}>
          <span
            className={"label-item"}>{t("sg.stateAction.scheduledDelivery")}</span>
            <div className={"d-flex align-items-center gap-3"}>
             {order?.deliveryNotesEditable ? <InputCustom
                maxLength={"100"}
                ele={{
                  name: "deliveryNotes",
                  type: "input"
                }}
                val={deliveryDateNotes}
                changeValue={changeValueInputDeliveryDate}
              /> : order?.orderDetail?.deliveryNotes || "-"}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}
