import React, {CSSProperties, ReactChild} from "react";
import {mergeClass} from "../../functions/function";
import {classByArray} from "../../utils/htmlUtils";

const LabelValue = ({
                        label,
                        value,
                        inline,
                        labelClass,
                        valueClass,
                        children, className, labelValueStyle, labelStyle
                    }: {
    label: string | ReactChild,
    value?: string | ReactChild,
    inline?: boolean,
    labelClass?: string,
    valueClass?: string,
    children?: ReactChild,
    className?: string,
    labelValueStyle?: CSSProperties
    labelStyle?: CSSProperties
}) => {

    const labelValueClass = mergeClass("label-value", classByArray([inline ? "inline" : "", className ? className : ""]));
    const labelClassCSS = mergeClass("sh-label", labelClass ? labelClass : undefined);
    const valueStyle = mergeClass("value", valueClass ? valueClass : undefined);

    return (
        <div className={labelValueClass} style={labelValueStyle}>
            <div className={labelClassCSS} style={labelStyle}>{label}</div>
            <div className={valueStyle}>{children || null}{value}
            </div>
        </div>
    );
};

export default LabelValue;
