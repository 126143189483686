import React, {useEffect} from "react";
import userManager from "../../../utils/oidcUserManager";

function SsoPage() {

 useEffect(() => {
     userManager.signinRedirect();
 }, [])
  return (
      <></>
  );
}

export default SsoPage;
