import React, { Fragment, useState } from 'react'
import IconLabel from '../../custom/icon/IconLabel'
import { Popover, PopoverPosition, ContentRenderer, ArrowContainer } from 'react-tiny-popover'

const PopoverCustomOver = ({
                             positions,
                             children,
                             title,
                             content,
                             trigger = 'click',
                           }: {
  positions?: PopoverPosition[],
  children: React.ReactChild,
  title?: React.ReactChild,
  content: React.ReactChild,
  trigger?: 'click' | 'mouseOver'
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={positions}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#fff'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className={'sh popover-content'}>
            {title && <div className={'popover-title'}>{title}</div>}
            <div
              className={'popover-body'}
              onMouseOver={() => setIsPopoverOpen(true)}
              onMouseOut={() => setIsPopoverOpen(false)}
            >
              {content}
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <div className="d-inline-block" onMouseOver={() => setIsPopoverOpen(!isPopoverOpen)}
           onMouseOut={() => setIsPopoverOpen(false)}>
        {children}
      </div>
    </Popover>
  )
}

export default PopoverCustomOver
