import React from "react";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

const LastUpdateInline = ({ lastModified }: { lastModified: any }) => {
    const { t } = useLanguageForShared();

    return (
      <div className={"list-key-value order-date"}>
                 <span className={"flex-row align-items-baseline gap-2"}>
                          <span className={"label-item"}>{t("sg.order.lastEditOn")}</span>
                   {lastModified ? (<>
                     {dateformat_ggMMMyyyy(lastModified?.date)}
                     {(lastModified?.surname || lastModified?.name) && <span><span
                       className={"label-item"}>{t("sg.order.of")}</span> {lastModified?.surname?.toUpperCase()}{" "}{lastModified?.name}</span>}
                   </>) : ("-")}
                          </span>
      </div>
    );
  }
;

export default LastUpdateInline;
