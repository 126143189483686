import React, { useEffect } from "react";
import { useLanguageForShared } from "../../../../../../../shared/utils/languages/useLanguageForShared";
import { useTemplateFormOtherInfo } from "../data/useTemplateFormOtherInfo";
import { useParamsData } from "../data/useParamsData";
import { useEffectOnlyUpdate } from "../../../../../../../shared/utils/use_custom/useEffectCustom";
import { copyObject } from "../../../../../../../shared/utils/objUtils";
import { FormElements } from "../../../../../../../shared/bootstrap/form/FormElements";
import { useTemplateFormConcentratorPortable } from "../data/useTemplateFormConcentratorPortable";

const ConcentratorTemplatePortable = ({ dataForm, changeValue, errors, setData, isSubmit }) => {
  const { t } = useLanguageForShared();
  const {
    formStructurePulsedFlow, formStructureDetailPulsedFlow
  } = useTemplateFormConcentratorPortable(t, dataForm);
  const {
    formStructureCannulaNasal, formStructureMask,
    formStructureDetailCannulaNasal, formStructureDetailMask
  } = useTemplateFormOtherInfo(t, dataForm);
  const {
    formStructureNote
  } = useParamsData(t, dataForm);

  useEffectOnlyUpdate(() => {
    if (!dataForm?.cannulaNasal) {
      const modeField = formStructureDetailCannulaNasal.map((ele) => ele.name).join(",").split(",");
      const copyDataForm = copyObject(dataForm);
      modeField?.forEach((key) => {
        delete copyDataForm[key];
      });
      setData(copyDataForm);
    }
  }, [dataForm?.cannulaNasal]);

  useEffect(() => {
    if (!dataForm?.mask) {
      const modeField = formStructureDetailMask.map((ele) => ele.name).join(",").split(",");
      const copyDataForm = copyObject(dataForm);
      modeField?.forEach((key) => {
        delete copyDataForm[key];
      });
      setData(copyDataForm);
    }
  }, [dataForm?.mask]);

  useEffect(() => {
    if (!dataForm?.pulsedFlow) {
      const modeField = formStructureDetailPulsedFlow.map((ele) => ele.name).join(",").split(",");
      const copyDataForm = copyObject(dataForm);
      modeField?.forEach((key) => {
        delete copyDataForm[key];
      });
      setData(copyDataForm);
    }
  }, [dataForm?.pulsedFlow]);


  return (
    <>
      <div className="row">
        <FormElements
          structure={formStructurePulsedFlow}
          dataForm={dataForm}
          changeValue={changeValue}
          errors={errors}
          isSubmit={isSubmit}
        />

        <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
        <FormElements
          structure={formStructureCannulaNasal}
          dataForm={dataForm}
          changeValue={changeValue}
          errors={errors}
          isSubmit={isSubmit}
        />
        <FormElements
          structure={formStructureMask}
          dataForm={dataForm}
          changeValue={changeValue}
          errors={errors}
          isSubmit={isSubmit}
        />
        <FormElements
          structure={formStructureNote}
          dataForm={dataForm}
          changeValue={changeValue}
          errors={errors}
          isSubmit={isSubmit}
        />
      </div>
    </>
  );
};

export default ConcentratorTemplatePortable;
