import * as Yup from "yup";
import { cfRegex } from "../../../../utils/cfRegex";

export const validationSchemaNominativeWithTelephone = Yup.object().shape({
	patient: Yup.object().shape({
		// id: Yup.string().required("Selezionare un paziente prima di procedere"),
		name: Yup.string().required("Il nome è obbligatorio").nullable(),
		surname: Yup.string().required("Il cognome è obbligatorio").nullable(),
		cf: Yup.string().nullable()
			.matches(cfRegex, "Inserire CF valido"),
		phone: Yup.number()
			.typeError("Inserire un numero valido")
			.required("Il Telefono obbligatorio").nullable(),
	}),
	container: Yup.object().shape({
		serial: Yup.string().required("Il seriale della bombola è obbligatorio").nullable(),
	}),
	// "patient.id": Yup.string().required("Il Codice fiscale è obbligatorio"),
	// "patient.cf": Yup.string().required("Il Codice fiscale è obbligatorio"),
	// "patient.phone": Yup.string().required("Il Telefono obbligatorio"),
});
