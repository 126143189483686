import { useHistory } from "react-router";
import {getRoutes} from "../routes/getRoutes";
export function useRoute() {
  const history = useHistory();
  const changeRoute = (
    name: string,
    data?: { [key: string]: string },
    query?: { [key: string]: string },
    replace: boolean = false
  ) => {
    let path = getRoute(name);
    if (path.includes(":")) {
      data &&
        Object.keys(data).forEach((ele) => {
          path = path.replace(":" + ele, data[ele]);
        });
    }
    if (query) {
      const values = Object.keys(query).reduce((newObj: any, ele) => {
        if (query[ele] !== undefined) {
          newObj[ele] = query[ele];
        }
        return newObj;
      }, {});
      path = `${path}?${new URLSearchParams(values).toString()}`;
    }
    replace ? history.replace(path) : history.push(path);
  };
  const findRoute = (name: string) => {
    const routesFilter = getRoutes()
    return routesFilter.find((ele) => ele.name === name);
  };
  const getRoute = (name: string): string => {
    const rt = findRoute(name);
    return rt ? rt.path : "/";
  };

  return {
    changeRoute,
  };
}
