import {createSelector, createSlice} from "@reduxjs/toolkit";
import {orderTypeByTemplate} from "./orderData";

const initialOrderState = {
  orderData: {},
  editOrderData: undefined,
  fromCart: false,
  isLsi: false
};


export const orderSlice = createSlice({
  name: "order",
  initialState: initialOrderState,
  reducers: {
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setIsLsi: (state) => {
      state.isLsi = true;
    },
    resetIsLsi: (state) => {
      state.isLsi = false
    },
    setEditOrderData: (state, action) => {
      state.editOrderData = action.payload;
    },
    setFromCart: (state, action) => {
      state.fromCart = action.payload;
    },
    setProductOrderData: (state, action) => {
      const templateRDO = action.payload.products[0].templateRdo;
      const type = orderTypeByTemplate[templateRDO];
      state.orderData = {type, orderRequest: {templateRDO}, ...(action.payload?.data || {}), products: action.payload.products,};
    },
    setEditProductOrderData: (state, action) => {
      const templateRDO = action.payload.products[0].templateRdo;
      const type = orderTypeByTemplate[templateRDO];
      state.editOrderData = {type, orderRequest: {templateRDO}, ...(action.payload?.data || {}), products: action.payload.products,};
    },
    resetOrderData: (state) => {
      state.orderData = {};
    },
    resetEditOrderData: (state) => {
      state.editOrderData = undefined;
    }
  }
});


export const {setOrderData, setFromCart, resetIsLsi, setIsLsi, setProductOrderData, resetOrderData, setEditOrderData, resetEditOrderData, setEditProductOrderData} = orderSlice.actions;



export const selectOrderData = (state) => state.order.orderData;
export const selectEditOrderData = (state) => state.order.editOrderData;
export const selectFromLsi = (state) => state.order.isLsi;

export const selectCurrentOrder = createSelector(selectOrderData, selectEditOrderData, (order, orderEdit) => {
  return order && Object.keys(order)?.length? order : orderEdit;
})
export const selectCurrentOrderType = createSelector(selectCurrentOrder, (order) => {
  return order?.type;
})
export const selectIsEditOrder = createSelector(selectCurrentOrderType, (type) => {
  return !!type && ['Variation', 'Suspension', 'Renewal'].includes(type);
})
export const selectEditProduct = createSelector(selectEditOrderData, (order) => {
  const orderData = order?.products?.length && order?.products[0]
  return orderData;
})
export const selectFromCart = (state) => state.order.fromCart;
