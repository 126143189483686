import React, {ReactChild, useState} from "react";
import LabelValueHover from "./LabelValueHover";
import {clipboardCopy} from "../../utils/generalUtils";
import {useDispatch} from "react-redux";
import {useLanguageForShared} from "../../utils/languages/useLanguageForShared";
import configuration from "../../configuration";
import {addSuccessMessage} from "../../redux/errors/errorSlice";

const LabelValueCopy = ({
                            label,
                            value,
                            iconValue,
                            clickIconValueHandler,
                            ...rest
                        }: { label: string | ReactChild, value?: string, iconValue?: { ico: string, size?: string, color?: string }, clickIconValueHandler?: (value?: string) => void, labelClass?: string }) => {

    const {t} = useLanguageForShared();
    const iconDefault = {ico: "copy", size: "14px", color: configuration.theme.primary, ...iconValue};
    const dispatch = useDispatch();

    const clickCopy = (val?: string) => {
        val && clipboardCopy(val);
        dispatch(addSuccessMessage({title: t(`"${val}" ${t("action.copied")}`)}))
    };

    return (
        <LabelValueHover label={label} value={value} iconValue={iconDefault}
                         clickIconValueHandler={(val) => clickCopy(val)} title={t("action.copy")} {...rest} />
    );
};

export default LabelValueCopy;
