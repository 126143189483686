import {useState} from "react";

export function useIsUpdatePatient(oldPatient, patientForm)  {
    const [updateRegistry, setUpdateRegistry] = useState(false);
    const getIsUpdatePatient = () => {
        return oldPatient && JSON.stringify(oldPatient) !== JSON.stringify(patientForm)
    }
    const isUpdatePatient = getIsUpdatePatient();
    return {
        updateRegistry, setUpdateRegistry,
        isUpdatePatient
    }
}
