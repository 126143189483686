import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsRDO } from "../../../../../app/modules/Auth/_redux/authRedux";
import { useRoute } from "../../../../../app/utils/useRoute";
import { InitCustomerAction } from "../../../../../redux/customer/customerActions";
import {
	selectCustomers,
	selectSelectedCustomer,
	setSelectedCustomer,
} from "../../../../../redux/customer/customerSlice";
import * as routeName from "../../../../../app/routes/routeNameAuthType";
import Caption from "../../../../../app/shared/ui/headings/caption/Caption";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
// import { Tab, Nav } from "react-bootstrap";
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../../../app/actions/users/usersActions";

export function QuickPanel() {
	const { t } = useLanguageForShared();

	// const [selectedTab, setSelectedTab] = useState("Organizations");
	//
	// const setTab = _tabName => {
	//   setSelectedTab(_tabName);
	// };
	//
	// const dispatch = useDispatch();
	//
	// const { currentState, user } = useSelector((state) => (
	//   {
	//     currentState: state.users,
	//     user: state.auth.user
	//   }
	// ), shallowEqual);
	//
	// useEffect(() => {
	//   dispatch(actions.fetchCustomers(user));
	// }, [dispatch]);
	//
	// const { customersList } = currentState;
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();
	const customers = useSelector(selectCustomers);
	const customer = useSelector(selectSelectedCustomer);

	const isRdo = useSelector(selectIsRDO);
	return (
		<div id="kt_quick_panel" className="offcanvas offcanvas-right p-10">
			{/*<Tab.Container*/}
			{/*  defaultActiveKey={selectedTab}*/}
			{/*>*/}
			{/*  <div*/}
			{/*    className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">*/}
			{/*    <Nav*/}
			{/*      onSelect={setTab}*/}
			{/*      as="ul"*/}
			{/*      role="tablist"*/}
			{/*      className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"*/}
			{/*    >*/}
			{/*      <Nav.Item className="nav-item" as="li">*/}
			{/*        <Nav.Link*/}
			{/*          eventKey="Organizations"*/}
			{/*          className={`nav-link ${selectedTab === "Organizations" ? "active" : ""*/}
			{/*            }`}*/}
			{/*        >*/}
			{/*          Organizzazioni*/}
			{/*          </Nav.Link>*/}
			{/*      </Nav.Item>*/}
			{/*    </Nav>*/}

			{/*    <div className="offcanvas-close mt-n1 pe-5" style={{ position: "absolute", top: "15px", right: "10px" }}>*/}
			{/*      <a*/}
			{/*        href="#"*/}
			{/*        className="btn btn-xs btn-icon btn-light btn-hover-primary"*/}
			{/*        id="kt_quick_panel_close"*/}
			{/*      >*/}
			{/*        <i className="ki ki-close icon-xs text-muted"></i>*/}
			{/*      </a>*/}
			{/*    </div>*/}
			{/*  </div>*/}
			{/*  <div className="offcanvas-content px-10">*/}
			{/*    <div className="tab-content">*/}
			{/*      <div*/}
			{/*        id="kt_quick_panel_notifications"*/}
			{/*        role="tabpanel"*/}
			{/*        className={`tab-pane fade pt-2 pe-5 me-n5 scroll ps ${selectedTab === "Organizations" ? "active show" : ""}`}*/}
			{/*      >*/}
			{/*        <div className="navi navi-icon-circle navi-spacer-x-0">*/}
			{/*          {*/}
			{/*            customersList.length > 0*/}
			{/*              ? customersList.map((element) => {*/}
			{/*                return (*/}
			{/*                  <a id="kt_quick_panel_a" href="#" className="navi-item" key={element.CustomerName} onClick={() => {*/}
			{/*                    dispatch(actions.setCustomerId(element));*/}
			{/*                    window.location.href = "/"*/}
			{/*                  }}>*/}
			{/*                    <div className="navi-link rounded">*/}
			{/*                      <div className="symbol symbol-50 me-3">*/}
			{/*                        <div className="symbol-label">*/}
			{/*                          <i className="flaticon-bell text-success icon-lg"></i>*/}
			{/*                        </div>*/}
			{/*                      </div>*/}
			{/*                      <div className="navi-text">*/}
			{/*                        <div className="font-weight-bold font-size-lg">*/}
			{/*                          {element.CustomerName}*/}
			{/*                        </div>*/}
			{/*                        <div className="text-muted">{element.CustomerAddress}</div>*/}
			{/*                      </div>*/}
			{/*                    </div>*/}
			{/*                  </a>*/}
			{/*                )*/}
			{/*              })*/}
			{/*              : null*/}
			{/*          }*/}
			{/*        </div>*/}
			{/*      </div>*/}
			{/*    </div>*/}
			{/*  </div>*/}
			{/*</Tab.Container>*/}

			<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
				<h3 className="font-weight-bold m-0">Organizzazioni</h3>
				<a
					href="#"
					className="btn btn-xs btn-icon btn-light btn-hover-primary"
					id="kt_quick_panel_close">
					<i className="ki ki-close icon-xs text-muted" />
				</a>
			</div>

			<div className="offcanvas-content pe-5 me-n5">
				<div className="separator mt-10 mb-5" />
				{isRdo && (
					<div>
						<ul style={{ listStyle: "none", paddingLeft: "0px" }}>
							{customers.map((ele, i) => (
								<li
									key={i}
									onClick={() => {
										localStorage.setItem("customerId", ele.id);
										dispatch(setSelectedCustomer(ele));
										dispatch(InitCustomerAction(t));
										changeRoute(routeName.products);
									}}>
									<Caption
										className={`mb-3  ${
											ele.id === customer?.id ? "primary" : "link pointer"
										}`}>
										{ele.name} ({ele.type})
									</Caption>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
}
