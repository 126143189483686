import React from "react";
import ModalCustomVH from "../../../../../shared/bootstrap/modal/ModalCustomVH";
import TitleCard from "../../../../../app/shared/app/titleCard/TitleCard";
import PatientRegistry from "../../../../../app/pages/sample/anagraficaPaziente/PatientRegistry";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

const EditRegistryPatientModal = ({ patient, cancelHandler }: { patient?: any, cancelHandler: () => void }) => {
  const { t } = useLanguageForShared();

  return (
    <div>
      <ModalCustomVH
        className={null}
        titleLayout={<h3>{t('sg.patientRegistry')}</h3>}
        closeModal={cancelHandler}
        isFullScreen={false}
        footerTemplate={<></>}
      >
        <PatientRegistry patient={patient} closeModalHandler={cancelHandler} />
      </ModalCustomVH>
    </div>
  );
};

export default EditRegistryPatientModal;
