import React from "react";

const ThumbImage = ({ product }) => {
  return (
    <div>
      {product?.img ? <img src={product?.img} width="100%" /> : <span className={'p-2'}>{product?.title}</span>}
    </div>
  );
};

export default ThumbImage;
