import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setErrorTypeAction,
  setSuccessErrorAction
} from "../../../../../redux/errors/errorsActions";
import { updateOrders } from "../../../../../redux/order/orderCrud";
import IconBtn from "../../../../shared/ui/buttons/icon-button/IconButton";
import Error from "../../../../shared/ui/forms/error/Error";
import { InputCustom } from "../../../../shared/ui/forms/input/InputCustom";
import Icon from "../../../../shared/ui/icon/Icon";
import IconLabel from "../../../../../shared/custom/icon/IconLabel";
import configuration from "../../../../../shared/configuration";
import { SelectCustom } from "../../../../shared/ui/forms/select/SelectCustom";
import { selectProviders } from "../../../../../redux/customer/customerSlice";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export default function RowTag({
                                 isOnlyRead,
                                 listNR,
                                 paramValidation,
                                 idOrder,
                                 //updateOrderHandler,
                                 //setOrderInEdit,
                                 providerList,
                                 newListTags,
                                 setNewListTags,
                                 updateCallHandler,
                                 setdeleteListTags,
                                 deleteListTags
                               }) {
  const { t } = useLanguageForShared();
  console.log("listNR", listNR);
  const [tag, setTag] = useState(undefined);
  const [error, setError] = useState(undefined);
  //const providers = useSelector(selectProviders);

  const [provider, setProvider] = useState(providerList ? providerList[0]?.id : undefined);
  const dispatch = useDispatch();
  const changeValueInputTag = (value) => {
    setTag(value);
  };
  const changeValueProvider = (name, value) => {
    setProvider(value);
  };


  const addTagInNewList = () => {
    if (
      tag &&
      paramValidation &&
      paramValidation !== "NO_ORD" &&
      paramValidation !== "undefined" && //TODO
      !tag.startsWith(paramValidation)
    ) {
      setError({ errorType: paramValidation });
      return;
    }
    //tag && updateCall([...listNR, { providerId: provider, number: tag }]);
    tag && setNewListTags && setNewListTags([...newListTags, { providerId: provider, number: tag }]);
    setTag("");
    setError(undefined);
  };

  const deleteItemTag = (item) => {
    setdeleteListTags([...deleteListTags, item])
   // updateCallHandler(listNR.filter((ele) => ele.number !== item.number));
  };

  const deleteItemTagNewList = (item) => {
    setNewListTags(newListTags.filter((ele) => ele.number !== item.number));
  };

  /*    useEffect(() => {
        setOrderInEdit && setOrderInEdit(!!tag);
      }, [tag]);*/
  const cancelHandler = () => {
    setError(undefined);
    setTag("");
  };

  return (
    <Row className={`align-items-start row-gap-2 ${isOnlyRead ? "" : "mt-4"}`}>
      {providerList && providerList.length > 1 && <Col xs={"auto"} style={{ "min-width": "120px" }}>
        <div className={"list-key-value"}>
              <span className={"w-100"}>
                  {!isOnlyRead && (
                    <>
                      <span className={"label-item"}>{t("sg.addProvider")}</span>
                      <SelectCustom ele={{
                        name: "provider",
                        type: "input"
                      }} options={providerList.map(pr => ({ label: pr.name, value: pr.id }))} values={{ provider }}
                                    changeValue={changeValueProvider} />
                    </>
                  )/* : (
                    listNR &&
                    listNR.length > 0 && (
                      <span className={"label-item"}>{t("sg.orderNumbers")}</span>
                    )
                  )*/}
              </span>
        </div>
      </Col>}
      {!isOnlyRead && (
        <Col xs="auto" style={{ "min-width": "200px" }}>
          <div className={"list-key-value"}>
             <span>
              <span
                className={"label-item"}>{t("sg.addOrderNumbers")}</span>
                 <div className={"d-flex flex-column"}>
                 <div className={"d-flex align-items-center gap-3"}>
                    <InputCustom
                      ele={{
                        name: "tag",
                        type: "input"
                      }}
                      val={tag}
                      changeValue={changeValueInputTag}
                    />
                    <div className={"d-flex align-items-center gap-2"}>
                   {!!tag && <IconLabel
                     tintColor={configuration.theme.error}
                     className={"pointer d-flex gap-1 align-items-center"}
                     labelClass={"text-primary"}
                     icon="minus-c"
                     size="20px"
                     title={t("action.cancel")}
                     onClick={cancelHandler}></IconLabel>}
                      <IconLabel
                        tintColor={configuration.theme.primary}
                        className={`${!tag ? "disabled" : "pointer"} d-flex gap-1 align-items-center`}
                        labelClass={"text-primary"}
                        icon="plus-c"
                        size="20px"
                        title={t("action.add")}
                        onClick={addTagInNewList}></IconLabel>
              </div>
                   </div>
                   {error && tag && (
                     <Error>{t("sg.type")} "{error.errorType}" {t("sg.notPresent")}</Error>
                   )}
                 </div>
             </span>
          </div>
        </Col>
      )}
      <Col xs="12">
        <div className={""}>
          <div className={"list-key-value"}>
            {listNR &&
              listNR.length > 0 && (
                <span className={"label-item"}>{t("sg.orderNumbers")}</span>)}
          </div>
          <div className={`item-row ${isOnlyRead ? "read" : "mt-1"}`}>
            {listNR?.filter((ele) => !deleteListTags.map(el => el.number).includes(ele.number))?.map((ele, i) => (
              <div className={`row`} key={i}>
                {providerList && providerList.length > 1 &&
                  <Col xs={"auto"} style={{ "minWidth": "120px" }}>
                    <span>{providerList.find(prv => prv.id === ele.providerId).name} </span>
                  </Col>}
                <Col xs={"auto"} style={{ "minWidth": "200px" }}>
                  {ele.number}
                </Col>
                {!isOnlyRead && (<Col xs={"auto"}>
                  <IconLabel
                    className={"pointer"}
                    tintColor={configuration.theme.error}
                    icon="minus-c"
                    size="20px"
                    onClick={() => deleteItemTag(ele)}
                  />
                </Col>)}
              </div>
            ))}
          </div>

          <div className={`item-row ${"mt-1"}`}>
            {newListTags && newListTags?.filter((ele) => !deleteListTags.map(el => el.number).includes(ele.number))?.map((ele, i) => (
              <div className={`row`} key={i}>
                {providerList && providerList.length > 1 &&
                  <Col xs={"auto"} style={{ "minWidth": "120px" }}>
                    <span>{providerList.find(prv => prv.id === ele.providerId).name} </span>
                  </Col>}
                <Col xs={"auto"} style={{ "minWidth": "200px" }}>
                  {ele.number}
                </Col>
                {!isOnlyRead && (<Col xs={"auto"}>
                  <IconLabel
                    className={"pointer"}
                    tintColor={configuration.theme.error}
                    icon="minus-c"
                    size="20px"
                    onClick={() => deleteItemTagNewList(ele)}
                  />
                </Col>)}
              </div>
            ))}
          </div>

        </div>
      </Col>
    </Row>
  );
}
