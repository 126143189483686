import React from "react";
import { templateFormNote } from "../templateForm";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";

export function TemplateNoteRead({ data }) {
  const { t } = useLanguageForShared();

  return (
    <div className={"row"}>
      {templateFormNote(t).map((field, i) => (
        <div
          key={i}
          className={`col-xs-12 ${field?.className || ""} mb-3 list-key-value`}>
					<span className="mr-2"><span className={"label-item"}>{field.label}</span>
            {(data && data[field.name]) || "-"}</span>
        </div>
      ))}
    </div>
  );
}
