import React from "react";
import Icon from "../../icon/Icon";
import { IconSVG } from "../../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../../shared/configuration";

const WizardStep = ({ label, isActive, index, isLast }) => {
  return (
    <div className={`shared-wizardStep ${isActive ? "active" : ""} ${isLast ? "current" : ""}`}>
      <span>{label}</span>
      <IconSVG className="icon-step" icon="chevron-right" size="14px"
               tintColor={` ${(isActive && !isLast) ? configuration.theme.primaryDark : configuration.theme.gray}`} />
    </div>
  );
};

export default WizardStep;
