import React from "react";
import { templateNameId, templateNameType } from "../../../../../../shared_lsi_sg/templateData";
import { TemplateNote } from "./TemplateNote";
import { TemplateOxigen } from "./TemplateOxigen";
import { TemplateSleep } from "./TemplateSleep";
import VentiloTemplate from "./VentiloTemplate";
import { TemplateConcentrator } from "./TemplateConcentrator";

export function TemplateSelectProduct({ formik, dataForm, setData, template, changeValue, errors, isSubmit }) {
  const templateList = {
    [templateNameId[templateNameType.note]]: <TemplateNote isSubmit={isSubmit} dataForm={dataForm}
                                                           changeValue={changeValue}
                                                           errors={errors} setData={setData} />,
    [templateNameId[templateNameType.ossigeno]]: <TemplateOxigen isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue}
                                                                 errors={errors} setData={setData} />,
    [templateNameId[templateNameType.ventiloterapia]]: <VentiloTemplate dataForm={dataForm} changeValue={changeValue}
                                                                        errors={errors} setData={setData}
                                                                        isSubmit={isSubmit} />,
    [templateNameId[templateNameType.sleep]]: <TemplateSleep isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue}
                                                             errors={errors} setData={setData} />,

    [templateNameId[templateNameType.concentrator]]: <TemplateConcentrator isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue}
                                                                           errors={errors} setData={setData} />
  };
  return (
    <>
      {templateList[template]}
    </>
  );
}
