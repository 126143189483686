import React from "react";
import IsPermission from "../../../../app/shared/utils/IsPermission";
import { permissionsData } from "../../../../app/modules/Auth/_redux/permissionsData";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../shared/configuration";
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import moment from "moment";
import DetailPatientRegistry from "./DetailPatientRegistry";

const RowPatientDetailTemplate = ({
                                    patient,
                                    clickEditRegistryHandler
                                  }: { patient: any, clickEditRegistryHandler: () => void }) => {
  const { t } = useLanguageForShared();


  return (
    <div className={"w-100"}>
      <h3 className={"title-page mt-3"}>
        <div className={"d-flex justify-content-between align-items-end"}>
          {t("sg.patient.registryPatient")}
          {clickEditRegistryHandler && <div className={"d-flex align-items-center gap-3"}>
            <IsPermission permissions={[permissionsData.canEdit]}>
              <BtnDefault
                className="border-0 p-2 py-0 h-auto text-capitalize"
                onClick={clickEditRegistryHandler}
                icon="edit"
                tintColor={configuration.theme.primary}
                iconSize={"24px"}>
                {t("sg.patient.modifyRegistryPatient")}
              </BtnDefault>
            </IsPermission>
          </div>}
        </div>
      </h3>
      <DetailPatientRegistry patient={patient} />
    </div>
  );
};

export default RowPatientDetailTemplate;
