import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";
import { concentratorType } from "../concentratorData";


export function TemplateConcentratorPulsedFlowRead({ data }: any) {
  const { t } = useLanguageForShared();


  return (
    <>
      {(data?.concentratorType === concentratorType.fixed_portable || data?.concentratorType === concentratorType.portable || data?.concentratorType === concentratorType.transportable) &&
        <div className="col-sm-12 col-md-12">
          <LabelValue label={t("prescription.template.pulsedFlow.label")}
                      value={data?.pulsedFlow ? t("common.yes") : t("common.no")} className={"me-5"} />
          {data?.pulsedFlow && <>
            <LabelValue
              label={t("prescription.template.pulsedFlowSettings.label")}
              value={data?.pulsedFlowSettings || "-"} className={"me-5"} />
          </>}
        </div>}
    </>
  );
}
