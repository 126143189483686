import React from "react";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import LabelValue from "../../../../shared/custom/labelValue/LabelValue";
import Tag from "../../../../shared/custom/tag/Tag";
import configuration from "../../../../shared/configuration";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import useStatesAction from "../../../utils/useStatesAction";
import { useSelector } from "react-redux";
import { selectCustomerRole, selectCustomerType } from "../../../modules/Auth/_redux/authRedux";
import { customerTypeName } from "../../../../redux/customer/customerData";
import { Col } from "react-bootstrap";
import IconLabel from "../../../../shared/custom/icon/IconLabel";

const OrderRow = ({ order }: { order: any }) => {
  const { t } = useLanguageForShared();
  const { findStates, findStatesLabel } = useStatesAction(t);
  const state = findStates(order?.currentStateCode);
  const customerTypeSel = useSelector(selectCustomerType);
  const listNR = order?.ordersCodes || [];
  const providerList = order?.availableProviders;
  const customer_role = useSelector(selectCustomerRole);

  return (
    <section className={"section-order"}>
      <h3 className={"title-section"}>{t("sg.section.order")}</h3>
      <div className="time-row list-key-value order-detail-row">
				<span>
					<span className={"label-item"}>
						{t("sg.order.numberRequestOrder")}
					</span>
					<span>SG{order.rdOLabel}</span>
				</span>
        <span>
					<span className={"label-item"}>{t("sg.order.dataOrder")}</span>
          {dateformat_ggMMMyyyy(order.dataCreazioneOrdine)}
				</span>
        <span>
					<span className={"label-item"}>{t("sg.order.numberOrder")}</span>
					<span>{order.orderNumber || "-"}</span>
				</span>

        {order.patientCode && order?.patientSource === "lsi" && (
          <div>
            <LabelValue
              label={t("sg.order.treatment")}
              value={<div className={"font-size-lg"}>{order.patientCode}</div>}
              labelClass={"text-sm"}
            />
          </div>
        )}

        <span>
					<span className={"label-item"}>{t("sg.order.channel")}</span>
					<div style={{ marginTop: "-4px" }}>
						{order?.channel === "sg" ? (
              <Tag
                bgColor={configuration.theme.tertiary}
                color={configuration.theme.tertiary_color}
                label={t("sg.order.portal")?.toUpperCase()}
              />
            ) : (
              <Tag
                bgColor={configuration.theme.gray100}
                color={configuration.theme.primaryDark}
                label={t("sg.order.other")?.toUpperCase()}
              />
            )}
					</div>
				</span>


        <span>
					<span className={"label-item"}>{t("sg.order.orderState")}</span>
					<div className={"d-flex align-items-baseline gap-2"}>
              <Tag
                bgColor={state?.tagBgColor}
                color={state?.tagColor}
                label={`${findStatesLabel(order?.currentStateCode, customer_role)}`}
              />
            {order?.cronologyState[0]?.label === "Final.Aperta.Inlavorazione" &&
              <span>
                <span
                  className={"label-item"}>{t("sg.order.fromOf")}</span> {dateformat_ggMMMyyyy(order?.cronologyState[0]?.date)}{" "}
                {(order?.cronologyState[0]?.surname || order?.cronologyState[0]?.neme) && <span
                  className={"label-item"}>{t("sg.order.of")}</span>} {order?.cronologyState[0]?.surname?.toUpperCase()} {order?.cronologyState[0]?.name}
						</span>}

					</div>
				</span>
        <div>
          <div className={""}>
            <div className={"list-key-value"}>
              {listNR &&
                listNR.length > 0 && (
                  <span className={"label-item"}>{t("sg.orderNumbers")}</span>)}
            </div>
            <div className={`d-flex align-items-baseline gap-2`}>
              {listNR.map((ele, i) => (
                <div className={`d-flex align-items-baseline gap-1`} key={i}>
                  {providerList && providerList.length > 1 &&
                    <div>
                      <span>{providerList.find(prv => prv.id === ele.providerId).name} </span>
                    </div>}
                  <div>
                    {ele.number}
                    {i < providerList.length - 1 ? "," : ""}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>

    </section>
  );
};

export default OrderRow;
