import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setIsLsi} from "../../../redux/order/orderSlice";
import {OrderDetailLoad} from "./component/summary/OrderDetailLoad";
import imgFolder from "../../assets/img/emptyFolder.svg";
import {selectPatient, setPatient} from "../../../redux/patient/patientSlice";
import {GetPatientAction} from "../../../redux/patient/patientActions";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function OrderShowPage({ history }) {
  const { t } = useLanguageForShared();

  const {id} = useParams()
  const patient = useSelector(selectPatient)
  const dispatch = useDispatch();
  // const { changeRoute } = useRoute();
  useEffect(() => {


  })
  const setPatientAction = (order) =>{
    dispatch(GetPatientAction(t, order.dettaglioOrdine.patient.id));
    dispatch(setIsLsi())
  }
  const suspensionCallback = () => {
      window.location.href = `${process.env.REACT_APP_URL_LSI}pazienti/${patient.id}/dettaglio`
  }
  return (
      <div className={'container mt-3'}>
        {id ? (
            <OrderDetailLoad
                suspensionCallback={suspensionCallback}
                idOrder={id}
                reloadOrder={setPatientAction}
            />
        ) : (
            <div
                className="p-1 text-center w-100 h-100"
                style={{
                  background: `url(${imgFolder}) center center no-repeat`,
                  backgroundSize: `20%`
                }}>
              {t("sg.noData")}
              {/* <div>
												<img
													src={imgFolder}
													class="img-fluid pt-5 mt-5"
													width={"200px"}
												/>
											</div> */}
            </div>
        )}
  </div>
  );
}
