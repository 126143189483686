import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {getDynamicLanguage} from "./utilityI18n";
import configuration from "../../../configuration";


export const updateI18n = (lang: string, callback: any) => {
  if(i18n.languages.includes(lang)){
    callback()
  } else {
    getDynamicLanguage(lang).then((translation: any) => {
      i18n.addResourceBundle(lang, 'translation', translation)
      callback();
    })
  }
}

export const setI18n = (lang: string, callback: any) => {
  if(!(window as any).translateBase){
    (window as any).translateBase = {}
  }
  if(!(window as any).translateCustom){
    (window as any).translateCustom = {}
  }
  getDynamicLanguage(lang).then((translation: any) => {
    console.log('languages', translation)

    i18n.use(initReactI18next).init({
      resources: {
        [lang]: {
          translation
        }
      },
      lng: lang,
      interpolation: {
        escapeValue: false,
      },
    }).then(load  => {
      configuration.configuration.lang = lang;
      callback && callback();
    })

  })

}


export default i18n
