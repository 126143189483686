import React from "react";
import userManager from "../../../utils/oidcUserManager";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

function Login() {
  const { t } = useLanguageForShared();

  const onLoginButtonClick = () => {
    userManager.signinRedirect();
  };

  return (
    <div className="login-form login-signin">
      <div className="text-center mb-10 mb-lg-20">
      </div>
      <div className="form-group row">
        <div className="col-lg-4">
        </div>
        <div className="col-lg-4 text-center">
          <button
            id="kt_login_signin_submit"
            //type="submit"
            //disabled={isSubmitting}
            onClick={onLoginButtonClick}
            className={`btn btn-primary btn-login font-weight-bold px-9 py-4 my-3`}
          >
            {t("login.label")}
          </button>
        </div>
        <div className="col-lg-4">

        </div>
      </div>
    </div>
  );
}

export default Login;
