import React, { Fragment } from "react";
import { IconSVG } from "../icon/IconSVG";
import configuration from "../../configuration";

const ActonToogleBtn = ({
                          actions,
                          selected,
                          setSelected
                        }: { actions: { name: string, label: string, icon?: string }[], selected: string, setSelected: (sel: string) => void }) => {
  return (
    <div className={"btn-actions-toggle"}>
      {actions?.map((ele, k) => {
        const checked = ele.name === selected;
        return <Fragment key={k}>
          <input type="radio" className="btn-check" name="options" id={ele.name} autoComplete="off"
                 checked={checked} onClick={() => setSelected(ele.name)} />
          <label className={`btn btn-secondary toogle-btn ${checked ? "checked" : ""}`}
                 htmlFor={ele.name}>{ele?.icon && <IconSVG icon={ele?.icon} size={"20px"}
                                                           tintColor={checked ? configuration.theme.light : configuration.theme.primary} />}{ele.label}
          </label>
        </Fragment>;
      })
      }
    </div>
  );
};

export default ActonToogleBtn;
