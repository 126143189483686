import React, { useEffect, useState } from "react";
import { FormElements } from "../../../ui/forms/FormElements";
import { typeData } from "./typeData";
import { getTodayDate, toEnDateString } from "../../../../utils/dateUtils";
import useStatesAction from "../../../../utils/useStatesAction";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
import { useSelector } from "react-redux";
import { selectProviders } from "../../../../../redux/customer/customerSlice";
import { selectCustomerRole, selectCustomerType } from "../../../../modules/Auth/_redux/authRedux";
import { customerTypeName } from "../../../../../redux/customer/customerData";

const FiltersCol = ({ formik }) => {
  const { t } = useLanguageForShared();
  const searchAction = () => {
  };
  const todayDate = toEnDateString(getTodayDate());
  const providers = useSelector(selectProviders);
  const customer_role = useSelector(selectCustomerRole);
  const customerTypeSel = useSelector(selectCustomerType);

  const { states, findStatesLabel } = useStatesAction(t);

  const formStructure = [
    {
      name: "searchAll",
      label: t("sg.stateAction.prescriptionPortalSearch"),
      type: "input",
      col: { xs: 12 }
    },
    {
      name: "dateBy",
      label: t("sg.stateAction.prescriptionPortalBy"),
      type: "input",
      dataElement: {
        type: "date",
        max: formik.values.dateTo ? formik.values.dateTo : todayDate
      },
      col: { xs: 12 }
    },
    {
      name: "dateTo",
      label: t("sg.stateAction.prescriptionPortalTo"),
      type: "input",
      dataElement: {
        type: "date",
        min: toEnDateString(formik.values.dateBy),
        max: todayDate
      },
      col: { xs: 12 }
    },
    // {
    // 	name: "order",
    // 	label: "Ordina per",
    // 	type: "input",
    // 	col: { xs: 12 },
    // },
    providers && providers.length > 1 && {
      name: "providers",
      label: t("sg.stateAction.provider"),
      type: "checkbox",
      display: "flex-column",
      className: "mb-5",
      options: providers.map((ele) => ({ value: ele.id, label: ele.name })),
      col: { xs: "12" }
    },
    /*        {
              type: "checkboxSingle",
              name: "excludeOtherChannels",
              label: t("sg.stateAction.channel"),
              dataElement: {
                label: t("sg.stateAction.portal")
              },
              display: "flex-column",
              className: "mb-5",
              value: true,
              col: { xs: "12" }
            },*/
    {
      name: "channels",
      label: t("sg.stateAction.channel"),
      type: "checkbox",
      display: "flex-column",
      className: "mb-5",
      options: [
        { value: "sg", label: t("sg.stateAction.portal") },
        {
          value: "other",
          label: t("sg.stateAction.other")
        }
      ],
      col: { xs: "12" }
    },
    {
      name: "state",
      label: t("sg.stateAction.prescriptionPortalState"),
      type: "checkbox",
      display: "flex-column",
      className: "mb-5",
      options: states.filter(ele => !ele.flag || ele.flag.includes(customerTypeSel)).map((ele) => ({
        value: ele.code,
        label: findStatesLabel(ele.code, customer_role)
      })),
      col: { xs: "12" }
    },
    customer_role === customerTypeName.bo && {
      name: "type",
      label: t("sg.stateAction.typology.labelTypology"),
      type: "checkbox",
      display: "flex-column",
      options: Object.keys(typeData()).map((ele) => ({
        value: typeData()[ele].name,
        label: typeData()[ele].label
      })),
      col: { xs: "12" }
    }
  ];

  return (
    <div className={"filters-structure"}>
      <FormElements structure={formStructure} formik={formik}></FormElements>
    </div>
  );
};

export default FiltersCol;
