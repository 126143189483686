
export const structureCPAP = [
    {
        name: 'pressure',
        req: true
    },
]

export const structureAUTOST = [
    {
        name: 'ipap_max',
        req: true
    },
    {
        name: 'ipap_min',
        req: true
    },
    {
        name: 'epap_max',
        req: true
    },
    {
        name: 'epap_min',
        req: true
    }
]


export const structureAPAP = [
    {
        name: 'press_min',
        req: true
    },
    {
        name: 'press_max',
        req: true
    },
]

export const structureST = [
    {
        name: 'ipap_max',
    },
    {
        name: 'ipap_min',
    },
    {
        name: 'epap',
        req: true
    },
    {
        name: 'ipap',
        req: true
    },
    {
        name: 'freq_resp',
        req: true
    },
]

export const structureTAPVCS = [
    {
        name: 'ipap_max',
    },
    {
        name: 'ipap_min',
    },
    {
        name: 'epap',
        req: true
    },
    {
        name: 'freq_resp',
        req: true
    },
]

export const structureBILEVELS = [
    {
        name: 'epap',
        req: true
    },
    {
        name: 'ipap',
        req: true
    }
]

export const structureBILEVELAUTO = [
    {
        name: 'ipap_min',
    },
    {
        name: 'ipap_max',
    },
    {
        name: 'ps_min',
    },
    {
        name: 'ps_max',
    },
]


export const modeChoose: any = {
    cpap: structureCPAP,
    autost: structureAUTOST,
    apap: structureAPAP,
    st: structureST,
    t: structureTAPVCS,
    apvc: structureTAPVCS,
    s: structureTAPVCS,
    autocpap: structureAPAP,
    bilevels: structureBILEVELS,
    bilevelauto: structureBILEVELAUTO,
}

export const modeChooseDefaultValue: any = {
    cpap: {
        pressure: '4'
    },
    autost: {
        ipap_max: '20',
        ipap_min: '6',
        epap_max: '20',
        epap_min: '6'
    },
    apap: {
        press_min: '4',
        press_max: '20'
    },
    st: {
        ipap_max: '20',
        ipap_min: '6',
        epap: '6',
        ipap: '12'
    },
    t: {
        ipap_max: '20',
        ipap_min: '6',
        epap: '6'
    },
    apvc: {
        ipap_max: '20',
        ipap_min: '6',
        epap: '6'
    },
    s: {
        ipap_max: '20',
        ipap_min: '6',
        epap: '6'
    },
    autocpap: {
        press_min: '4',
        press_max: '20'
    },
    bilevels: {},
    bilevelauto: {},
}


export const allNameStep = Object.keys(modeChoose).reduce((acc: string[], ele: any) => ([...acc, ...modeChoose[ele].map((el: any) => el.name)]), [])