import * as requestFromServer from "./containerCrud";
import {setErrorTypeAction} from "../errors/errorsActions";
import {setContainers} from "./containerSlice";
import {containerMovement, getContainers} from "./containerCrud";

export const getContainersAction = (t) => (dispatch, getState) => {
	const customer = getState().customer.selectedCustomer;
	return getContainers(customer.id)
		.then((response) => {
			dispatch(setContainers(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t("sg.msg.errorLoadStore")));
		});
};

// export const containerMovementAction = (data) => (dispatch, getState) => {
// 	return containerMovement(data)
// 		.then((response) => {
// 			dispatch(setContainers(response));
// 		})
// 		.catch((error) => {
// 			dispatch(setErrorTypeAction("Errore nel caricamento del magazzino"));
// 		});
// };
