import React from "react";
import ToastMsg from "../../../toast/ToastMsg";

const styleError = {
	style: { color: "#E1000F", bgColor: "#fff" },
	icon: { color: "#E1000F", ico: "error" },
};

const ToastError = ({ className, title, body, closeAction, ...rest }) => {
	return (
		<ToastMsg
			className="shared-toastError"
			toastStyle={styleError}
			title={title}
			body={body}
			closeAction={() => closeAction && closeAction()}></ToastMsg>
	);
};

export default ToastError;
