import React from "react";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function ProductCartRead({ product }) {
	const { t } = useLanguageForShared();

	return (
		<div className="d-flex">
			{product?.specifiedQuantity && !!product?.quantity && (
				<HeadingH4 className="mr-2">{t('sg.quantitySm')} {product?.quantity}</HeadingH4>
			)}
			{product?.specifiedQuantity && !!product?.price && (
				<div className="d-flex">
					<HeadingH4 className="mr-5">
						<span className="mr-2">x</span> € {product?.price.toFixed(2)}
					</HeadingH4>
					<HeadingH4 className="ms-5">
						<span className="mr-2">TOT.</span> €
						{(product?.price * product?.quantity).toFixed(2)}
					</HeadingH4>
				</div>
			)}
		</div>
	);
}
