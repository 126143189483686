import React, { useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import ErrorsManagement from "./ErrorsManagement";
import LoaderFullScreenApp from "../../../app/component/load/LoaderFullScreenApp";
import ModalApp from "../../../app/shared/redux/modal/ModalApp";
import HeaderWrapper from "./HeaderWrapper";
import { actions } from "../../../app/modules/Auth/_redux/authRedux";
import userManager from "../../../app/utils/oidcUserManager";
import { useSelector } from "react-redux";
import {customerType, customerTypeName} from "../../../redux/customer/customerData";
import {selectCustomers, selectSelectedCustomer} from "../../../redux/customer/customerSlice";
import FooterComponent from "../../../libraries/footer/FooterComponent";
import configuration from "../../../shared/configuration";
import ProviderFormModalContext from "../../../app/shared/redux/modal/context/ProviderFormModalContext";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);
  const { user } = useSelector((state) => state.auth);
  const logoutHandler = () => {
    // dispatch(actions.logout())
    userManager.signoutRedirect();
  };
  const customers = useSelector(selectCustomers);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const logo = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/logo/logo${"_sgfe"}.svg`;
  const footerString = `${configuration?.external?.footerLabel || ""}`;

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      {/*<HeaderMobile />*/}
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid"
            id="kt_wrapper">
            {/*
            <Header />
*/}
            <HeaderWrapper organizations={customers} logout={logoutHandler} user={user} logo={logo}
                           currentOrganization={selectedCustomer} />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`
              }>
              {/* {layoutProps.subheaderDisplay && <SubHeader />}*/}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    <ProviderFormModalContext>
                      <LoaderFullScreenApp />
                      <ModalApp />
                      <ErrorsManagement />
                      {children}
                    </ProviderFormModalContext>

                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <FooterComponent customerName={footerString} />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      <QuickPanel />
      {/*    <ScrollTop />*/}
      {/* <StickyToolbar/> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
