import http from "../../app/utils/http";

export const ORDERS_URL = "orders";

export const saveOrder = (data) => http.post(`${ORDERS_URL}`, data);
export const getDeliveries = (customerId) =>
  http.get(`customer-deliveries/${customerId}`);
export const getDeliveriesPatient = (id, template) =>
  http.get(`deliveries/${id}?templateRDO=${template}`);
export const createPatient = (data, id) =>
  http.post(`patients`, { ...data, codiceCliente: id });
export const updatePatient = (data) => http.patch(`patients/${data.id}`, data);
export const searchOrders = (data) => http.post(`orders/search`, data);
export const getOrdersForPatient = (id) => http.get(`orders/patient/${id}`);
export const getOrder = (id) => http.get(`orders/${id}`);
export const changeOrderState = (idOrder, state) =>
  http.patch(`orders/${idOrder}/state`, { state });
export const changeOrderAction = (idOrder, action, data = {}) =>
  http.put(`orders/${idOrder}/actions/${action}`, data);
export const actionPerform = (data) =>
  http.post(`${process.env.REACT_APP_URL_V2}actions/perform`, data);
export const getStates = () => http.get(`states`);

export const updateOrders = (orderId, data) => {
  return http.patch(`orders/${orderId}`, data);
};
