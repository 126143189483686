import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CardBody } from "../../../../_metronic/_partials/controls";
import HistoryList from "./HistoryList";
import { OrderDetailLoad } from "../../order/component/summary/OrderDetailLoad";
import { searchOrders } from "../../../../redux/order/orderCrud";
import { copyObject } from "../../../utils/objUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../redux/customer/customerSlice";
import { setErrorTypeAction } from "../../../../redux/errors/errorsActions";
import { setStopLoader } from "../../../shared/ui/loader/redux/loadSlice";
import { resetErrors } from "../../../../redux/errors/errorsSlice";
import { resetModal, setModal } from "../../../shared/redux/modal/modalSlice";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import { setProvidersAction } from "../../../../redux/customer/customerActions";
import { customerTypeName } from "../../../../redux/customer/customerData";
import { selectCustomerRole, selectCustomerType } from "../../../modules/Auth/_redux/authRedux";
import WelcomeComponent from "./WelcomeComponent";
import PanelBottom from "../../../../shared/custom/panelBottom/PanelBottom";
import PanelActions from "./PanelActions";
import { actionsNewObj } from "../../../utils/actionData";

let polling;
let filterCall;
const HistoryDetail = ({ filters = {} }) => {
  const { t } = useLanguageForShared();

  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState();
  const [listOrders, setListOrders] = useState([]);
  const [orderInEdit, setOrderInEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const customer = useSelector(selectSelectedCustomer);
  const elementForPage = 40;
  const imgFolder = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/emptyFolder.svg`;
  const customerTypeSel = useSelector(selectCustomerType);
  const customer_role = useSelector(selectCustomerRole);

  const searchOrderPolling = (list) => {
    dispatch(setStopLoader(true));
    const copyFilters = dataByFilter(filters);
    searchOrders({
      page: 1,
      clientId: customer.id,
      elementForPage: 100,
      ...copyFilters,
      lastUpdate: true
    })
      .then((res) => {
        const newOrders = res.orders;
        const orders = [...newOrders, ...filterOrdersExist(list, res.orders)];
        setListOrdersAction(orders);
        dispatch(setStopLoader(false));
      });
  };
  const filterOrdersExist = (list, newOrders) => {
    return list.filter(ele => !newOrders.map(el => el.codiceRDO).includes(ele.codiceRDO));
  };
  const timeoutPolling = (list) => {
    polling && clearTimeout(polling);
    polling = setTimeout(() => {
      searchOrderPolling(list);
    }, 60000);
  };
  const handleScroll = (event) => {
    const element = event.target;
    if (
      element.scrollHeight - element.scrollTop < element.clientHeight + 50 &&
      count > listOrders.length
    ) {
      dispatch(resetErrors());
      const nextPage = page + 1;
      const copyFilters = dataByFilter(filters);
      searchOrders({
        page: nextPage,
        clientId: customer.id,
        elementForPage,
        ...copyFilters
      })
        .then((res) => {
          setPage(nextPage);
          const orders = [...filterOrdersExist(listOrders, res.orders), ...res.orders];
          setListOrdersAction(orders);
        })
        .catch((error) => {
          dispatch(setErrorTypeAction(t("sg.msg.errorSearchOrders")));
        });
    }
  };
  const setListOrdersAction = (orders) => {
    setListOrders(orders);
    timeoutPolling(orders);
  };
  const reloadOrder = (order) => {
    setListOrdersAction(listOrders.map((ele) => (ele.codiceRDO === order.codiceRDO ? { ...ele, ...order } : ele)));
  };
  const suspensionCallback = (order) => {
    setListOrdersAction(listOrders.map((ele) => (ele.codiceRDO === order.oldIdRdO ? { ...ele, ...order } : ele)));
    setSelectedId(order.codiceRDO)
  };

  const dataByFilter = (fil) => {
    const copyFilters = copyObject(fil);
    if (!copyFilters.state?.length) {
      copyFilters.state = undefined;
    }
    if (!copyFilters.providers?.length) {
      copyFilters.providers = undefined;
    }
    if (!copyFilters.type?.length) {
      copyFilters.type = undefined;
    }
    if (!copyFilters.dateBy) {
      copyFilters.dateBy = undefined;
    }
    if (!copyFilters.dateTo) {
      copyFilters.dateTo = undefined;
    }
    return copyFilters;
  };
  useEffect(() => {
    dispatch(setProvidersAction());
    filterCall && clearTimeout(filterCall);
    filterCall = setTimeout(() => {
      dispatch(resetErrors());
      const copyFilters = dataByFilter(filters);
      setPage(1);
      searchOrders({
        page: 1,
        elementForPage,
        clientId: customer.id,
        ...copyFilters
      })
        .then((res) => {
          setListOrdersAction(res.orders);
          setCount(res.count);
          //setSelectedId(!!res.orders.length && res.orders[0]?.codiceRDO);
        })
        .catch((error) => {
          dispatch(setErrorTypeAction(t("sg.msg.errorSearchOrders")));
        });
    }, 500);

  }, [filters]);

  const buttonList = [
    {
      label: t("sg.understand"),
      action: "confirmModal",
      type: "primary",
      dataType: {}
    }
  ];
  const communicationsHasEditNotSaved = () => {
    dispatch(
      setModal(
        buttonList,
        {
          title: t("sg.msg.modals.editNotSaved"),
          body: t("sg.msg.modals.editNotSavedBody")
        },
        { confirmModal }
      )
    );
  };
  const confirmModal = () => {
    dispatch(resetModal());
  };

  const clickOrder = (order) => {
    setSelectedId(order.codiceRDO);
    //communicationsHasEditNotSaved(); //TODO MASSY al click su altro ordine se c'è una modifica fa comparire la modale di avviso
  };

  /*  const { changeRoute } = useRoute();
    const goToNewPrescription = () => {
      changeRoute(routeName.patientsPage);
    };*/

  const heightContentHistory = `calc(100vh - 75px)`;


  return (
    <>

      <div className="row-history-content">
        <div className="pe-0 col-history-list">
          <Card className="history-card">
            <CardBody
              onScroll={handleScroll}
              style={{ height: heightContentHistory, overflow: "auto" }}>
              <HistoryList
                listOrders={listOrders}
                selectedId={selectedId}
                clickElement={(order) => clickOrder(order)}
              />
            </CardBody>
          </Card>
        </div>


        <OrderDetailLoad
          idOrder={selectedId}
          reloadOrder={reloadOrder}
          suspensionCallback={suspensionCallback}
          setOrderInEdit={setOrderInEdit}
          closeDetailHandler={() => setSelectedId(undefined)}
        />


      </div>
    </>
  );
};
export default HistoryDetail;



