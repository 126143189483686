import React from "react";
import { FormControl } from "react-bootstrap";

const TextArea = ({ ele, formik, value }) => (
	<FormControl
		className="mb-2"
		as="textarea"
		rows={ele.rows || 2}
		value={value}
		onChange={formik.handleChange}
		{...(ele.dataElement || {})}
		name={ele.name}
	/>
);

export default TextArea;
