import React, {useEffect} from 'react';
import {FormElements} from "../../../../../../shared/bootstrap/form/FormElements";
import {useTranslation} from 'react-i18next'
import {copyObject} from "../../../../../../shared/utils/objUtils";
import {useVentiloData} from "./data/useVentiloData";
import {useParamsData} from "./data/useParamsData";
import { useTemplateFormOtherInfo } from "./data/useTemplateFormOtherInfo";
import {useEffectOnlyUpdate} from "../../../../../../shared/utils/use_custom/useEffectCustom";


const VentiloTemplate = ({
                             dataForm,
                             errors,
                             changeValue,
                             setData,
                             isSubmit
                         }: { dataForm?: any, errors: any, changeValue: (data: any) => void, setData: (data: any) => void, isSubmit?: boolean }) => {
        const {t} = useTranslation()
        const {
            formStructurePathologies,
            formStructureMode,
            formStructureDevice,
        } = useVentiloData(t, dataForm, setData)
        const {
            formStructureDetailInterface,
            formStructureDetailHumidifier,
          formStructureInterface,
          formStructureHumidifier,
        } = useParamsData(t, dataForm)
        const {
          formStructureCannulaTracheostomy,
            formStructureDetailCannulaTracheostomy
        } = useTemplateFormOtherInfo(t, dataForm);
        const {
          formStructureNote,
        } = useParamsData(t, dataForm)

    useEffectOnlyUpdate(() => {
        if (!dataForm.interface) {
            const modeField = formStructureDetailInterface.map((ele: any) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key: string) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.interface]);

    useEffectOnlyUpdate(() => {
        if (!dataForm.cannulaTracheostomy) {
            const modeField = formStructureDetailCannulaTracheostomy.map((ele: any) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key: string) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.cannulaTracheostomy]);

    useEffectOnlyUpdate(() => {
        if (!dataForm.humidifier) {
            const modeField = formStructureDetailHumidifier.map((ele: any) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key: string) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.humidifier]);
        const changePathologyData: (data: any, val: any) => void = (data, val) => {

            if(dataForm?.pathology?.includes("Other") && data.name === 'pathology' && !data.value.includes("Other")){
                changeValue({
                    value: {
                        pathology:data.value,
                        otherPathologies: ''
                    }
                })
            } else {
                changeValue(data)
            }

        }
        return (
            <div>
                <FormElements
                    structure={formStructurePathologies}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changePathologyData}
                    isSubmit={isSubmit}
                />
              <p className={"title-paragraph"}>{t('sg.prescription')}</p>
              <FormElements
                    structure={formStructureMode}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                    isSubmit={isSubmit}
                />
                <FormElements
                    structure={formStructureDevice}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                    isSubmit={isSubmit}
                />
              <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
              <FormElements
                    structure={formStructureInterface}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                    isSubmit={isSubmit}
                />
              <FormElements
                    structure={formStructureCannulaTracheostomy}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                    isSubmit={isSubmit}
                />
                <FormElements
                    structure={formStructureHumidifier}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                    isSubmit={isSubmit}
                />
              <FormElements
                structure={formStructureNote}
                dataForm={dataForm}
                changeValue={changeValue}
                errors={errors}
                isSubmit={isSubmit}
              />
            </div>
        );
    }
;

export default VentiloTemplate;
