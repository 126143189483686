import React, {useEffect} from 'react';
import {FormElements} from "../../../../../../shared/bootstrap/form/FormElements";
import {useTranslation} from 'react-i18next'
import {useSleepData} from "./data/useSleepData";
import {useParamsData} from "./data/useParamsData";
import {copyObject} from "../../../../../utils/objUtils";


const SleepTemplate = ({
                           dataForm,
                           errors,
                           changeValue,
                            setData,
                           isSubmit
                       }: { dataForm?: any, errors: any, changeValue: (data: any) => void, setData: (data: any) => void , isSubmit?: boolean}) => {
        const {t} = useTranslation()
        const {
            formStructureMode,
            formStructureDevice,
        } = useSleepData(t, dataForm)
    const {
        formStructureDetailRampa,
        formStructureRampa,
    } = useParamsData(t, dataForm)
    useEffect(() => {
        //se rampa è false svuota i campi del tamplate rampa
        if (!dataForm.rampa) {
            const modeField = formStructureDetailRampa.map((ele: any) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key: string) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.rampa]);
        return (
            <div>
              <p className={"title-paragraph"}>{t('sg.prescription')}</p>
              <FormElements
                  isSubmit={isSubmit}
                    structure={formStructureMode}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
                <FormElements
                    isSubmit={isSubmit}
                    structure={formStructureDevice}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
              <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
              <FormElements
                  isSubmit={isSubmit}
                    structure={formStructureRampa}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
            </div>
        );
    }
;

export default SleepTemplate;
