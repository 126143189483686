import React from "react";
import { Col, Row } from "react-bootstrap";

export default function TitleCard({ title, subtitle, classNames }) {
  return (
    <Row className={`w-100 mt-5`}>
      <Col xs="12" className={`title-page ps-0 ms-2 ${classNames ? classNames : ""}`}>
        <h2 style={{ "fontSize": "1.32rem" }}>{title}</h2>
        <span className={"fw-light"}>{subtitle}</span>
      </Col>

    </Row>
  );
}
