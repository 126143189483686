import React from "react";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function SummaryLightOrder({ products = [], isPharmacy }) {
const{t}=useLanguageForShared();

	return (
		<div>
			<HeadingH4>{isPharmacy ? t('sg.products'): t("sg.order.prescriber.prescriptionLeft")}</HeadingH4>
			<div>
				{products.map((ele) => (
					<div key={ele.id} className="mb-3">
						– {ele.title}
					</div>
				))}
			</div>
		</div>
	);
}
