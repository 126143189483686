import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getContainersAction } from "../../../../../redux/container/containerActions";
import { containerMovement } from "../../../../../redux/container/containerCrud";
import {
	getPatient,
	getPatients,
} from "../../../../../redux/patient/patientCrud";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import { FormElements } from "../../../../shared/ui/forms/FormElements";
import BoxSearchPatients from "../../../sample/templateGenFar/component/BoxSearchPatients";
import { formStructureNominativeRowWithTelephone } from "../../../sample/templateGenFar/templateGenFarData";
import { validationSchemaNominativeWithTelephone } from "../../../sample/templateGenFar/validations/nominativeValidationData";
import Error from "../../../../shared/ui/forms/error/Error";
import {
	createPatient,
	updatePatient,
} from "../../../../../redux/order/orderCrud";
import { setErrorTypeAction } from "../../../../../redux/errors/errorsActions";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { setStopLoader } from "../../../../shared/ui/loader/redux/loadSlice";
import axios from "axios";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
let stopAction = false;
let timeoutAction;
let cancelToken;
export function Trace({ container, resetShowTrace }) {
	const { t } = useLanguageForShared();

	const [patients, setPatients] = useState([]);
	const dispatch = useDispatch();
	const selectedCustomer = useSelector(selectSelectedCustomer);

	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaNominativeWithTelephone,
		onSubmit: (values) => {
			(formik.values.patient.id
				? updatePatient(formik.values.patient)
				: createPatient(formik.values.patient, selectedCustomer.id)
			)
				.then((res) => {
					movementAction({ ...formik.values, patient: res });
				})
				.catch((err) => {
					const message = err?.response?.data?.Message;
					if (message === "ERR_CF_DUPLICATE") {
						dispatch(setErrorTypeAction(t('sg.msg.cfAlreadyRegistered')))
					} else if (message === "ERR_CF_INCOHERENT") {
						dispatch(setErrorTypeAction(t('sg.msg.cfWrong')))
					} else if (message === "ERR_EMAIL_DUPLICATE") {
						dispatch(setErrorTypeAction(t('sg.msg.emailAlreadyRegistered')))
					} else if (message === "ERR_EMAIL_INCOHERENT") {
						dispatch(setErrorTypeAction(t('sg.msg.emailWrong')))
					} else {
						dispatch(
							setErrorTypeAction(
								formik.values.patient.id
									? t('sg.msg.errorUpdatePatient')
									: t('sg.msg.errorInsertPatient')
							)
						);
					}
				});
		},
	});
	const disassociate = formik.values.container?.deliveryPlace;
	const handleTextInput = (event) => {
		if (event.target.localName !== "input") {
			if (
				/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/.test(
					event.data
				)
			) {
				formik.setFieldValue("patient.cf", event.data);
			} else {
				!container.fixed &&
					formik.setFieldValue("container.serial", event.data);
			}
		}
	};
	useEffect(() => {
		document.addEventListener("textInput", handleTextInput, true);
		return () => {
			document.removeEventListener("textInput", handleTextInput, true);
		};
	}, []);
	const setPatient = (pat) => {
		stopAction = true;
		getPatient(pat.id).then((res) => {
			setPatients([]);
			formik.setFieldValue("patient", { ...res });
			setTimeout(() => {
				stopAction = false;
			}, 1000);
		});
	};
	useEffect(() => {
		if (
			!container?.deliveryPlace &&
			!stopAction &&
			(formik.values.patient?.name ||
				formik.values.patient?.surname ||
				formik.values.patient?.cf)
		) {
			dispatch(setStopLoader(true));
			if (typeof cancelToken != typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}
			cancelToken = axios.CancelToken.source();
			getPatients(
				{
					name: formik.values.patient?.name,
					surname: formik.values.patient?.surname,
					cf: formik.values.patient?.cf,
					customerCode: selectedCustomer.id,
				},
				cancelToken
			)
				.then((res) => {
					setPatients(res);
					timeoutAction && clearTimeout(timeoutAction);
					timeoutAction = setTimeout(() => {
						dispatch(setStopLoader(false));
					}, 500);
				})
				.catch((error) => {
					error?.response?.status &&
						dispatch(setErrorTypeAction(t('sg.msg.errorLoadPatients')));
				});
		} else {
			setPatients([]);
		}
	}, [
		formik.values.patient?.name,
		formik.values.patient?.surname,
		formik.values.patient?.cf,
	]);
	useEffect(() => {
		container && formik.setFieldValue("container", container);
		container?.deliveryPlace &&
			formik.setFieldValue("patient", container.deliveryPlace);
	}, [container]);
	const formStructureSerialRow = [
		{
			name: "container.serial",
			label: t('sg.serial'),
			type: "input",
			disabled: container,
			col: { xs: "12", sm: "6", lg: "3" },
		},
	];
	const movementAction = (values) => {
		containerMovement({
			action: disassociate ? "Detach" : "Associate",
			patientCode: values.patient?.id,
			serial: values.container?.serial,
			clientCode: selectedCustomer.id,
		}).then(
			(res) => {
				dispatch(getContainersAction(t));
				resetShowTrace();
			},
			(err) => {
				err.response.status === 404
					? dispatch(setErrorTypeAction(t('sg.msg.serialNotFound')))
					: dispatch(
							setErrorTypeAction(
								t('sg.msg.errorToAssociate')
							)
					  );
			}
		);
	};
	return (
		<Form onSubmit={formik.handleSubmit}>
			<FormElements
				structure={formStructureSerialRow}
				formik={formik}></FormElements>
			<FormElements
				structure={
					container?.deliveryPlace
						? formStructureNominativeRowWithTelephone(t).map((ele) => ({
								...ele,
								disabled: true,
						  }))
						: formStructureNominativeRowWithTelephone(t)
				}
				formik={formik}></FormElements>
			{patients && !!patients.length && (
				<BoxSearchPatients patients={patients} setPatient={setPatient} />
			)}
			{formik.errors?.patient?.id && (
				<Error>{formik.errors?.patient?.id}</Error>
			)}
			<Row className="justify-content-end">
				<BtnSecondary className="me-3" onClick={resetShowTrace}>
					{t("action.cancel")}
				</BtnSecondary>
				<BtnPrimary type="submit">{disassociate ? t("sg.comeBack") : t("sg.delivery")}</BtnPrimary>
			</Row>
		</Form>
	);
}
