import React from "react";
import { Col, Row } from "react-bootstrap";
import WizardStep from "../step/WizardStep";

const WizardGroup = ({ listStep }) => {


  const checkCurrentActive = () => {
    return listStep.findLastIndex(ele => ele.isActive);
  };
  return (
    <Row className={"mt-3"}>
      <Col xs="12">
        <div className={`shared-wizardGroup`}>
          {listStep.map((step, i) => (
            <WizardStep
              key={i}
              label={step.label}
              index={i}
              isActive={step.isActive}
              isLast={checkCurrentActive() === i}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default WizardGroup;
