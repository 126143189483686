import React from "react";
import {useLanguageForShared} from "../../utils/languages/useLanguageForShared";

const EmptyData = ({ title, subtitle, dataTest }: { title?: string, subtitle?: string, dataTest?: string }) => {
    const {t} = useLanguageForShared();
  const emptyImg = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/empty_data.svg`;

  return (
    <div className={"d-flex align-items-center justify-content-center p-5"}
         data-test={dataTest ? dataTest : "no-data"}>
      <div className={"d-flex flex-column justify-content-center align-items-center"}>
        <img src={emptyImg} width={"180px"} />
        <h4><b>{title ? title : t("msg.emptyData.title")}</b></h4>
        <div>{subtitle ? subtitle : t("msg.emptyData.subTitle")}</div>
      </div>
    </div>
  );
};

export default EmptyData;
