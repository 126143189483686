import React from "react";
import { DropDelete } from "../../order/component/summary/DropDelete";
import { actionsNewObj, findAction } from "../../../utils/actionData";
import { DropRefuse } from "../../order/component/summary/DropRefuse";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../shared/configuration";
import { customerTypeName } from "../../../../redux/customer/customerData";
import { exportPdf } from "../../../utils/exportPdf";
import { useSelector } from "react-redux";
import { selectCustomerType } from "../../../modules/Auth/_redux/authRedux";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

const ActionHeader = ({
                        order,
                        changeState,
                        refComp,
                        closeDetailHandler
                      }: {
  order: any,
  changeState: (ele: any) => void,
  refComp,
  closeDetailHandler: () => void
}) => {
  const { t } = useLanguageForShared();

  const customerTypeSel = useSelector(selectCustomerType);

  return (
    <div className={"d-flex align-items-center gap-0 btn-group ms-auto"}>
      {order?.actions?.includes(actionsNewObj["AZ-Prendeincarico"]) &&
        <BtnDefault
          className="border-0 p-2 h-auto text-capitalize"
          onClick={() => changeState({
            action: {
              Name: "AZ-Prendeincarico"
            }
          })}
          icon={"take-charge"}
          tintColor={configuration.theme.primary}
          iconSize={"24px"}
        >
          {t("sg.stateAction.takeCharge")}
        </BtnDefault>}

      {order?.actions?.includes(actionsNewObj["AZ-Approva"]) &&
        <BtnDefault
          className="border-0 p-2 h-auto text-capitalize"
          onClick={() => changeState({
            action: {
              Name: "AZ-Approva"
            },
            msg: {
              title: t("sg.msg.confirmApproveTitle"),
              body: t("sg.msg.confirmApproveBody")
            }
          })}
          icon={"approve"}
          tintColor={configuration.theme.primary}
          iconSize={"24px"}
        >
          {t("sg.stateAction.approve")}
        </BtnDefault>}
      {order?.actions?.includes(actionsNewObj["AZ-Nonapprova"]) &&
        <BtnDefault
          className="border-0 p-2 h-auto text-capitalize"
          onClick={() => changeState({
            action: {
              Name: "AZ-Nonapprova"
            },
            msg: {
              title: t("sg.msg.confirmNotApproveTitle"),
              body: t("sg.msg.confirmNotApproveBody")
            }
          })}
          icon={"not_approve"}
          tintColor={configuration.theme.primary}
          iconSize={"24px"}
        >
          {t("sg.stateAction.notApprove")}
        </BtnDefault>}

      {order?.actions?.includes(actionsNewObj["AZ-Richiedicancellazione"]) &&
        <BtnDefault
          className="border-0 p-2 h-auto text-capitalize"
          onClick={() => changeState({
            action: {
              Name: "AZ-Richiedicancellazione"
            },
            msg: {
              title: t("sg.msg.confirmRequestDeleteTitle"),
              body: t("sg.msg.confirmRequestDeleteBody")
            }
          })}
          icon={"request_delete"}
          tintColor={configuration.theme.primary}
          iconSize={"24px"}
        >
          {t("sg.stateAction.requestDelete")}
        </BtnDefault>}


      {/*{order.actions?.map((ele, key) => (<div key={key}>
        {ele.id === 5 ? (<DropDelete
          ele={ele}
          clickClient={() => changeState(findAction(ele.id) || ele, { proxState: 7 })}
          clickBO={() => changeState(findAction(ele.id) || ele, { proxState: 6 })}
        />) : ele.id === 6 ? (<DropRefuse
          ele={ele}
          submitHandler={(data) => changeState(findAction(ele.id) || ele, data)}
        />) : (<BtnDefault
          className="border-0 p-2 h-auto text-capitalize"
          onClick={() => changeState(findAction(ele.id) || ele)}
          icon={"priority_message"}
          tintColor={configuration.theme.primary}
          iconSize={"24px"}
        >
          {findAction(ele.id)?.label}
        </BtnDefault>)}
      </div>))}*/}
      {customerTypeSel !== customerTypeName.pharmacy && <BtnDefault
        className="border-0 p-2 h-auto text-capitalize"
        onClick={() => exportPdf(`patient_${order?.patient || ""}`, refComp)}
        icon={"pdf"}
        tintColor={configuration.theme.primary}
        iconSize={"24px"}
      >
        {t("sg.printPrescription")}
      </BtnDefault>}
      <BtnDefault
        className="border-0 p-2 h-auto text-capitalize ms-2"
        onClick={closeDetailHandler}
        icon={"close"}
        tintColor={configuration.theme.primary}
        iconSize={"24px"}
      >
      </BtnDefault>
    </div>
  );
};

export default ActionHeader;
