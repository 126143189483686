import { required } from "../../../../../../../shared/utils/forms/utils/validationType";

export const useValidationOxygen = (t: any, dataForm: any) => {
  const validations = [
    {
      name: "pathology",
      validations: [
        {
          name: required,
          params: {
            name: t("common.pathologies")
          }
        }
      ]
    },
    {
      name: "flowRest",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.flowRest")
          }
        }
      ]
    },
    {
      name: "underStressFlow",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.underStressFlow")
          }
        }
      ]
    },
    {
      name: "nightFlow",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.nightFlow")
          }
        }
      ]
    }
  ];

  return validations;

};
