export const columnsContainers = (t) => [
  {
    dataField: "codeProduct",
    text: t("sg.product"),
    classes: "text-left pe-0",
    headerClasses: "text-left pe-3",
    style: {
      minWidth: "100px"
    }
  },
  {
    dataField: "descriptionSummary",
    text: "",
    classes: "text-left pe-0",
    headerClasses: "text-left pe-3",
    style: {
      minWidth: "100px"
    }
  },
  {
    dataField: "serial",
    text: t("sg.serial"),
    classes: "text-left pe-0",
    headerClasses: "text-left pe-3",
    style: {
      minWidth: "100px"
    }
  }
];

export const typeViewContainer = {
  trace: "trace",
  collect: "collect"
};
