import { DatePickerField } from "../../_metronic/_partials/controls";

export const stringDateToTimestamp = (stringDate: any): any =>
	typeof stringDate === "string" ? new Date(stringDate).getTime() : stringDate;

export const getTodayDate = () => new Date();

export const getTodayTimestamp = () => stringDateToTimestamp(getTodayDate());

export const minArrayDate = (dates: any[]) => {
	const arr = dates ? dates.filter((ele: any) => ele) : [];
	return arr.reduce((data: any, ele) => {
		return data ? minDate(data, ele) : ele;
	}, undefined);
};

export const maxArrayDate = (dates: any[]) => {
	const arr = dates ? dates.filter((ele: any) => ele) : [];
	return arr.reduce((data: any, ele) => {
		return data ? maxDate(data, ele) : ele;
	}, undefined);
};

export const maxDate = (date1: any, date2: any) => {
	return stringDateToTimestamp(date2) > stringDateToTimestamp(date1)
		? date2
		: date1;
};

export const minDate = (date1: any, date2: any) => {
	return stringDateToTimestamp(date2) < stringDateToTimestamp(date1)
		? date2
		: date1;
};

export const localdateString = (date: string | number) => {
	return new Date(date).toLocaleDateString("it-IT");
};

export const toEnDateString = (date: string | number | Date) => {
	const d = new Date(date);
	return `${d.getFullYear()}-${padNumber(d.getMonth() + 1)}-${padNumber(
		d.getDate()
	)}`;
};

export const getEndOfDay = (date: string | number) => {
	return new Date(new Date(new Date(date).setMinutes(59)).setHours(23));
};

export const getStartOfDay = (date: string | number) => {
	return new Date(new Date(new Date(date).setMinutes(0)).setHours(0));
};

export const padNumber = (value: number) => `0${value}`.slice(-2);

export const dateformat_ggMMMyyyy = (date: string | number) =>
	new Date(date).toLocaleString("it-IT", {
		day: "2-digit",
		month: "short",
		year: "numeric",
	});

export const copyDate = (date: string | number | Date) => {
	return new Date(date);
};

export const nextDayDate = (d: string | number | Date, days: number = 1) => {
	const copy = copyDate(d);
	return new Date(copy.setDate(copy.getDate() + days));
};
export const prevDayDate = (d: string | number | Date, days: number = 1) => {
	const copy = copyDate(d);
	return new Date(copy.setDate(copy.getDate() - days));
};
