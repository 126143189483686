import React from 'react'
import {FormGroupProps} from '../models/FormModule'
import Error from '../error/Error'
import Label from "../label/Label";


const FormGroup = ({display, label, subLabel, rightTemplate, children, error, dataTest, name}: FormGroupProps) => {
    const classes = [...(display?.child ? [display.child] : []), ...(dataTest ? [dataTest] : []), 'data-test-select-sh-' + name]
    return (
        <div className="form-group">
            <div className={display?.form ? display.form : ''}>
                {label && label.label ? (
                    <div className={display?.label ? display.label : ''}>
                        <Label {...label} />
                    </div>
                ) : null}

                {subLabel && subLabel.label ? (
                    <div className={display?.label ? display.label : ''}>
                        <Label {...subLabel} className={'subLabel'}/>
                    </div>
                ) : null}

                <div className={classes.join(' ')}>
                    {children}
                    {error && <Error {...error} />}
                </div>
                {rightTemplate ? (
                    <div className={display?.rightTemplate ? display.rightTemplate : ''}>
                        {rightTemplate}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default FormGroup
