import React from "react";

const Label = ({ label, children, className, ...rest }) => {
	return (
		<label className={`shared-label ${className}`} {...rest}>
			{label ? label : children}
		</label>
	);
};

export default Label;
