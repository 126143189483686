import { Routes } from "./models/routes";
import * as routeName from "./routeNameAuthType";
import { ProductsPage } from "../pages/products/ProductsPage";
import { Sample } from "../pages/sample/Sample";
import { CartPage } from "../pages/cart/CartPage";
import { HistoricalPage } from "../pages/historical/HistoricalPage";
import { SelectProductPage } from "../pages/products/selectProduct/SelectProductPage";
import { OrderPage } from "../pages/order/OrderPage";
import { PatientPage } from "../pages/patients/PatientPage";
import { PatientsPage } from "../pages/patients/PatientsPage";
import { customerTypeName } from "../../redux/customer/customerData";
import { Containers } from "../pages/containers/Containers";
import { SelectPatientPage } from "../pages/patients/SelectPatientPage";
import { PatientEditPage } from "../pages/patients/PatientEditPage";
import { EditProductPage } from "../pages/products/selectProduct/EditProductPage";
import { RedirectActiveOrderPage } from "../pages/order/RedirectActiveOrderPage";
import { OrderShowPage } from "../pages/order/OrderShowPage";
import { RedirectEditOrderPage } from "../pages/order/RedirectEditOrderPage";
import { RedirectPatientPage } from "../pages/order/RedirectPatientPage";
import { t } from "i18next";
import {permissionsData} from "../modules/Auth/_redux/permissionsData";

const routes: (() => Routes) = () => {
  return {
    list: [
      {
        path: "/storico",
        component: HistoricalPage,
        name: routeName.historical,
        icon: "list",
        navTitle: { label: t("sg.menu.history") },
        roles: [customerTypeName.pharmacy]
      },
      {
        path: "/richieste",
        component: HistoricalPage,
        name: routeName.historical,
        icon: "list",
        navTitle: { label: t("sg.menu.historyPrescription") },
        roles: [customerTypeName.prescriber, customerTypeName.bo]
      },
      {
        path: "/prodotti",
        component: ProductsPage,
        name: routeName.products,
        icon: "store",
        navTitle: { label: t("sg.menu.products") },
        roles: [customerTypeName.pharmacy],
        permissions: [permissionsData.canEdit, permissionsData.hasBuyer]
      },
      {
        path: "/prodotti",
        component: ProductsPage,
        name: routeName.products,
        roles: [customerTypeName.prescriber],
        permissions: [permissionsData.hasBuyer]
      },
      {
        path: "/acquisto",
        component: SelectProductPage,
        name: routeName.selectProduct,
        permissions: [permissionsData.canEdit]
      },
      {
        path: "/modifica-ordine",
        component: EditProductPage,
        name: routeName.selectEditProduct,
        permissions: [permissionsData.canEdit]
      },
      {
        path: "/ordine",
        component: OrderPage,
        name: routeName.orderPage,
        permissions: [permissionsData.canEdit]
      },
      {
        path: "/paziente",
        component: PatientPage,
        name: routeName.patientPage
      },
      {
        path: "/paziente/edit",
        component: PatientEditPage,
        name: routeName.patientEditPage,
        permissions: [permissionsData.canEdit]
      },
      {
        path: "/paziente-sel",
        component: SelectPatientPage,
        name: routeName.selectedPatientPage,
        roles: [customerTypeName.prescriber]
      },
      {
        path: "/paziente/:id",
        component: RedirectPatientPage,
        name: routeName.redirectPatientPage,
        roles: [customerTypeName.prescriber]
      },
      {
        path: "/paziente/:id/active",
        component: RedirectActiveOrderPage,
        name: routeName.redirectActiveOrder,
        roles: [customerTypeName.prescriber]
      },
      {
        path: "/order/:id/actions/:type",
        component: RedirectEditOrderPage,
        name: routeName.redirectEditOrder,
        roles: [customerTypeName.prescriber]
      },
      {
        path: "/ordine/:id",
        component: OrderShowPage,
        name: routeName.orderShowPage,
        roles: [customerTypeName.prescriber]
      },
      {
        path: "/pazienti",
        component: PatientsPage,
        name: routeName.patientsPage,
        icon: "user-circle",
        navTitle: { label: t("sg.menu.newPrescription") },
        roles: [customerTypeName.prescriber],
        permissions: [permissionsData.hasBuyer]
      },

      {
        path: "/carrello",
        component: CartPage,
        name: routeName.cart,
        icon: "cart",
        navTitle: { label: t("sg.menu.cart") },
        notify: "3",
        roles: [customerTypeName.pharmacy],
        permissions: [permissionsData.canEdit, permissionsData.hasBuyer]
      },
      {
        path: "/magazzino",
        component: Containers,
        name: routeName.containersPage,
        icon: "user-circle",
        navTitle: { label: t("sg.menu.tracking") },
        roles: [customerTypeName.pharmacy],
        permissions: [permissionsData.canEdit, permissionsData.hasBuyer]
      }
    ],
    redirect: routeName.redirectPath,
    firstPage: routeName.firstPage
  };
};
export default routes;
