import store from "../../redux/store";
import auth from "./auth";
import {customerTypeName} from "../../redux/customer/customerData";


export const getRoutes = () => {
  const user = store.getState().auth.user;
  const customer = store.getState().customer.selectedCustomer
  const customerType = user?.role === customerTypeName.bo ? customerTypeName.bo : customer?.type
  return auth().list.filter(r => !r.roles || r.roles.includes(customerType))
} ;
