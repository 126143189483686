export const usePathologiesData = (t: any, dataForm: any) => {
  const pathologieSample = { //ci arrivano da be?
    "COPD": "BPCO",
    "OSAS": "OSAS",
    "COPDSAS": "BPCO-OSAS",
    "OHS": "OHS",
    "CSA": "CSAS",
    "RestrictivePathology": "Patologia restrittiva",
    "NeuroMuscularDisease": "Malattia neuro-muscolare",
    "Other": "Altro",
    "Obesity": "Obesità",
    "Asthma": "Asma",
    "RespiratoryFailure": "Insufficienza respiratoria",
    "HeartFailure": "Insufficienza cardiaca",
    "KidneyFailure": "Insufficienza renale",
    "Hyperthyroidism": "Ipertiroidismo",
    "Hypothyroidism": "Ipotiroidismo",
    "IschemicHeartDisease": "Cardiopatia ischemica",
    "Cerebrovascular": "M. Cerebrovascolare",
    "Diabetes": "Diabete II",
    "RLS_PLM": "RLS/PLM",
    "Insomnia": "Insonnia",
    "Anxiety": "Ansia",
    "Parkinson": "Parkinson"
  };

  
  const formStructurePathologies = [
    {
      name: "pathology",
      type: "select",
      label: `${t("common.pathologies")}*`,
      options: Object.keys(pathologieSample).map(ele => ({
          label: t(`pathology.${ele}`),
          value: ele
        })
      ),
      dataElement: {
        type: "text",
        multi: true
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" }
    },
    ...(dataForm?.pathology?.includes("Other") ? [{
      name: "otherPathologies",
      type: "input",
      label: `${t("common.otherPathologies")}`,
      dataElement: {
        placeholder: t(`common.otherPathologies_placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "6", lg: "6", xl: "6" }
    }] : [])
  ];


  return {
    formStructurePathologies
  };
};
