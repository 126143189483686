import i18n from "i18next";

export const finalizeOrderStep = {
  detail: "detail",
  finalize: "finalize",
  summary: "summary"
};

export const nameSteps = {
  patient: "patient",
  products: "products",
  ...finalizeOrderStep,
  cart: "cart"
};

const commonSteps = () => ([
  { label: i18n.t("sg.steps.requestDetail"), name: nameSteps.finalize },
  { label: i18n.t("sg.steps.summary"), name: nameSteps.summary }
]);

export const listStepPatient = () => ([
  { label: i18n.t("sg.steps.selectPatient"), name: nameSteps.patient },
  { label: i18n.t("sg.steps.service"), name: nameSteps.products },
  { label: i18n.t("sg.steps.detail"), name: nameSteps.detail },
  ...commonSteps()
]);

export const listStepPharmacyFromCart = () => ([
  { label: i18n.t("sg.steps.cart"), name: nameSteps.cart },
  ...commonSteps()
]);

export const listStepPharmacyFromProduct = () => ([

  { label: i18n.t("sg.steps.product"), name: nameSteps.products },
  { label: i18n.t("sg.steps.detail"), name: nameSteps.detail },
  ...commonSteps()

]);

export const listStepPatientEdit =()=>( [
  { label: i18n.t("sg.steps.editService"), name: nameSteps.detail },
  ...commonSteps()

]);


export const getClassicListSteps = (isPharmacy, fromCart) => {
  return isPharmacy ? (fromCart ? listStepPharmacyFromCart() : listStepPharmacyFromProduct()) : listStepPatient();
};

export const getListSteps = (isPharmacy, fromCart, selectedStep, isEdit) => {
  return isEdit && Object.values(finalizeOrderStep).includes(selectedStep) ? listStepPatientEdit() : getClassicListSteps(isPharmacy, fromCart);
};

export const getListWithSelectedStep = (isPharmacy, fromCart, selectedStep, isEdit) => {
  const listStep = getListSteps(isPharmacy, fromCart, selectedStep, isEdit);
  const index = listStep.findIndex(ele => ele.name === selectedStep);
  return listStep.map((ele, i) => ({
    label: ele.label, isActive: i <= index
  }));
};
