import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectIsPharmacy } from "../../../../../redux/customer/customerSlice";
import { OrderDetailComponent } from "./OrderDetailComponent";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function SummaryOrder({
                               orderDetail,
                               order,
                               resetSummary,
                               createOrder
                             }) {
  const { t } = useLanguageForShared();
  const isPharmacy = useSelector(selectIsPharmacy);
  return (
    <>
      <OrderDetailComponent order={order} orderDetail={orderDetail} noActions={true}      />
      <Row className="justify-content-end mt-3">
        <Col xs="auto">
          <BtnSecondary className="mr-3" onClick={resetSummary}>
            {t("action.back")}
          </BtnSecondary>
        </Col>
        <Col xs="auto">
          <BtnPrimary data-test="order-send" onClick={createOrder}>{t("action.send")}</BtnPrimary>
        </Col>
      </Row>
    </>
  );
}
