import {
  StructureDetailPulsedFlow, structurePulsedFlow
} from "./paramsData";


export const useTemplateFormConcentratorPortable = (t: any, dataForm: any) => {

  const createElementForm = (ele: any) => {
    return {
      type: "input",
      label: t(`prescription.template.${ele.name}.label`),
      dataElement: {
        placeholder: t(`prescription.template.${ele.name}.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" },
      ...ele
    };
  };
  const formStructureDetailPulsedFlow = StructureDetailPulsedFlow.map(ele => createElementForm(ele));
  const formStructurePulsedFlow = [
    ...structurePulsedFlow.map(ele => createElementForm(ele)),
    ...(dataForm?.pulsedFlow ? formStructureDetailPulsedFlow : [])
  ];


  return {
    formStructurePulsedFlow, formStructureDetailPulsedFlow
  };
};
