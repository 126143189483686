import React from "react";
import { templateNameId, templateNameType } from "./templateData";
import { TemplateNoteRead } from "./templateRead/TemplateNoteRead";
import { TemplateOxigenRead } from "./templateRead/TemplateOxigenRead";
import { TemplateVentilotherapyRead } from "./templateRead/TemplateVentilotherapyRead";
import { TemplateSleepRead } from "./templateRead/TemplateSleepRead";
import { TemplateConcentratorRead } from "./templateRead/TemplateConcentratorRead";

export function TemplateSelectProductRead({ template, data }) {
  const templateList = {
    [templateNameId[templateNameType.note]]: <TemplateNoteRead data={data} />,
    [templateNameId[templateNameType.ossigeno]]: <TemplateOxigenRead data={data} />,
    [templateNameId[templateNameType.ventiloterapia]]: <TemplateVentilotherapyRead data={data} />,
    [templateNameId[templateNameType.sleep]]: <TemplateSleepRead data={data} />,
    [templateNameId[templateNameType.concentrator]]: <TemplateConcentratorRead data={data} />
  };
  return (
    <>
      {templateList[template]}
    </>
  );
}
