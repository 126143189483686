import React, { useEffect } from "react";
import moment from "moment/moment";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import LabelValueCopy from "../../../../shared/custom/labelValue/LabelValueCopy";

const DetailPatientRegistry = ({ patient }: { patient: any }) => {
  const { t } = useLanguageForShared();

  const residence = `${patient?.residence?.street ? patient?.residence?.street : "-"}${patient?.residence?.number ? (", " + patient?.residence?.number) : ""} ${patient?.residence?.city || ""} ${patient?.residence?.province || ""} ${patient?.residence?.cap || ""}`;
  const domicile = `${patient?.domicile?.street ? patient?.domicile.street : "-"}${patient?.domicile?.number ? (", " + patient?.domicile?.number) : ""} ${patient?.domicile?.city || ""} ${patient?.domicile?.province || ""} ${patient?.domicile?.cap || ""}`;
  const birthdate = patient?.birthDate ? moment(patient?.birthDate).format("DD MMM YY") : patient?.DateOfBirth ? moment(patient?.DateOfBirth).format("DD MMM YY") : "-";

  return (
    <div className={"detail-patient"}>
      <div className={"d-flex flex-wrap gap-5 info-col"}>
        <LabelValueCopy label={t("registry.name")} value={patient?.name || "-"} />
        <LabelValueCopy label={t("registry.surname")} value={patient?.surname || "-"} />
        <LabelValueCopy label={t("registry.birthdate")} value={birthdate || "-"} />
        <LabelValueCopy label={t("registry.fiscalCode")} value={patient?.cf || "-"} />
        {patient?.gender && <LabelValueCopy label={t("registry.gender")} value={patient?.gender || "-"} />}
        <LabelValueCopy label={t("registry.telephone")} value={patient?.phone || "-"} />
        <LabelValueCopy label={t("registry.mobile")} value={patient?.mobile || "-"} />
        <LabelValueCopy label={t("registry.email")} value={patient?.email || "-"} />
      </div>
      <div className={"d-flex flex-wrap gap-5"}>
      </div>
      <div className={"d-flex flex-wrap gap-5 address-col"}>
        <LabelValueCopy label={t("registry.resident")} value={residence} />
        <LabelValueCopy label={t("registry.domicileIn")} value={domicile} />
      </div>
    </div>
  );
};

export default DetailPatientRegistry;
