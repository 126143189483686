import React from "react";
import ItemHistory from "../../../shared/design/history/itemHistory/ItemHistory";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

const HistoryList = ({ listOrders = [], clickElement, selectedId }) => {
	const { t } = useLanguageForShared();

	return (
		<div>
			{listOrders && listOrders.length > 0 ? (
				listOrders.map((order, key) => (
					<ItemHistory
						order={order}
						key={key}
						active={selectedId === order.codiceRDO}
						clickElement={() => clickElement(order)}
					/>
				))
			) : (
				<div className="mt-5 pt-5 p-3 text-center">{t("sg.noData")}</div>
			)}
		</div>
	);
};

export default HistoryList;
