export const checkboxData = {
  dataElement: {
    type: "checkbox"
  },
  display: {
    form: "inlineFormReverse mt-5 pt-5",
    label: "",
    child: ""
  }
};

export const structureCannulaNasal = [{
  ...checkboxData,
  name: "cannulaNasal"
}];

export const structureCannulaTracheostomy = [{
  ...checkboxData,
  name: "cannulaTracheostomy"
}];

export const structureMask = [{
  ...checkboxData,
  name: "mask"
}];

export const structureHumidifier = [
  {
    name: "humidifier",
    options: [],
    ...checkboxData
  }
];
export const StructureDetailCannula = [
  {
    name: "cannulaType"
  },
  {
    name: "cannulaModel"
  }
];
export const StructureDetailMask = [
  {
    name: "maskType"
  },
  {
    name: "maskModel"
  }
];
export const StructureDetailRampa = [
  {
    name: "initialPress"
  },
  {
    name: "duration"
  }
];

export const StructureDetailInterface = [
  {
    name: "interfaceType"
  },
  {
    name: "interfaceModel"
  },
  {
    name: "interfaceSize"
  }
];

export const structureRampa = [
  {
    name: "rampa",
    ...checkboxData
  }
];
export const structureInterface = [
  {
    name: "interface",
    ...checkboxData
  }
];


export const StructureDetailHumidifier = [
  {
    name: "notesHumidifier"
  }
];


export const structureNote = [
  {
    name: "note",
    type: "textarea",
    display: {
      form: "mt-4",
      label: "",
      child: ""
    },
    col: { xs: "12" }
  }
];


export const structurePulsedFlow = [{
  ...checkboxData,
  name: "pulsedFlow"
}];
export const StructureDetailPulsedFlow = [
  {
    name: "pulsedFlowSettings",
    col: { xs: "12", lg: '6' }

  }
];


// export const structureNote = {
//   name: "note",
//   type: "textarea",
//   label: t(`prescription.template.note.label`),
//   display: {
//     form: "",
//     label: "",
//     child: ""
//   },
//   dataElement: {
//     type: "text"
//   },
//   col: { xs: "12" }
// };


