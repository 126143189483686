import React, {ReactChild, useEffect, useState} from "react";
import {NavItemsMenu} from "./NavItemsMenu";
import {SidebarRight} from "./SidebarRight";
import {NavbarCollapse} from "./components/NavbarCollapse";
import {NavRight} from "./components/NavRight";
import {HeaderMenuItemsProps, ListAppsProps, templatesTypes} from "./models/Nabar.model";
import SubPatientNav from "./SubPatientNav";
import {customButtonHeaderModel, OrganizationModel} from "../../models/Organization.model";
import {TemplateModel} from "../../models/TemplateModel";

interface NavbarProps extends HeaderMenuItemsProps {
    user?: any;
    organizations?: OrganizationModel[];
    organization_id?: string;
    changeOrganization?: (id: any) => void
    changeLanguageHandler?: (id: any) => void
    currentOrganization?: OrganizationModel
    languages: string[]
    editProfile?: boolean;
    template?: TemplateModel;
    patient?: any
    logout?: () => void
    goProfile?: () => void
    goToAdmin?: (path: string) => void
    listApps?: ListAppsProps[]
    goBack?: () => void
    logo?: any
    subHeaderHtml?: React.ReactNode
    innerTemplateSubNav?: ReactChild
    adminSectionEnabled?: boolean
    showExpand?: boolean
    hideSubPatientNavElements?: string[]
    customButtons?: customButtonHeaderModel[]
}

export const Navbar: React.FC<NavbarProps> = ({
                                                  goBack,
                                                  editProfile,
                                                  logout,
                                                  user,
                                                  organizations,
                                                  organization_id,
                                                  goProfile,
                                                  goToAdmin,
                                                  currentOrganization,
                                                  languages,
                                                  changeLanguageHandler,
                                                  template,
                                                  patient,
                                                  changeOrganization,
                                                  listApps,
                                                  logo,
                                                  subHeaderHtml,
                                                  innerTemplateSubNav,
                                                  adminSectionEnabled,
                                                  showExpand,
                                                  hideSubPatientNavElements,
                                                  customButtons,
                                                  ...rest
                                              }) => {
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [isExpand, setIsExpand] = useState(false);


    const templates: any = {
        patient: (data: any) => {
            return <SubPatientNav data={data} hideSubPatientNavElements={hideSubPatientNavElements}/>
        }
    }

    const [isScrollY, setIsScrollY] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
                window.scrollY > 0 ? setIsScrollY(true) : setIsScrollY(false)
            }
        )
        return () => {
            setIsScrollY(false)
        };
    }, []);

    const goToHome = () => {
        window.location.href = '/'
    }

    return (
        <>
            <NavbarCollapse
                logo={<img onClick={goToHome}
                           className={"pointer"} src={logo} alt="" width={'152px'}/>}
                setToggleSidebar={setToggleSidebar}
                toggleSidebar={toggleSidebar}
                navLeft={<NavItemsMenu {...rest}/>}
                navCenter={undefined}
                navRight={
                    <NavRight
                        user={user}
                        currentOrganization={currentOrganization}
                        setToggleSidebarHandler={() => setToggleSidebar(!toggleSidebar)}
                        listApps={listApps}
                    />
                }
                subNav={(subHeaderHtml || (template && templates[template.name](template.data)))}
                sidebarRight={<SidebarRight goProfile={goProfile} goToAdmin={goToAdmin} editProfile={editProfile}
                                            logout={logout}
                                            customButtons={customButtons}
                                            changeLanguageHandler={changeLanguageHandler}
                                            changeOrganization={changeOrganization} user={user} languages={languages}
                                            organizations={organizations} organization_id={organization_id}
                                            adminSectionEnabled={adminSectionEnabled}/>}
                isExpand={isExpand}
                setIsExpand={setIsExpand}
                bodyScroll={!!(subHeaderHtml || (template && templates[template.name])) && isScrollY}
                innerTemplateSubNav={innerTemplateSubNav}
                goBack={goBack}
                showExpand={showExpand}
            />
        </>
    );
};
