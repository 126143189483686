import React, { useState } from "react";
import InfoMsg from "../../../../../shared/custom/badge/info/InfoMsg";
import configuration from "../../../../../shared/configuration";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

const AlertRegistry = () => {
  const { t } = useLanguageForShared();

  return (
    <InfoMsg clickAction={() => null}
             label={<span>{t("sg.patientRegistryDifference")}</span>}
             variant={"info inline"}
             iconColor={configuration.theme.info_color} />
  );
};

export default AlertRegistry;
