import {
    StructureDetailHumidifier,
    StructureDetailInterface,
    StructureDetailRampa,
    structureHumidifier,
    structureInterface, structureMask, structureNote,
    structureRampa
} from "./paramsData";

export const useParamsData = (t: any, dataForm: any) => {
    const createElementForm = (ele: any) => {
        return {
            type: 'input',
            label: t(`prescription.template.${ele.name}.label`),
            dataElement: {
                placeholder: t(`prescription.template.${ele.name}.placeholder`),
                type: 'text',
            },
            col: {xs: "6", sm: "4", lg: "3", xl: "3"},
            ...ele
        }
    }

    const formStructureDetailRampa = StructureDetailRampa.map(ele => createElementForm(ele));
    const formStructureDetailInterface = StructureDetailInterface.map(ele => createElementForm(ele));
    const formStructureDetailHumidifier = StructureDetailHumidifier.map(ele => createElementForm(ele));


    const formStructureRampa = [
        ...structureRampa.map(ele => createElementForm(ele)),
        ...(dataForm?.rampa ? formStructureDetailRampa : [])
    ];


    const formStructureInterface = [
        ...structureInterface.map(ele => createElementForm(ele)),
        ...(dataForm?.interface ? formStructureDetailInterface : [])
    ];
    const formStructureHumidifier = [
        ...structureHumidifier.map(ele => createElementForm(ele)),
        ...(dataForm?.humidifier ? formStructureDetailHumidifier : [])
    ]
    const formStructureMask = [
        ...structureMask.map(ele => createElementForm(ele)),
        /* ...(dataForm?.humidifier ? formStructureDetailHumidifier : [])*/
    ]
    const formStructureNote = [
        ...structureNote.map(ele => createElementForm(ele))
    ]

    return {
        formStructureRampa,
        formStructureInterface,
        formStructureHumidifier,
        formStructureNote,
        formStructureDetailRampa,
        formStructureDetailInterface,
        formStructureDetailHumidifier
    };
};
