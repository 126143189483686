import React, { Fragment, useEffect, useState } from "react";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import ActonToogleBtn from "../../../../shared/custom/actionBar/ActonToogleBtn";
import ValidateForm from "./ValidateForm";
import DeleteForm from "./DeleteForm";
import { useForm } from "../../../../shared/utils/forms/useForm";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import { actionsNewObj } from "../../../utils/actionData";
import { required } from "../../../../shared/utils/forms/utils/validationType";
import { updateOrders } from "../../../../redux/order/orderCrud";
import { setErrorTypeAction, setSuccessErrorAction } from "../../../../redux/errors/errorsActions";
import { useDispatch } from "react-redux";

const PanelActions = ({ closePanel, actionPanel, order, haveActions }: {
  closePanel: () => void,
  actionPanel: (data: any) => void,
  order: any,
  haveActions: boolean
}) => {
  const { t } = useLanguageForShared();
  const dispatch = useDispatch();

  const [actions, setActions] = useState<any[]>(undefined);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setActions([
      ...(order?.actions?.includes(actionsNewObj["AZ-Chiudilav"]) ? [{
        label: t("sg.msg.validate"),
        name: "validate-action",
        icon: "validate"
      }] : []),
      ...((order?.actions?.includes(actionsNewObj["AZ-Cancella.cliente"]) ||
        order?.actions?.includes(actionsNewObj["AZ-Cancella.BO"])) ? [{
        label: t("sg.msg.delete"),
        name: "delete-action",
        icon: "delete-action"
      }] : [])
    ]);
  }, [order]);


  const submitHandler = () => {
    setChangeHandler();
  };


  useEffect(() => {
    setValidations(
      [
        ...(selected === "delete-action" ? [{
          name: "type",
          validations: [
            {
              name: required,
              params: {
                name: t("sg.msg.deleteMotivation")
              }
            }
          ]
        }] : [])
      ]
    );
  }, [selected]);
  const {
    dataForm, dataFormatted,
    changeValue,
    errors,
    isSubmit,
    setValidations,
    isValid,
    submitAction
  } = useForm(submitHandler, {});

  const [newListTags, setNewListTags] = useState([]);
  const [deleteListTags, setdeleteListTags] = useState([]);
  const [deliveryDateNotes, setDeliveryDateNotes] = useState(order?.dettaglioOrdine?.deliveryNotes || "");
  const updateCall = (data) => {
    updateOrders(order?.codiceRDO, data).then(
      (res) => {
        dispatch(setSuccessErrorAction(t("sg.msg.orderCodeSuccess")));
        actionPanel({ action: { Name: "AZ-Chiudilav" } }); //da vedere codice numeri ordine

        // setTag("");
        //updateOrderHandler && updateOrderHandler(res);
      },
      (err) => {
        if (
          err?.response?.data?.Message ===
          "OrderCodeAlreadyAssociatedWithDifferentRdo"
        ) {
          dispatch(
            setErrorTypeAction(
              t("sg.msg.orderCodeAlreadyExist")
            )
          );
        } else {
          dispatch(
            setErrorTypeAction(t("sg.msg.errorOrderCode"))
          );
        }
      }
    );
  };

  const validateAction = () => {
    updateCall(
      {
        ordersCodes: [...order?.ordersCodes, ...newListTags].filter((ele) => !deleteListTags.map(el => el.number).includes(ele.number)),
        dettaglioOrdine: {
          ...order.dettaglioOrdine,
          deliveryNotes: deliveryDateNotes
        }
      }
    );
  };

  const setChangeHandler = () => {
    if (selected === "validate-action") {
      validateAction();
    }

    if (selected === "delete-action") {
      actionPanel({ action: { Name: dataForm?.type }, actionNotes: dataForm?.actionNotes });
    }
  };
  return (
    <div>
      {haveActions ? <p>{t("sg.msg.selectAction")}</p> : <p>{t("sg.msg.noActionAvailable")}</p>}
      <div className={"d-flex flex-column"}>
        <ActonToogleBtn actions={actions} selected={selected} setSelected={setSelected} />
        <div className={"py-5"}>
          {selected === "validate-action" &&
            <ValidateForm order={order} newListTags={newListTags} setNewListTags={setNewListTags}
                          deliveryDateNotes={deliveryDateNotes} setDeliveryDateNotes={setDeliveryDateNotes}
                          updateCallHandler={updateCall}
                          setdeleteListTags={setdeleteListTags}
                          deleteListTags={deleteListTags}
            />}
          {selected === "delete-action" &&
            <DeleteForm order={order} errors={errors} changeValue={changeValue} isSubmit={isSubmit}
                        dataForm={dataForm} />}
        </div>

        <div className={"d-flex gap-2 justify-content-end"}>
          <BtnSecondary data-dismiss="modal" onClick={closePanel}>
            {haveActions ? t("action.cancel") : t("action.close")}
          </BtnSecondary>
          {haveActions && <BtnPrimary onClick={submitAction}>{t("action.save")}</BtnPrimary>}
        </div>
      </div>
    </div>
  );
};

export default PanelActions;
