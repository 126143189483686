import {
	setErrorTypeAction,
} from "../errors/errorsActions";
import {getPatient} from "./patientCrud";
import {setPatient} from "./patientSlice";



export const GetPatientAction = (t, id, callback) => (dispatch) => {
	return getPatient(id)
		.then((response) => {
			dispatch(setPatient(response));
			callback && callback()
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t("sg.msg.errorLoadData")));
		});
};

