import React from "react";
import UserDetail from "../../../../shared/ui/userDetail/UserDetail";
import TitleCard from "../../../../shared/app/titleCard/TitleCard";
import {UseTitleApp} from "../../../../core/useTitleApp";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function SelectProductHeader({patient, isPharmacy}) {
	const { t } = useLanguageForShared();

	const {label} = UseTitleApp(t,'selectedProduct', isPharmacy);
	return (
		<>
{/*
			{patient && <UserDetail customer={patient} />}
*/}
			<TitleCard
				title={label.title}
				subtitle={label.subtitle}
			/>
		</>
	);
}
