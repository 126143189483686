import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { selectCustomerRole, selectUser } from "../../../../modules/Auth/_redux/authRedux";
import { dateformat_ggMMMyyyy } from "../../../../utils/dateUtils";
import Caption from "../../../ui/headings/caption/Caption";
import Tag from "../../../../../shared/custom/tag/Tag";
import useStatesAction from "../../../../utils/useStatesAction";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../shared/configuration";
import { typeData } from "../FilterSidebar/typeData";

const ItemHistory = ({ order, active, clickElement }) => {
  const { t } = useLanguageForShared();
  const customer_role = useSelector(selectCustomerRole);

  const { findStates, findStatesLabel } = useStatesAction(t);

  const state = findStates(order?.currentStateCode);

  return (
    <div
      className={`shared-itemHistory ${active ? "active" : ""}`}
      onClick={clickElement}>
      <div className="time-row">
        <div className={"d-flex gap-2 align-items-center"}>
				 <span className={"box-wrap"}>
					<small>{t("sg.order.numberRequestOrder")}</small> <span className={"text-ellipsis"}
                                                                  style={{ maxWidth: "100px" }}
                                                                  title={`SG${order.rdOLabel}`}>SG{order.rdOLabel}</span>
				</span>
          <span className={"box-wrap"}>
					<small>{t("sg.order.numberOrder")}</small>
            <span className={"d-flex gap-1"}>
            {order?.orderNumber ? order.orderNumber : "-"} <small
              className={"border-end border-gray mx-1 my-1"}></small>
              <span style={{ marginTop: "-2px" }}>
              {order?.channel === "sg" ? (
                <Tag
                  bgColor={configuration.theme.tertiary}
                  color={configuration.theme.tertiary_color}
                  label={t("sg.order.portal")?.toUpperCase()}
                />
              ) : (
                <Tag
                  bgColor={configuration.theme.gray100}
                  color={configuration.theme.primaryDark}
                  label={t("sg.order.other")?.toUpperCase()}
                />
              )}
              </span>
            </span>
				</span>
        </div>
        <span className={"box-wrap"}>
					<small>{t("sg.order.dataOrder")}</small> <span
          className={"text-primary-dark text-sm"}>{dateformat_ggMMMyyyy(order.createdDate || order.dataCreazioneOrdine)}</span>
				</span>
      </div>
      <div className="user-row">
				 <span className={"box-wrap"}>
          <small>{t("sg.order.detailOrderApplicant")}</small> {order?.name} - {order?.user_create}
				</span>
      </div>


      <Caption
        className="d-flex justify-content-between w-100 ellipsis bold"
        size="16px"
        title={!!order?.products?.length && order?.products[0]?.title}>
        <div className="ellipsis bold">
          {!!order?.products?.length && order?.products[0]?.title}
        </div>

        <div className={''}>
          {typeData()[order?.type] && <Tag
            bgColor={configuration.theme.gray100}
            color={configuration.theme.primaryDark}
            label={typeData()[order?.type]?.label}/*Nuovo / Rinnovo / Sospensione / Variazione    o Consegna*/
          />}
        </div>
      </Caption>

      <div className="practice-row">
				<span className="d-flex align-items-baseline">
					<small className={"text-nowrap"}>{t("sg.order.deliveryTo")}</small>
					<Caption className="ms-1 text-ellipsis" style={{ "maxWidth": "185px" }}>
						{order.patient || "Farmacia"}
					</Caption>
				</span>
        <div className={'d-flex flex-column gap-2'}>
          {order?.currentStateCode &&
            <Tag label={`${findStatesLabel(order?.currentStateCode, customer_role)}`} color={state?.tagColor}
                 bgColor={state?.tagBgColor} />}
        </div>
      </div>
    </div>
  );
};

export default ItemHistory;
