import React from "react";
const HeadingH4 = ({ children, className, ...rest }) => {
	return (
		<h4 className={`heading-4 ${className}`} {...rest}>
			{children}
		</h4>
	);
};

export default HeadingH4;
