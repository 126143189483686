import React from "react";
import { templateFormNote } from "../../../../../../shared_lsi_sg/templateForm";
import { FormElements } from "../../../../../../shared/bootstrap/form/FormElements";
import { useLanguageForShared } from "../../../../../../shared/utils/languages/useLanguageForShared";

export function TemplateNote({ dataForm, changeValue, errors, setData, isSubmit }) {
  const { t } = useLanguageForShared();

  return (
    <>
      <FormElements
        structure={templateFormNote(t)}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
    </>
  );
}
