import React from "react";
import { FormElements } from "../../../../../../../shared/bootstrap/form/FormElements";
import { useTemplateFormConcentrator } from "../data/useTemplateFormConcentrator";
import { useLanguageForShared } from "../../../../../../../shared/utils/languages/useLanguageForShared";

const ConcentratorChoose = ({ dataForm, changeValue, errors, isSubmit }) => {
  const { t } = useLanguageForShared();

  const { formStructureConcentratorChoose } = useTemplateFormConcentrator(t, dataForm);

  return (
    <div>
      <FormElements
        structure={formStructureConcentratorChoose}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
    </div>
  );
};

export default ConcentratorChoose;
