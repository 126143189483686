import React from "react";
import { concentratorType } from "../concentratorData";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { useDateCustom } from "../../shared/custom_use/useDateCustom";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";

const TemplateConcentratorPrescriptionRead = ({ data }: any) => {
  const { t } = useLanguageForShared();
  const { data: dataFormatter } = useDateCustom();

  return (
    <>
      {(data?.concentratorType === concentratorType.fixed || data?.concentratorType === concentratorType.fixed_portable || data?.concentratorType === concentratorType.transportable) && <>
        <div className="col-sm-12 col-md-12">
          <p className={"title-paragraph"}>{t('sg.prescription')}</p>

          {data?.flowRest && <LabelValue label={t("prescription.template.flowRest")}
                                     value={data?.flowRest || "-"} />}
          {data?.flowContinue && <LabelValue label={t("prescription.template.flowContinue")}
                                             value={data?.flowContinue || "-"} />}
          {data?.flowRestHourDie && <LabelValue
            label={t("prescription.template.hourDie")}
            value={data?.flowRestHourDie || "-"} className={"me-5"} />}

          {data?.flowContinueHourDie && <LabelValue
            label={t("prescription.template.hourDie")}
            value={data?.flowContinueHourDie || "-"} className={"me-5"} />}

          {data?.underStressFlow && <><LabelValue
            label={t("prescription.template.underStressFlow")}
            value={data?.underStressFlow || "-"} />
            <LabelValue
              label={t("prescription.template.hourDie")}
              value={data?.underStressFlowHourDie || "-"} />
          </>}
          {data?.nightFlow && <><LabelValue
            label={t("prescription.template.nightFlow")}
            value={data?.nightFlow || "-"} />
            <LabelValue
              label={t("prescription.template.hourDie")}
              value={data?.nightFlowHourDie || "-"} />
          </>}
        </div>
        <div className="col-sm-12 col-md-12">
          <LabelValue label={t("prescription.template.validUntil")}
                      value={data?.validUntil ? dataFormatter.dateFormatGGmmmYY(data?.validUntil) : "-"} />
        </div>
      </>}
    </>
  );
};

export default TemplateConcentratorPrescriptionRead;
