import React from "react";
import ToastSuccess from "../toastSuccess/ToastSuccess";
import ToastError from "../toastError/ToastError";
import ToastPermission from "../toastPermission/ToastPermission";

const ToastListError = ({ list = [], closeAction }) => {
	return (
		<>
			<div className="shared-toastListError">
				{list.map((toast) => {
					switch (toast.type) {
						case "success":
							return (
								<ToastSuccess
									key={toast.id}
									{...toast}
									closeAction={() => closeAction && closeAction(toast)}
								/>
							);
						case "error":
							return (
								<ToastError
									key={toast.id}
									{...toast}
									closeAction={() => closeAction && closeAction(toast)}
								/>
							);
						case "unauthorized":
							return (
								<ToastPermission
									key={toast.id}
									{...toast}
									closeAction={() => closeAction && closeAction(toast)}
								/>
							);
						default:
							return;
					}
				})}
			</div>
		</>
	);
};

export default ToastListError;
