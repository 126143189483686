import React, { ForwardedRef, useRef } from "react";
import DetailPatientRegistry from "../../../../../_metronic/layout/components/subHeaderTemplate/DetailPatientRegistry";
import { ProductListOrder } from "./ProductListOrder";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

const PrintComponentPrescription = React.forwardRef(({
                                                       patient,
                                                       order,
                                                       suspensionCallback
                                                     }: any, ref: ForwardedRef<any>) => {
    const { t } = useLanguageForShared();
    const logo = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/logo/logo${"_sgfe"}.png`;

    return (

      <div className={"position-fixed"} style={{ left: "-500%" }}>
        <div id={"patientPrescriptionPdf"} ref={ref}>
          <img src={logo} alt="" width={"72px"} />
          <div className={"title-page"}>
            {t("sg.form.orderDetail")}
          </div>


          <h3 className={"title-page patient"}>
            {t("sg.patient.registryPatient")}
          </h3>
          <DetailPatientRegistry patient={patient} />

          <ProductListOrder order={order} showBtnAction={true} suspensionCallback={suspensionCallback} />

        </div>
      </div>

    );
  }
);

export default PrintComponentPrescription;
