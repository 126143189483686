import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialPatientState = {
  patient: undefined,
  homeDelivery: undefined,
  emptySubNav: undefined
};


export const patientSlice = createSlice({
  name: "patient",
  initialState: initialPatientState,
  reducers: {
    setPatient: (state, action) => {
      state.patient = action.payload;
    },
    resetPatient: (state) => {
      state.patient = undefined;
    },
    setHomeDelivery: (state, action) => {
      state.homeDelivery = action.payload;
    },
    resetHomeDelivery: (state, action) => {
      state.homeDelivery = undefined;
    },
    setEmptySubNav: (state, action) => {
      state.emptySubNav = action.payload;
    },
    resetEmptySubNav: (state, action) => {
      state.emptySubNav = undefined;
    }
  }
});

export const {
  setPatient,
  setHomeDelivery,
  resetHomeDelivery,
  resetPatient,
  setEmptySubNav,
  resetEmptySubNav
} = patientSlice.actions;

export const selectPatient = (state) => state.patient.patient;
export const selectHomeDelivery = (state) => state.patient.homeDelivery;
export const selectEmptySubNav = (state) => state.patient.emptySubNav;


