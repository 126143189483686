import {ReactChild, useState} from "react";
import IconLabel from "./IconLabel";
import PopoverCustomOver from "../../external_lib/popover/PopoverCustomOver";
import PopoverCustomClick from "../../external_lib/popover/PopoverCustomClick";


interface IconProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
    icon: string | boolean
    size?: string
    label?: string | ReactChild
    titlePop?: string | ReactChild
    contentPop?: string | ReactChild
    placement?: any[]
    trigger?: "click" | "mouseOver"
    tintColor?: string
}

export const IconLabelTooltip = ({
                                     icon,
                                     label,
                                     titlePop,
                                     contentPop,
                                     placement = ['top', 'bottom', 'left', 'right'],
                                     trigger = 'mouseOver',
                                     tintColor,
                                     ...rest
                                 }: IconProps) => {
    return (
        <div className={'d-flex'}>

            {trigger === 'mouseOver' && <PopoverCustomOver
                positions={placement}
                content={<div>
                    <div>{titlePop}</div>
                    <div>{contentPop}</div>
                </div>}
            >
                   <span className="d-flex icon-label tooltip-content">
                      <IconLabel
                          icon={icon}
                          label={label}
                          labelClass="link pointer"
                          className="me-1"
                          tintColor={tintColor}
                          {...rest}
                      />
                    </span>
            </PopoverCustomOver>}

            {trigger === 'click' && <PopoverCustomClick
                positions={placement}
                content={<div>
                    <div>{titlePop}</div>
                    <div>{contentPop}</div>
                </div>}
            >
                <span className="d-flex icon-label tooltip-content">
                      <IconLabel
                          icon={icon}
                          label={label}
                          labelClass="link pointer"
                          className="me-1"
                          tintColor={tintColor}
                          {...rest}
                      />
                    </span>
            </PopoverCustomClick>
            }
        </div>
    )
}
