import {
  checkboxData,
  structureCannulaNasal, structureCannulaTracheostomy,
  StructureDetailCannula, StructureDetailMask,
  StructureDetailRampa,
  structureMask,
  structureRampa
} from "./paramsData";

export const useTemplateFormOtherInfo = (t: any, dataForm: any) => {
  const createElementForm = (ele: any) => {
    return {
      type: "input",
      label: t(`prescription.template.${ele.name}.label`),
      dataElement: {
        placeholder: t(`prescription.template.${ele.name}.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" },
      ...ele
    };
  };
  const formStructureDetailCannulaNasal = StructureDetailCannula.map(ele => createElementForm(ele));
  const formStructureDetailCannulaTracheostomy = StructureDetailCannula.map(ele => createElementForm(ele));
  const formStructureDetailMask = StructureDetailMask.map(ele => createElementForm(ele));
  const formStructureCannulaNasal = [
    ...structureCannulaNasal.map(ele => createElementForm(ele)),
    ...(dataForm?.cannulaNasal ? formStructureDetailCannulaNasal : [])
  ];
  const formStructureCannulaTracheostomy = [
    ...structureCannulaTracheostomy.map(ele => createElementForm(ele)),
    ...(dataForm?.cannulaTracheostomy ? formStructureDetailCannulaTracheostomy : [])
  ];
  const formStructureMask = [
    ...structureMask.map(ele => createElementForm(ele)),
    ...(dataForm?.mask ? formStructureDetailMask : [])
  ];

  return {
    formStructureCannulaNasal,
    formStructureCannulaTracheostomy,
    formStructureMask,
    formStructureDetailMask,
    formStructureDetailCannulaTracheostomy,
    formStructureDetailCannulaNasal

  };
};
