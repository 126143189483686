import React from "react";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";


export function DetailOrderRequest({ order, orderDetail }) {

  const { t } = useLanguageForShared();

  return (<>
      <section>
        <h3 className={"title-section"}>{t("sg.section.request")}</h3>
        <div className="list-key-value">
          {/* {order?.orderProviderName && (
          <div className="time-row">
            <span>
                        <span className={"label-item"}>{t("sg.provider")}</span>
                          <span className={"text-primary-dark"}>
                            {order?.orderProviderName}
                          </span>
                      </span>
                      </div>
                      )}*/}

          {order.clientCodeERP && (
            <span>
                  <span className={"label-item"}>{t("sg.order.client")}</span>
                        <span className={"text-primary-dark"}>
                          {order.clientCodeERP} {order?.requester_customer ? " - " + order?.requester_customer : ""}
                        </span>
                </span>
          )}
          <span>
              <span className={"label-item"}>{t("sg.order.detailOrderApplicant")}</span>
            {order?.orderDetail?.applicant?.surname.toUpperCase()}{" "} {order?.orderDetail?.applicant?.name || "-"}
          </span>
          {/*<span>
            <span className={"label-item"}>{t("sg.order.detailOrderRequestOf")}</span>
            {order?.orderDetail?.applicant?.data ? dateformat_ggMMMyyyy(order?.orderDetail?.applicant?.data) : "-"}
        </span>
*/}
          <span>
            <span className={"label-item"}>{t("sg.order.detailOrderPrescriber")}</span>
            {orderDetail?.prescriber?.surname?.toUpperCase()}{" "}
            {orderDetail?.prescriber?.name || "-"}{" "}|{" "}{orderDetail?.prescriber?.code || "-"}
        </span>

          <span>
            <span className={"label-item"}>{t("sg.order.detailOrderPrescription")}</span>
            <span>{orderDetail?.prescription?.code || "-"} <span
              className={"label-item"}>{t("sg.order.from")}{" "}</span>{orderDetail?.prescription?.date ? dateformat_ggMMMyyyy(orderDetail?.prescription?.date) : "-"}</span>
          </span>


        </div>
      </section>
    </>
  );
}
