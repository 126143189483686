import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  init: false,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setInit: (state) => {
      state.init = true
    }
  },
})

export const {
  setInit
} = settingsSlice.actions

export const selectInit = (state) => state.settings.init

export default settingsSlice.reducer
