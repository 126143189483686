export const loadScript = (url: string) => new Promise((resolve, reject) => {
        try{
            const scripts = document.getElementsByTagName('script');
            let isset = false;
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src.split('?')[0] === url) {
                    isset = true; // Lo script è già presente, non fare nulla
                    return;
                }
            }
            if(isset){
                resolve(true)
            } else {
                const script = document.createElement("script");
                script.src = url+'?'+new Date().getTime();
                script.async = true;
                script.onload = () => {
                    resolve(true)
                };
                script.onerror = (err) => {
                    console.error('errore caricamento configurazione, path js non corretto '+ url);
                    reject(true)
                }
                document.body.appendChild(script);
            }

        }catch (err){
            console.log('errore caricamento configurazione, path js non corretto '+ url);
            reject(true)
        }
    })

export const loadScriptOrIgnore = (url: string) => new Promise((resolve, reject) => loadScript(url).then(res => resolve(true), err => resolve(true)))
