const modalEvents: {
  events: { [key: string]: () => void };
  templates: { [key: string]: React.ReactNode };
  data: any
} = {
  events: {},
  templates: {},
  data: {}
};

export default modalEvents;
