import React from "react";
import { useSelector } from "react-redux";
import BoxFullScreen from "../../boxFullScreen/BoxFullScreen";

import Loader from "../loader/Loader";
import {selectActiveLoad, selectActiveManualLoad, selectStopLoad} from "../redux/loadSlice";

const LoaderFullScreen = ({ children, className }) => {
	const show = useSelector(selectActiveLoad);
	const showManual = useSelector(selectActiveManualLoad);
	const stopLoad = useSelector(selectStopLoad);
	return (
		<BoxFullScreen show={!stopLoad && (show || showManual)} loader={true}>
			<div className="loader-sh">
				<Loader></Loader>
			</div>
		</BoxFullScreen>
	);
};
export default LoaderFullScreen;
