import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";

export function CheckboxForm({ ele, formik }) {
  const changeData = (event) => {
    const oldValue = formik.values[ele.name] || [];
    const value = event.target.value;
    formik.setFieldValue(
      ele.name,
      oldValue.includes(value)
        ? oldValue.filter((ele) => ele.toString() !== value)
        : [...oldValue, value]
    );
  };
  return (
    <Row
      className={`gap-2 ${ele.display ? ele.display : ""} ${
        ele.className ? ele.className : ""
      }`}>
      {ele.options.map((opt, i) => (
        <Col xs="auto" {...(opt.col || {})} key={opt.label}>
          <InputGroup className="shared-checkbox sg">
            <InputGroup.Checkbox
              id={ele.name + i}
              checked={formik.values[ele.name]
                ?.toString()
                ?.includes(opt.value.toString())}
              className="checkbox-sh sg"
              value={opt.value}
              name={ele.name}
              onChange={changeData}>
            </InputGroup.Checkbox>
            <label htmlFor={ele.name + i}>{opt.label}</label>
          </InputGroup>
        </Col>
      ))}
    </Row>
  );
}
