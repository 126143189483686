import React from "react";
import SleepTemplate from "./SleepTemplate";
import ParamsTemplate from "./ParamsTemplate";


export function TemplateSleep({ dataForm, changeValue, errors, setData, isSubmit }) {

  return (
    <>
     <SleepTemplate errors={errors} changeValue={changeValue} setData={setData} dataForm={dataForm} isSubmit={isSubmit}/>
     <ParamsTemplate errors={errors} changeValue={changeValue} dataForm={dataForm} setData={setData} isSubmit={isSubmit}/>
    </>
  );
}
