import moment from "moment";
import {itMoment} from "./locale/it"
import {plMoment} from "./locale/pl";
import {esMoment} from "./locale/es";
import {caMoment} from "./locale/ca";

export const settings: any = {
    it: {
        moment: itMoment(moment),
        other: {
            LT: "HH:mm",
            L: "dd/MM/YY",
            LL: "d MMM YY",
            LLL: "d MMM YY HH:mm",
            LLLL: "dddd, D MMMM YY LT",
            fullDateWithMinute: "d MMM YY HH:mm",
            fullDateWithSeconds: "d MMM YY HH:mm:ss",
        }
    },
    pl: {
        moment: plMoment(moment),
        other: {
            LT: "HH:mm",
            L: "dd/MM/YY",
            LL: "d MMM YY",
            LLL: "d MMM YY HH:mm",
            LLLL: "dddd, D MMMM YY LT",
            fullDateWithMinute: "d MMM YY HH:mm",
            fullDateWithSeconds: "d MMM YY HH:mm:ss",
        }
    },
    es: {
        moment: esMoment(moment),
        other: {
            LT: "HH:mm",
            L: "dd/MM/YY",
            LL: "d MMM YY",
            LLL: "d MMM YY HH:mm",
            LLLL: "dddd, D MMMM YY LT",
            fullDateWithMinute: "d MMM YY HH:mm",
            fullDateWithSeconds: "d MMM YY HH:mm:ss",
        }
    },
    ca: {
        moment: caMoment(moment),
        other: {
            LT: "HH:mm",
            L: "dd/MM/YY",
            LL: "d MMM YY",
            LLL: "d MMM YY HH:mm",
            LLLL: "dddd, D MMMM YY LT",
            fullDateWithMinute: "d MMM YY HH:mm",
            fullDateWithSeconds: "d MMM YY HH:mm:ss",
        }
    }
}

export const setMomentLocale = (lang: string) => {
    moment.locale(lang, settings[lang] ? [settings[lang].moment] : undefined)
}
