import React from "react";
import { FormElements } from "../../../../shared/bootstrap/form/FormElements";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import { actionsNewObj } from "../../../utils/actionData";

const DeleteForm = ({
                      dataForm,
                      errors,
                      changeValue,
                      isSubmit,
                      order
                    }: {
  dataForm?: any,
  errors: any,
  changeValue: (data: any) => void,
  isSubmit: boolean,
  order: any
}) => {
  const { t } = useLanguageForShared();

  const formStructureDelete = [
    {
      name: "type",
      label: "",
      format: "",
      type: "radio",
      options: [
        ...(order?.actions?.includes(actionsNewObj["AZ-Cancella.cliente"]) ? [{
          value: "AZ-Cancella.cliente",
          label: t("sg.msg.delete_customer")
        }] : []),
        ...(order?.actions?.includes(actionsNewObj["AZ-Cancella.BO"]) ? [{
          value: "AZ-Cancella.BO",
          label: t("sg.msg.delete_bo")
        }] : [])
      ],
      dataElement: {
        display: "d-flex gap-5"
      },
      display: {
        form: "d-flex"
      },
      col: { xs: "12", sm: "12", lg: "12" }
    },
    {
      name: "actionNotes",
      label: "Note",
      dataElement: {
        placeholder: t("sg.msg.possibleNote")
      },
      type: "textarea",
      col: { xs: "12" }
    }
  ];


  return (
    <div>
      <p>{t("sg.msg.deleteMotivation")}</p>

      <FormElements
        structure={formStructureDelete}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
    </div>
  );
};

export default DeleteForm;
