import React from "react";

import { processSilentRenew } from "redux-oidc";



export const SilentRenewComponent = () => {

    processSilentRenew();

    return (

        <div>SilentRenewComponent</div>

    );

};
