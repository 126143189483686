import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { toEnDateString } from "../../../../utils/dateUtils";
import { formatModel, format as formatData } from "../format";
import arrow from "./img/arrow-down.png";

export function SelectCustom({
                               ele, changeValue, leftTemplate, rightTemplate, alignRight, values, options, ...rest
                             }) {

  return (<InputGroup className="">
    {leftTemplate && (<InputGroup.Text id="basic-addon-left">{leftTemplate}</InputGroup.Text>)}
    <FormControl
      custom
      as="select"
      style={{ backgroundImage: `url(${arrow})` }}
      value={values[ele.name] !== undefined && values[ele.name] !== "-1" ? values[ele.name] : "-1"}
      onChange={(event) => {
        return changeValue(ele.name, event.target.value !== "-1" ? event.target.value : undefined);
      }}
      {...(ele?.dataElement || {})}>
      {options.map((opt, index) => (<option key={opt.value} value={opt.value}>
        {opt.label}
      </option>))}
    </FormControl>
    {rightTemplate && (<InputGroup.Text id="basic-addon-right">
      {rightTemplate}
    </InputGroup.Text>)}
  </InputGroup>);
}
