import React from "react";
import ModalCustom from "../../../../shared/bootstrap/modal/ModalCustom";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";


const ModalInfoSupplies = ({ data, cancelHandler }: { data?: any, cancelHandler: () => void }) => {
  const { t } = useLanguageForShared();

  const supplies = data?.split("\n");

  return (
    <div>
      <ModalCustom
        className={null}
        title={t("sg.infoForniture")}
        closeModal={cancelHandler}
      >
        <div className={"mt-3"}>
          {supplies && supplies.length > 0 ? supplies?.map((rowText, i) => <p key={i} className={"mb-2"}>{rowText}</p>) :
            <span>{t("sg.noData")}</span>}
        </div>
      </ModalCustom>
    </div>
  );
};

export default ModalInfoSupplies;
