import React from "react";
import { Col } from "react-bootstrap";
import Caption from "../../../shared/ui/headings/caption/Caption";
import HeadingH3 from "../../../shared/ui/headings/headingH3/HeadingH3";
import { ProductCartRead } from "../../cart/ProductCartRead";
import Tag from "../../../../shared/custom/tag/Tag";
import configuration from "../../../../shared/configuration";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import { typeData } from "../../../shared/design/history/FilterSidebar/typeData";
import { IconSVG } from "../../../../shared/custom/icon/IconSVG";
import { IconLabelTooltip } from "../../../../shared/custom/icon/IconLabelTooltip";

export default function ProductSummary({ ord, orderType, orderDetail }) {
  const { t } = useLanguageForShared();

  return (
    <div className="shared-product-card border-0 mb-0">
      <div className="info-product flex-row">
        <div className="head-info-product">
          <div className="title-product">
            <div className={"d-flex gap-2 align-items-center"}>
              {typeData()[orderType] && <Tag
                bgColor={configuration.theme.gray100}
                color={configuration.theme.primaryDark}
                label={typeData()[orderType]?.label}/*Nuovo / Rinnovo / Sospensione / Variazione    o Consegna*/
              />}
              {orderDetail?.suspension && <IconLabelTooltip
                icon="alert"
                onClick={() => null}
                titlePop={orderDetail?.suspension?.suspensionType === "other" ? orderDetail?.suspension?.suspensionNote : t('sg.suspensionDeath')}
              />}
              <HeadingH3 className="">{ord?.title}</HeadingH3>
            </div>
            <Caption className="mt-1" style={{ "lineHeight": "24px" }}
            >{ord?.description}</Caption>
            {/* {ord?.codeERP && <span className="mt-3">Cod.{ord?.codeERP}</span>} */}
          </div>
        </div>
        <div>
          <ProductCartRead product={ord} />
        </div>
      </div>
    </div>
  );
}
