import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setErrorTypeAction } from "../../../redux/errors/errorsActions";
import { GetPatientAction } from "../../../redux/patient/patientActions";
import { getPatients } from "../../../redux/patient/patientCrud";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import imgSearchPatient from "../../assets/img/searchPatient.jpg";
import * as routeName from "../../routes/routeNameAuthType";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import { SearchInput } from "../../shared/ui/forms/searchInput/SearchInput";
import { setStopLoader } from "../../shared/ui/loader/redux/loadSlice";
import { useRoute } from "../../utils/useRoute";
import { selectIsPharmacy, selectSelectedCustomer } from "../../../redux/customer/customerSlice";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";
import { resetIsLsi, setFromCart } from "../../../redux/order/orderSlice";
import { resetEmptySubNav, resetPatient, selectEmptySubNav, setEmptySubNav } from "../../../redux/patient/patientSlice";

export function PatientsPage({ history }) {
  const { t } = useLanguageForShared();

  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const dispatch = useDispatch();
  const searchAction = ({ name: searchAll }) => {
    setLoading(true);
    getPatients({ searchAll, customerCode: selectedCustomer.id })
      .then((res) => {
        setUsers(res);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setErrorTypeAction(t("sg.msg.errorLoadPatients")));
        setLoading(false);
      });
  };
  const { changeRoute } = useRoute();

  const isPharmacy = useSelector(selectIsPharmacy);

  useEffect(() => {
    if (!(isPharmacy)) {
      dispatch(setEmptySubNav(true));
    }
    return () => {
      dispatch(resetEmptySubNav());
    };
  }, [isPharmacy]);

  useEffect(() => {
    dispatch(resetIsLsi());
    dispatch(resetPatient());
  }, []);
  useEffect(() => {
    dispatch(setStopLoader(true));
    return () => {
      dispatch(setStopLoader(false));
    };
  });

  const clickUser = (usr) => {
    const callback = () => changeRoute(routeName.selectedPatientPage);
    dispatch(GetPatientAction(t, usr.id, callback));
  };
  const heightContent = `calc(100vh - 320px)`;

  return (
    <Container>
      <Row>
        <Col xs="12">
          <WizardOrder selectedStep={nameSteps.patient} />
          <Card>
            <CardHeader>
              <TitleCard
                classNames="no-border mb-0 pb-0"
                title={""}
                subtitle={t("sg.patient.subtitle")}
              />
            </CardHeader>
            <CardBody
              style={{
                height: heightContent
                /*backgroundImage: `url(${imgSearchPatient})`,*/
              }}
              className="bg-img-patient-page">
              <Row className="">
                <Col xs={"12"} md={"8"} lg={"6"}>
                  <SearchInput
                    searchAction={searchAction}
                    clickUser={clickUser}
                    listUser={users}
                    resetUsers={() => setUsers([])}
                    loading={loading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
