import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import * as routeName from "../../../../routes/routeNameAuthType";
import { useRoute } from "../../../../utils/useRoute";
import Icon from "../../../ui/icon/Icon";
import IconBtn from "../../buttons/icon-button/IconButton";
import { InputCustom } from "../input/InputCustom";
import ItemDropdown from "./ItemDropdown";
import Loader from "../../loader/loader/Loader";
import EditRegistryPatientModal
  from "../../../../../_metronic/layout/components/subHeaderTemplate/modal/EditRegistryPatientModal";
import { IconSVG } from "../../../../../shared/custom/icon/IconSVG";
import BtnDefault from "../../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../../shared/configuration";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

let searchTimeout;

export function SearchInput({
                              searchAction,
                              clickUser,
                              listUser = [],
                              resetUsers,
                              loading = false
                            }) {
  const { changeRoute } = useRoute();
  const [showModalEdit, setShowModalEdit] = useState(undefined);
  const {t}=useLanguageForShared();

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    onSubmit: (values) => {
      searchAction && searchAction(values);
    }
  });
  const data = {
    name: "name"
  };

  const changeValueHandler = (value) => {
    formik.setFieldValue(data.name, value);
    searchTimeout && clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (value && value.length > 2)
        searchAction && searchAction({ name: value });
      else resetUsers && resetUsers();
    }, 300);
  };
  const goNewPatient = () => {
    setShowModalEdit(true);
    //changeRoute(routeName.patientPage);
  };
  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="form-search">
        <InputCustom
          leftTemplate={<IconSVG icon="search" size={"24px"} />}
          ele={data}
          val={formik.values.name}
          changeValue={changeValueHandler}
        />
        <div className="dropdown-search-input">
          <div className="drop-content">
            {loading && (
              <div className="loader-drop">
                <Loader size="x40" />
              </div>
            )}
            {listUser && listUser.length > 0 ? (
              listUser.map((usr, k) => (
                <ItemDropdown
                  key={k}
                  user={usr}
                  clickHandler={(usr) => clickUser(usr)}
                />
              ))
            ) : (
              <div className="text-center py-3">
                {formik.values.name?.length < 3 ? (
                  <div>
                    {t("sg.form.searchThreeCharacter")}
                  </div>
                ) : (
                  <div>
                    {loading
                      ? t("sg.form.loading")
                      : t("sg.form.noPatientData")
                    }
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="drop-footer">
            <BtnDefault
              className="border-0 p-2 py-0 h-auto text-capitalize"
              onClick={goNewPatient}
              icon="plus"
              tintColor={configuration.theme.primary}
              iconSize={"20px"}>
              {t("sg.form.action.addPatient")}
            </BtnDefault>
          </div>
        </div>
      </Form>
      {showModalEdit && <EditRegistryPatientModal cancelHandler={() => setShowModalEdit(false)} />}

    </>
  );
}
