import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectPatient } from "../../../redux/patient/patientSlice";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import * as routeName from "../../routes/routeNameAuthType";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import { nameSteps } from "../../shared/app/wizard_order/wizardOrderData";
import { useRoute } from "../../utils/useRoute";
import {
  getOrder,
  getOrdersForPatient,
} from "../../../redux/order/orderCrud";
import { OrderDetailComponent } from "../order/component/summary/OrderDetailComponent";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import { pages } from "../../core/titleApp";
import { UseTitleApp } from "../../core/useTitleApp";
import { clearOrder } from "../../utils/ordersUtils";
import { setErrorTypeAction } from "../../../redux/errors/errorsActions";
import IsPermission from "../../shared/utils/IsPermission";
import { permissionsData } from "../../modules/Auth/_redux/permissionsData";
import BtnPrimary from "../../../shared/bootstrap/button/primary/BtnPrimary";
import RowPatientDetailTemplate from "../../../_metronic/layout/components/subHeaderTemplate/RowPatientDetailTemplate";
import BtnSecondary from "../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function SelectPatientPage({ history }) {
  const{t} = useLanguageForShared();

  const dispatch = useDispatch();
  const patient = useSelector(selectPatient);
  const [listOrders, setListOrders] = useState([]);
  const { changeRoute } = useRoute();
  const setOrders = (orders, index = 0, newListOrders = []) => {
    if (index < orders.length) {
      getOrder(orders[index].codiceRDO)
        .then((res) => {
          setOrders(orders, index + 1, [...newListOrders, res]);
        })
        .catch((error) => {
          dispatch(setErrorTypeAction(t('sg.msg.errorSearchOrders')));
        });
    } else {
      setListOrders(newListOrders.map((ele) => clearOrder(ele)));
    }
  };
  useEffect(() => {
    !patient && changeRoute(routeName.patientsPage);
    patient &&
    getOrdersForPatient(patient.id)
      .then((res) => {
        setListOrders(res.map((ele) => clearOrder(ele)));
      })
      .catch((error) => {
        dispatch(setErrorTypeAction(t('sg.msg.errorSearchOrders')));
      });
  }, []);
  const goProducts = () => {
    changeRoute(routeName.products);
  };
  const goBack = () => {
    changeRoute(routeName.patientsPage);
  };

  const goEdit = () => {
    changeRoute(routeName.patientEditPage);
  };
  const { label } = UseTitleApp(t, pages.selectedPatient, false);
  return (
    <Container>
      <Row>
        <Col xs="12">
          <WizardOrder selectedStep={nameSteps.patient} />
          <Card>
            {/*<CardHeader>
              <TitleCard title={label.title} subtitle={label.subtitle} />
            </CardHeader>*/}

            <CardHeader>
              <TitleCard subtitle={`${t('sg.patient.thePatient')} ${patient?.surname} ${patient?.name} ${t('sg.patient.selected')}`} />
            </CardHeader>
              <Row className="justify-content-end">
                <Col xs="auto">
                  <BtnSecondary onClick={goBack} data-test={"back-sel-patient"}>
                    {t("action.back")}
                  </BtnSecondary>
                </Col>
                <Col xs="auto">
                  <IsPermission permissions={[permissionsData.canEdit]}>
                    <BtnPrimary onClick={goProducts} data-test={"activate-new-treatment"}>
                    {t("action.next")}
                    </BtnPrimary>
                  </IsPermission>
                </Col>
              </Row>
            <CardBody>

              {/* <Row className="justify-content-end">
                <Col xs="auto">
                  <IsPermission permissions={[permissionsData.canEdit]}>
                    <IconBtn
                      icon="edit"
                      sizeBtn="16px"
                      className="mb-2"
                      onClick={goEdit}>
                      Modifica anagrafica paziente
                    </IconBtn>
                  </IsPermission>
                </Col>
              </Row>*/}
              {/*<PatientDetail patient={patient} />*/}

              <div>
                <RowPatientDetailTemplate patient={patient} />
              </div>

              {listOrders && listOrders.length > 0 ? listOrders?.map((ele, k) => (
                  <OrderDetailComponent
                    key={k}
                    order={ele}
                    orderDetail={ele.orderDetail}
                  />
                )) :
                <div className={"d-flex gap-3 align-items-center"}>
                  <img src={`${process.env.REACT_APP_LOAD_CONFIG}assets/images/empty_data.svg`} width={"180px"} />
                  {t("sg.stateAction.noService")}
                </div>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
