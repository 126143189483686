import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { spinnerSlice } from "../app/actions/spinner/spinnerSlice";
import { reducer as oidcReducer } from "redux-oidc";
import { productsSlice } from "./products/productsSlice";
import { cartSlice } from "./cart/cartSlice";

import { orderSlice } from "./order/orderSlice";
import { customerSlice } from "./customer/customerSlice";
import { containerSlice } from "./container/containerSlice";
import loadReducer from "../app/shared/ui/loader/redux/loadSlice";
import modalReducer from "../app/shared/redux/modal/modalSlice";
import {patientSlice} from "./patient/patientSlice";
import {settingsSlice} from "./settingsSlice";
import errorsReducer from "../shared/redux/errors/errorSlice";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	spinner: spinnerSlice.reducer,
	settings: settingsSlice.reducer,
	products: productsSlice.reducer,
	cart: cartSlice.reducer,
	customer: customerSlice.reducer,
	container: containerSlice.reducer,
	errors: errorsReducer,
	order: orderSlice.reducer,
	patient: patientSlice.reducer,
	oidc: oidcReducer,
	load: loadReducer,
    modal: modalReducer,
});
