import React from "react";

interface InputProps {
  error?: boolean;
  format?: string;
  name: string;
  value: any;
  options: any[];
  changeValue: (data: any, event: React.ChangeEvent<HTMLInputElement>) => void;
  display: string;
}

const RadioCustom = ({
                       changeValue,
                       name,
                       error,
                       value,
                       options,
                       display,
                       ...rest
                     }: InputProps) => {
  const classArray = ["form-control", ...(error ? ["error"] : [])];

  return (
    <div className={display}>
      {options.map((ele, i) => {
        return (
          <div className="shared-radio input-group" key={i}>
            <span className="input-group-text">
            <input
              className="radio-sh"
              type="radio"
              value={ele.value}
              name={name}
              id={ele.value}
              checked={rest.format === "boolean" ? ele.value.toString() === value?.toString() : ele.value === value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const val = rest.format === "boolean" ? event.target.value === "true" : event.target.value;
                changeValue(val, event);
              }
              }
            />
            <label htmlFor={ele.value} className="">
              {ele.label}
            </label>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RadioCustom;
