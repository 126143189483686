export const plSetting = {
    months: "styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień".split("_"),
    monthsShort: "sty_lut_mar_kwi_maj_cze_lip_sie_wrz_paź_lis_gru".split("_"),
    weekdays: "Niedziela_Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota".split("_"),
    weekdaysShort: "Nd_Pon_Wt_Śr_Czw_Pt_So".split("_"),
    weekdaysMin: "Nd_Pn_Wt_Śr_Cz_Pt_So".split("_"),
    longDateFormat: {
        LT: "HH:mm",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY LT",
        LLLL: "dddd, D MMMM YYYY LT"
    },
    calendar: {
        sameDay: '[Dziś o] LT',
        nextDay: '[Jutro o] LT',
        nextWeek: 'dddd [o] LT',
        lastDay: '[Wczoraj o] LT',
        lastWeek: '[w zeszły] dddd [o] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: function (s: any) {
            return ((/^[0-9].+$/).test(s) ? "za" : "za") + " " + s;
        },
        past: "%s temu",
        s: "kilka sekund",
        m: "minuta",
        mm: "%d minut",
        h: "godzina",
        hh: "%d godzin",
        d: "dzień",
        dd: "%d dni",
        M: "miesiąc",
        MM: "%d miesięcy",
        y: "rok",
        yy: "%d lat"
    },
    ordinal: '%dº',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
}
