import { usePathologiesData } from "./usePathologiesData";
import { useModeVentiloData } from "./useModeVentiloData";

export const useVentiloData = (t: any, dataForm: any, setData: (data: any) => void) => {
  const { formStructurePathologies } = usePathologiesData(t, dataForm);

  const { formStructureMode } = useModeVentiloData(t, dataForm);

  const createElementForm = (ele: any) => {
    return {
      type: "input",
      label: t(`prescription.template.${ele.name}.label`),
      dataElement: {
        placeholder: t(`prescription.template.${ele.name}.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" },
      ...ele
    };
  };

  const formStructureDevice = [{ name: "guaranteedVolume" }, { name: "modelDevice" }].map(ele => createElementForm(ele));


  return {
    formStructurePathologies,
    formStructureMode,
    formStructureDevice
  };
};
