import React, { ReactChild, useState } from "react";
import { mergeClass } from "../../functions/function";
import { classByArray } from "../../utils/htmlUtils";
import { IconSVG } from "../icon/IconSVG";
import LabelValue from "./LabelValue";

const LabelValueHover = ({
                           label,
                           value,
                           iconValue,
                           clickIconValueHandler,
                           title,
                           ...rest
                         }: { label: string | ReactChild, value?: string, iconValue?: { ico: string, size?: string, color?: string }, clickIconValueHandler?: (value?: string) => void, title?: string }) => {

  const [showIcon, setShowIcon] = useState(false);

  const iconDefault = { ico: "close", size: "14px", ...iconValue };

  return (
    <div className={""} onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
      <LabelValue className={"position-relative"}
                  label={label}
                  value={<span>{value} {showIcon &&
                    <IconSVG icon={iconDefault.ico} size={iconDefault?.size} tintColor={iconDefault?.color} title={title}
                             className={"pointer position-absolute ms-1"}
                             onClick={() => clickIconValueHandler && clickIconValueHandler(value)} />}</span>} {...rest} />
    </div>
  );
};

export default LabelValueHover;
