import * as requestFromServer from "./cartCrud";
import { setErrorTypeAction } from "../errors/errorsActions";
import { addProductToCart, setCart } from "./cartSlice";

export const GetCartAction = (t) => (dispatch) => {
  return requestFromServer
    .getCartCall()
    .then((response) => {
      dispatch(setCart(response));
    })
    .catch((error) => {
      dispatch(setErrorTypeAction(t("sg.msg.errorLoadingCart")));
    });
};

export const AddProductToCartAction = (t, data, finalize = false, callback) => (dispatch, getState) => {
  if (getState().cart.cart.find(ele => ele.id.toString() === data.id.toString())) {
    dispatch(UpdateQuantityProductToCartAction(t, data, data.id, callback));
  } else {
    return requestFromServer
      .addProductToCartCall(data)
      .then((response) => {
        finalize ? dispatch(addProductToCart(response)) : dispatch(GetCartAction(t));
        callback && callback();
      })
      .catch((error) => {
        dispatch(setErrorTypeAction(t("sg.msg.errorAddProdCart")));
      });
  }

};

export const UpdateQuantityProductToCartAction = (t, data, idProduct, callback) => (dispatch) => {
  return requestFromServer
    .editProductCartCall(idProduct, data)
    .then((response) => {
      dispatch(GetCartAction(t));
      callback && callback();
      //dispatch(editProductToCart(response));
    })
    .catch((error) => {
      dispatch(GetCartAction(t));
      dispatch(setErrorTypeAction(t("sg.msg.errorUploadProdCart")));
    });
};

export const deleteProductByCartAction = (t, productId) => (dispatch) => {
  return requestFromServer
    .deleteProductByCartCall(productId)
    .then((response) => {
      dispatch(GetCartAction(t));
      //dispatch(deleteProductToCart(response));
    })
    .catch((error) => {
      dispatch(setErrorTypeAction(t("sg.msg.errorDeleteProdCart")));
    });
};
