import { useModeSleepData } from "./useModeSleepData";

export const useSleepData = (t: any, dataForm: any) => {

    const { formStructureMode } = useModeSleepData(t, dataForm);

    const formStructureDevice = [
        {
            name: 'device',
            type: 'input',
            label: t('prescription.template.device.label'),
            dataElement: {
                placeholder: t('prescription.template.device.placeholder'),
                type: 'text',
            },
            col: {xs: "6", sm: "4", lg: "3", xl: "3"}
        },
    ];
    return {
        formStructureMode, formStructureDevice
    };
};
