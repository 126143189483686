import { modeChoose } from "../../../../../../../shared_lsi_sg/modeDataForm";
import { required } from "../../../../../../../shared/utils/forms/utils/validationType";

export const createModeValidation = (mode, t) => {
  return modeChoose[mode]?.reduce((acc, ele) =>
    [...acc, ...(ele?.req ? [{
      name: ele?.name,
      validations: [
        {
          name: required,
          params: {
            name: t(`prescription.template.${ele.name}.label`)
          }
        }
      ]
    }] : [])], []
  );
};
