import React, { useEffect } from "react";
import Patient from "../../../../shared/UI components/Patient";
import moment from "moment/moment";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";
import IDPatient from "../../../../shared/UI components/IDPatient";
import IconLabelDetail from "../../../../shared/UI components/IconLabelDetail";
import { useDispatch } from "react-redux";
import { resetIsLsi } from "../../../../redux/order/orderSlice";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../shared/configuration";

const PatientDetailTemplate = ({ patient }: { patient: any }) => {
  const { t } = useLanguageForShared();
  const dispatch = useDispatch();
  const birthdate = patient?.birthDate ? moment(patient?.birthDate).format("DD MMM YY") : "";
  const ageCalc = ""; //TODO calcolare anni
  useEffect(() => {
    return () => {
      dispatch(resetIsLsi());
    };
  }, []);
  const goToLsi = () => {
    window.location.href = `${process.env.REACT_APP_URL_LSI}pazienti/${patient.id}/dettaglio`;
  };
  console.log(patient);
  return (
    <>
      <div className={"d-flex flex-wrap gap-5"}>
        <Patient
          patient={{
            name: patient.name,
            surname: patient.surname,
            birthdate: birthdate,
            age: ageCalc
          }} />
        <IDPatient cfPatient={patient.cf} />
        <IconLabelDetail icon={"phone"} label={patient.phone} value={t("registry.telephone")} />
        <IconLabelDetail icon={"mobile"} label={patient.mobile} value={t("registry.mobile")} />
        {patient.email && <IconLabelDetail icon={"mail"} label={patient.email} value={t("registry.email")} />}
      </div>
      <div>
        {patient?.source === "lsi" &&
          <div className={"ms-5"}>
            <IconLabelDetail icon={"exam"} iconSize={"28px"}
                             label={<a className={"text-primary"} onClick={goToLsi}>{t("sg.patient.scheduledPatient")}</a>}
                             value={t("Service Insight")} />
          </div>
        }

        {/* {patient?.source === "sg" && <div className={"d-flex flex-column"}>
          <BtnDefault
            className="border-0 p-2 py-0 h-auto text-capitalize"
            onClick={goToLsi}
            icon="exam"
            tintColor={configuration.theme.primary}
            iconSize={"24px"}>
            Scheda paziente
          </BtnDefault>
          <small className={"ms-5 ps-5"}> <b>Linde | Service Insight</b></small>
        </div>}*/}
      </div>
    </>
  );
};

export default PatientDetailTemplate;
