import React, {useEffect} from 'react';
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
import { FormElements } from "../../../../../shared/bootstrap/form/FormElements";
import { useForm } from "../../../../../shared/utils/forms/useForm";
import useFormModalContext from "../../../../shared/redux/modal/context/UseFormModalContext";

const FormSuspension = ({ }) => {
    const { t } = useLanguageForShared();

    const {dataForm, changeValue, setData, errors, isSubmit} = useFormModalContext()

    useEffect(() => {
      setData({ suspensionType: 'death' })
    }, [])

    const formStructureSuspension = [
      {
        name: "suspensionType",
        label: "",
        format: "",
        type: "radio",
        options: [
          {
            value: "death",
            label: t("sg.suspensionDeath")
          },
          {
            value: "other",
            label: t("sg.suspensionOther")
          }
        ],
        dataElement: {
          display: "d-flex gap-5"
        },
        display: {
          form: "d-flex"
        },
        col: { xs: "12", sm: "12", lg: "12" }
      },
      ...(dataForm?.suspensionType === 'other' ? [{
        name: "suspensionNote",
        label: t("sg.suspensionOtherNote"),
        dataElement: {
          placeholder: t("sg.suspensionOtherPlaceholder"),
          type: "text",
        },
        type: "input",
        col: { xs: "12" }
      }] : [])
    ];


    return (
      <div>
        <p>{t("sg.msg.suspensionMotivation")}</p>

        <FormElements
          structure={formStructureSuspension}
          dataForm={dataForm}
          changeValue={changeValue}
          errors={errors}
          isSubmit={isSubmit}
        />
      </div>
    );
  }
;


export default FormSuspension;
