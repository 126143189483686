import { getTodayDate, toEnDateString } from "../../../utils/dateUtils";

const todayDate = toEnDateString(getTodayDate());

export const optionDelivery = (t) => ({
  pharmacy: {
    label: t("sg.pharma"),
    value: "pharmacy"
  },
  patient: {
    label: t("sg.domicile"),
    value: "patient"
  }
});

export const formStructureConsegnaRow = (t) => [
  {
    name: "delivery",
    label: "",
    type: "radio",
    format: "",
    options: [optionDelivery(t).pharmacy, optionDelivery(t).patient],
    col: { xs: "12", sm: "6", lg: "6" }
  }
];
export const formStructureIndirizzoConsegnaRow = (t) => [
  {
    name: "patient.domicile.street",
    label: t("sg.addressComplete"),
    type: "input",
    col: { xs: "12", sm: "6", lg: "4", xl: "3" }
  },
  {
    name: "patient.domicile.number",
    label: t("sg.patient.number"),
    type: "input",
    col: { xs: "4", sm: "2", lg: "2", xl: "1" }
  },
  {
    name: "patient.domicile.internal",
    label: t("sg.patient.int"),
    type: "input",
    col: { xs: "4", sm: "2", lg: "2", xl: "1" }
  },
  {
    name: "patient.domicile.cap",
    label: t("sg.patient.zip"),
    type: "input",
    col: { xs: "4", sm: "2", lg: "2", xl: "1" }
  },
  {
    name: "patient.domicile.city",
    label: t("sg.patient.city"),
    type: "input",
    col: { xs: "12", sm: "6", lg: "4", xl: "3" }
  },
  {
    name: "patient.domicile.province",
    label: t("sg.patient.province"),
    type: "input",
    col: { xs: "12", sm: "3", lg: "2", xl: "1" }
  }
];
export const formStructureIndirizzoResidenceRow =(t)=> [
  {
    name: "patient.residence.street",
    label: t("sg.addressComplete"),
    type: "input",
    col: { xs: "12", sm: "6", lg: "4", xl: "3" }
  },
  {
    name: "patient.residence.number",
    label: t("sg.patient.number"),
    type: "input",
    col: { xs: "4", sm: "2", lg: "2", xl: "1" }
  },
  {
    name: "patient.residence.internal",
    label: t("sg.patient.int"),
    type: "input",
    col: { xs: "4", sm: "2", lg: "2", xl: "1" }
  },
  {
    name: "patient.residence.cap",
    label: t("sg.patient.zip"),
    type: "input",
    col: { xs: "4", sm: "2", lg: "2", xl: "1" }
  },
  {
    name: "patient.residence.city",
    label: t("sg.patient.city"),
    type: "input",
    col: { xs: "12", sm: "6", lg: "4", xl: "3" }
  },
  {
    name: "patient.residence.province",
    label: t("sg.patient.province"),
    type: "input",
    col: { xs: "12", sm: "3", lg: "2", xl: "1" }
  }
];

export const formStructureContattiRow =(t)=> [
  {
    name: "patient.phone",
    label: t("sg.patient.phone"),
    type: "input",
    col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" }
  },
  {
    name: "patient.mobile",
    label: t("sg.patient.mobile"),
    type: "input",
    col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" }
  },
  {
    name: "patient.email",
    label: t("sg.patient.email"),
    type: "input",
    col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" }
  }
];

export const formStructureNominative =(t)=> [
  {
    name: "patient.surname",
    label: t("sg.patient.lastName"),
    type: "input",
    col: { xs: "12", sm: "6", md: "3", lg: "3", xl: "3" }
  },
  {
    name: "patient.name",
    label: t("sg.patient.firstName"),
    type: "input",
    col: { xs: "12", sm: "6", md: "3", lg: "3", xl: "3" }
  },
  {
    name: "patient.cf",
    label: t("sg.patient.fiscalCode"),
    type: "input",
    col: { xs: "12", sm: "6", md: "4", lg: "3", xl: "3" }
  }
];
export const formStructureNominativeRow = (t)=>[
  ...formStructureNominative(t),
  {
    name: "patient.birthDate",
    label: t("sg.patient.birthDate"),
    type: "input",
    dataElement: {
      type: "date",
      max: todayDate
    },
    col: { xs: "12", sm: "6", md: "4", lg: "3", xl: "3" }
  }
];
export const formStructureNominativeRowWithTelephone =(t)=> [
  ...formStructureNominativeRow(t),
  {
    name: "patient.phone",
    label: t("sg.patient.phone"),
    type: "input",
    col: { xs: "12", sm: "6", md: "4", lg: "4", xl: "3" }
  }
];

export const formStructureOpzioniConsegnaRow = [
  {
    name: "delivery",
    label: "",
    type: "radio",
    format: "",
    options: [
      {
        label: "Farmacia",
        value: "farmacia",
        col: { xs: "12", sm: "12", lg: "12" }
      },
      {
        label: "A domicilio",
        value: "domicilio",
        col: { xs: "12", sm: "12", lg: "12" }
      }
    ],
    col: { xs: "12", sm: "12", lg: "12" }
  }
];
export const formStructureNoteRow = [
  {
    name: "note",
    label: "",
    type: "textarea",
    col: { xs: "12" }
  }
];
