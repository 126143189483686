import React, { useEffect } from "react";
import { FormElements } from "../../../../shared/ui/forms/FormElements";
import { formStructureIndirizzoConsegnaRow } from "../../../sample/templateGenFar/templateGenFarData";
import { useFormik } from "formik";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";
import { containerCommunications } from "../../../../../redux/container/containerCrud";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../../../redux/customer/customerSlice";
import { updatePatient } from "../../../../../redux/order/orderCrud";
import { Row } from "react-bootstrap";
import { setErrorTypeAction } from "../../../../../redux/errors/errorsActions";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function Collect({ container, resetShow }) {
	const { t } = useLanguageForShared();


	const customer = useSelector(selectSelectedCustomer);
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {},
		onSubmit: (values) => {},
	});

	useEffect(() => {
		container?.deliveryPlace &&
			formik.setFieldValue("patient", container.deliveryPlace);
	}, [container]);

	const collectAction = () => {
		updatePatient(formik.values.patient).then((res) => {
			containerCommunications({
				clientCode: customer.id,
				patientCode: formik.values.patient.id,
				note: formik.values.note,
				serial: container.serial,
			})
				.then((res) => resetShow())
				.catch((error) => {
					dispatch(setErrorTypeAction(t('sg.msg.errorRequestSend')));
				});
		}, err => {
			const message = err?.response?.data?.Message;
			if(message === 'ERR_CF_DUPLICATE') {
				dispatch(setErrorTypeAction(t('sg.msg.cfAlreadyRegistered')))
			}
			else if(message === 'ERR_CF_INCOHERENT'){
				dispatch(setErrorTypeAction(t('sg.msg.cfWrong')))
			}
			else if(message === 'ERR_EMAIL_DUPLICATE'){
				dispatch(setErrorTypeAction(t('sg.msg.emailAlreadyRegistered')))
			}
			else if(message === 'ERR_EMAIL_INCOHERENT'){
				dispatch(setErrorTypeAction(t('sg.msg.emailWrong')))
			}
			else {
				dispatch(setErrorTypeAction(t('sg.msg.errorUpdatePatient')))
			}
		});
	};
	const formStructureNominativeRow = [
		{
			name: "patient.surname",
			label: t('sg.patient.lastName'),
			type: "input",
			disabled: true,
			col: { xs: "12", sm: "6", lg: "3" },
		},
		{
			name: "patient.name",
			label: t('sg.patient.firstName'),
			type: "input",
			disabled: true,
			col: { xs: "12", sm: "6", lg: "3" },
		},
		{
			name: "patient.phone",
			label: t('sg.patient.phone'),
			type: "input",
			col: { xs: "12", sm: "6", lg: "3" },
		},
		{
			name: "note",
			label: t('sg.patient.note'),
			type: "textarea",
			col: { xs: "9" },
		},
	];
	return (
		<div>
			<FormElements
				structure={formStructureNominativeRow}
				formik={formik}></FormElements>
			<HeadingH4>{t("sg.patient.pickupAddress")}</HeadingH4>
			<FormElements
				structure={formStructureIndirizzoConsegnaRow(t)}
				formik={formik}></FormElements>
			<Row className="justify-content-end">
				<BtnSecondary className="me-3" onClick={resetShow}>
					{t("action.cancel")}
				</BtnSecondary>
				<BtnPrimary onClick={collectAction}>{t("sg.sendRequest")}</BtnPrimary>
			</Row>
		</div>
	);
}
