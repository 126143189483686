import { t } from "i18next";

export const actionsNewObj = {
  "AZ-Approva": "AZ-Approva",
  "AZ-Nonapprova": "AZ-Nonapprova",
  "AZ-Richiedicancellazione": "AZ-Richiedicancellazione", //se ho richiadi cancellazione
  "AZ-Cancella.cliente": "AZ-Cancella.cliente", //cancella cliente
  "AZ-Cancella.BO": "AZ-Cancella.BO", //cancella bo
  "AZ-Prendeincarico": "AZ-Prendeincarico", //salva -valida
  "AZ-AnnullaPresaincarico": "AZ-AnnullaPresaincarico", //annulla
  "AZ-Chiudilav": "AZ-Chiudilav", //salva -valida
  "AZ-Visualizzazione": "AZ-Visualizzazione", //fittizia
  "AZ-Invia": "AZ-Invia" //fittizia,
};

export const actionsNew = () => ([

  {
    code: "AZ-Approva",
    label: t("sg.stateAction.AZ-Approva"),
    icon: "trash"
  },
  {
    code: "AZ-Nonapprova",
    label: t("sg.stateAction.AZ-Nonapprova"),
    icon: "trash"
  },
  {
    code: "AZ-Richiedicancellazione",
    label: t("sg.stateAction.AZ-Richiedicancellazione"),
    icon: "trash"
  },
  {
    code: "AZ-Cancella.cliente",
    label: t("sg.stateAction.AZ-Cancella.cliente"),
    icon: "trash"
  },
  {
    code: "AZ-Cancella.BO",
    label: t("sg.stateAction.AZ-Cancella.BO"),
    icon: "trash"
  },
  {
    code: "AZ-Prendeincarico",
    label: t("sg.stateAction.takeCharge"),
    icon: "take-charge"
  },
  {
    code: "AZ-Chiudilav",
    label: t("sg.stateAction.AZ-Chiudilav"),
    icon: "trash"
  },
  {
    code: "AZ-Visualizzazione",
    label: t("sg.stateAction.AZ-Visualizzazione"),
    icon: "trash"
  },
  {
    code: "AZ-Invia",
    label: t("sg.stateAction.AZ-Invia"),
    icon: "trash"
  }
]);
export const actions = () => ([
  {
    id: 1,
    code: "Send",
    label: t("sg.stateAction.send")
  },
  {
    id: 2,
    code: "Approve",
    label: t("sg.stateAction.approve")
  },
  {
    id: 3,
    code: "Deny",
    label: t("sg.stateAction.refuse")
  },
  {
    id: 4,
    code: "Delete",
    label: t("sg.stateAction.requestDeleted"),
    msg: {
      title: t("sg.stateAction.requestDeletedMsgTitle"),
      body: t("sg.stateAction.requestDeletedMsgBody")
    },
    icon: "trash"
  },
  {
    id: 5,
    code: "Delete",
    label: t("sg.stateAction.delete"),
    msg: {
      title: t("sg.stateAction.deleteMsgTitle"),
      body: t("sg.stateAction.deleteMsgBody")
    },
    icon: "trash"
  },
  {
    id: 6,
    code: "Reject-Cancellation",
    label: t("sg.stateAction.refuseDeleteReq")
  },
  {
    id: 7,
    code: "Close-with-Positive-Result",
    label: t("sg.stateAction.closedPositive")
  },
  {
    id: 8,
    code: "work",
    label: t("sg.stateAction.work")
  },
  {
    id: 9,
    code: "work-end",
    label: t("sg.stateAction.closedWork")
  }
]);

export const findAction = (id: number | string) => {
  return actionsNew().find((action) => action.code === id);
};
