import React, { useEffect, useState } from "react";
import { FormElements } from "../../../../../../shared/bootstrap/form/FormElements";
import { useTemplateFormOxigen } from "./data/useTemplateFormOxigen";
import { useTranslation } from "react-i18next";
import { useTemplateFormOtherInfo } from "./data/useTemplateFormOtherInfo";
import { useParamsData } from "./data/useParamsData";
import { copyObject } from "../../../../../../shared/utils/objUtils";
import { useEffectOnlyUpdate } from "../../../../../../shared/utils/use_custom/useEffectCustom";
import InputCustom from "../../../../../../shared/bootstrap/form/input/InputCustom";
import FormGroup from "../../../../../../shared/bootstrap/form/form-group/FormGroup";


export function TemplateOxigen({ dataForm, changeValue, errors, setData, isSubmit }) {
  const { t } = useTranslation();
  const [sum, setSum] = useState();
  const {
    formStructurePathologies,
    formStructurePrescription,
    formStructureValid,
    formStructureStroller
  } = useTemplateFormOxigen(t, dataForm);
  const {
    formStructureCannulaNasal, formStructureMask,
    formStructureDetailCannulaNasal, formStructureDetailMask
  } = useTemplateFormOtherInfo(t, dataForm);
  const {
    formStructureNote
  } = useParamsData(t, dataForm);

  useEffectOnlyUpdate(() => {
    if (!dataForm?.cannulaNasal) {
      const modeField = formStructureDetailCannulaNasal.map((ele) => ele.name).join(",").split(",");
      const copyDataForm = copyObject(dataForm);
      modeField?.forEach((key) => {
        delete copyDataForm[key];
      });
      setData(copyDataForm);
    }
  }, [dataForm?.cannulaNasal]);

  useEffect(() => {
    if (!dataForm?.mask) {
      const modeField = formStructureDetailMask.map((ele) => ele.name).join(",").split(",");
      const copyDataForm = copyObject(dataForm);
      modeField?.forEach((key) => {
        delete copyDataForm[key];
      });
      setData(copyDataForm);
    }
  }, [dataForm?.mask]);

  const getValue = (val) => {
    return val ? parseInt(val) : 0;
  };
  useEffect(() => {
    setSum(getValue(dataForm?.nightFlowHourDie) + getValue(dataForm?.underStressFlowHourDie) + getValue(dataForm?.flowRestHourDie));
  }, [dataForm?.nightFlowHourDie, dataForm?.underStressFlowHourDie, dataForm?.flowRestHourDie]);

  return (
    <>
      <FormElements
        structure={formStructurePathologies}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
      <p className={"title-paragraph"}>{t('sg.prescription')}</p>
      <FormElements
        structure={formStructurePrescription}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />

      <div className="row">
        <div className="col-sm-12 col-lg-3">
          <FormGroup
            label={{ label: t("prescription.template.check24h.label") }}
            error={{
              isVisible: sum > 24,
              message: t("prescription.template.check24h.error")
            }}
          >
            <InputCustom
              type={"text"}
              name={"check24h"}
              error={sum > 24}
              value={sum}
              disabled={true}
            />
          </FormGroup>
        </div>
        <div className="col-sm-12 col-lg-3">
          <FormElements
            structure={formStructureValid}
            dataForm={dataForm}
            changeValue={changeValue}
            errors={errors}
            isSubmit={isSubmit}
          />
        </div>
      </div>

      <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
      <FormElements
        structure={formStructureStroller}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
      <FormElements
        structure={formStructureCannulaNasal}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
      <FormElements
        structure={formStructureMask}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
      <FormElements
        structure={formStructureNote}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
    </>
  );
}
