/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import userManager from "./utils/oidcUserManager";
import { OidcProvider } from 'redux-oidc';
import ConfigLoad from "../shared/shared_load/ConfigLoad";
import LanguageLoad from "../shared/shared_load/LanguageLoad";

export default function App({ store, persistor, basename }) {

  useEffect(() => {
    let appVersion = localStorage.getItem('appVersion');
    if (!appVersion || appVersion !== process.env.REACT_APP_VERSION) {
      let customerId = localStorage.getItem('customerId');
      localStorage.clear();
      localStorage.setItem('customerId', customerId)
      localStorage.setItem('appVersion', process.env.REACT_APP_VERSION)
    }
  }, []);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <ConfigLoad>
                    <LanguageLoad language='it'>
                      <Routes />
                    </LanguageLoad>
                  </ConfigLoad>
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </OidcProvider>
    </Provider>
  );
}
