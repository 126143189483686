import React from "react";
import HeadingH4 from "../../shared/ui/headings/headingH4/HeadingH4";
import Icon from "../../shared/ui/icon/Icon";
import InputNumber from "../../shared/ui/forms/number/InputNumber";
import configuration from "../../../shared/configuration";
import { IconSVG } from "../../../shared/custom/icon/IconSVG";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function ProductCartEdit({
                                  product,
                                  quantity,
                                  changeValueHandle,
                                  keyDownHandler,
                                  upValue,
                                  downValue,
                                  deleteProductCart
                                }) {
  const { t } = useLanguageForShared();

  return (
    <div className="d-flex align-items-center">
      {product.specifiedQuantity && (
        <>
          <span className="m-0 mx-2" style={{ color: configuration.theme.dark }}>{t('sg.quantitySm')} </span>
          <div style={{ width: "80px" }}>
            <InputNumber
              data-test="product-change-quantity"
              ele={{ name: product.id, format: "number", min: 1 }}
              value={quantity || ""}
              onChange={changeValueHandle}
              onKeyDown={keyDownHandler}
              upHandler={upValue}
              downHandler={downValue}
            />
          </div>
        </>
      )}
      {product?.price && (
        <HeadingH4
          className="m-0 mx-2 wordNoWrap dark"
          data-test="product-cart-price"
        >
          <span>€ {product?.price.toFixed(2)}</span>
        </HeadingH4>
      )}
      {deleteProductCart && (
        <IconSVG
          data-test="product-delete"
          className={"pointer ms-2"}
          size={"24px"}
          icon="trash"
          tintColor={configuration.theme.primary}
          onClick={() => deleteProductCart(product.id)}
        />
      )}
    </div>
  );
}
