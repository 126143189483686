export const pages = {
	selectedProduct: "selectedProduct",
	selectedPatient: "selectedPatient",
	products: "products",
	summary: "summary",
	summaryFinal: "summaryFinal",
};

export const titlePharmacyApp = (t) =>({
	[pages.selectedProduct]: {
		title: t('sg.titles.pharma.selectedProductTitle'),
		subtitle: t('sg.titles.pharma.selectedProductSubtitle'),
	},
	[pages.products]: {
    title: t('sg.titles.pharma.productsTitle'),
    subtitle: t('sg.titles.pharma.productsSubtitle'),
	},
    [pages.summary]: {
      title: t('sg.titles.pharma.summaryTitle'),
      subtitle: t('sg.titles.pharma.summarySubtitle'),
	},
    [pages.summaryFinal]: {
      title: t('sg.titles.pharma.summaryFinalTitle'),
      subtitle: t('sg.titles.pharma.summaryFinalSubtitle'),
	},
});

export const titlePrescriberApp = (t)=>({
	[pages.selectedProduct]: {
    title: t('sg.titles.prescriber.selectedProductTitle'),
    subtitle: t('sg.titles.prescriber.selectedProductSubtitle'),
	},
	[pages.selectedPatient]: {
    title: t('sg.titles.prescriber.selectedPatientTitle'),
    subtitle: t('sg.titles.prescriber.selectedPatientSubtitle'),
	},
	[pages.products]: {
    title: t('sg.titles.prescriber.productsTitle'),
    subtitle: t('sg.titles.prescriber.productsSubtitle'),
	},
	[pages.summary]: {
    title: t('sg.titles.prescriber.summaryTitle'),
    subtitle: t('sg.titles.prescriber.summarySubtitle'),
	},
	[pages.summaryFinal]: {
    title: t('sg.titles.prescriber.summaryFinalTitle'),
    subtitle: t('sg.titles.prescriber.summaryFinalSubtitle'),
	},
});
