import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import Icon from "../../../shared/ui/icon/Icon";
import {
  formStructureConsegnaRow,
  formStructureContattiRow,
  formStructureIndirizzoConsegnaRow,
  formStructureNoteRow,
  formStructureNominativeRow,
  optionDelivery, formStructureUpdateRegistry, formStructureNominative
} from "./templateGenFarData";
import {
  createPatient,
  getDeliveries,
  getDeliveriesPatient,
  updatePatient
} from "../../../../redux/order/orderCrud";
import BoxSearchPatients from "./component/BoxSearchPatients";
import { getPatient, getPatients } from "../../../../redux/patient/patientCrud";

import { setStopLoader } from "../../../shared/ui/loader/redux/loadSlice";
import { useDispatch, useSelector } from "react-redux";
import { UseTemplateGenFar } from "./UseTemplateGenFar";
import { useFormik } from "formik";
import {
  validationsTemplateGenFar,
  validationsTemplateGenFarWithIdPatient,
  validationsTemplateGenFarWithPatient
} from "../templateGenPub/templateGenPubData";
import { setErrorTypeAction } from "../../../../redux/errors/errorsActions";
import {
  selectIsPharmacy,
  selectSelectedCustomer
} from "../../../../redux/customer/customerSlice";
import axios from "axios";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { IconSVG } from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import Checkbox from "../../../../shared/bootstrap/form/checkbox/Checkbox";
import {useIsUpdatePatient} from "../useIsUpdatePatient";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

let stopAction = false;
let timeoutAction;
let cancelToken;
const TemplateGenFar = ({ customer, template, createOrder, goBack, order }) => {
  const { t } = useLanguageForShared();

  const [validations, setValidations] = useState(validationsTemplateGenFar);
  const [selectPatient, setSelectedPatient] = useState();




  const isPharmacy = useSelector(selectIsPharmacy);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validations,
    onSubmit: (values) => {
      submitForm();
    }
  });

  const dispatch = useDispatch();
  const [patients, setPatients] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [pharmacyDeliveries, setPharmacyDeliveries] = useState([]);
  const {updateRegistry, setUpdateRegistry, isUpdatePatient} = useIsUpdatePatient(selectPatient, formik.values.patient)
  useEffect(() => {
    order &&
    setTimeout(() => {
      formik.setValues(order);
    }, 100);
  }, [order]);
  const setPatient = (pat) => {
    stopAction = true;

    getPatient(pat.id).then((res) => {
      setPatients([]);
      setSelectedPatient({ ...res });
      formik.setFieldValue("patient", { ...res });
      setTimeout(() => {
        stopAction = false;
      }, 1000);
    }, err => console.log(err) );
  };
  useEffect(() => {
    if (
      (formik.values.patient?.name ||
        formik.values.patient?.surname ||
        formik.values.patient?.cf) &&
      !stopAction &&
      !formik.values.patient?.id
    ) {
      dispatch(setStopLoader(true));
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      getPatients(
        {
          name: formik.values.patient?.name,
          surname: formik.values.patient?.surname,
          cf: formik.values.patient?.cf,
          customerCode: selectedCustomer.id
        },
        cancelToken
      ).then((res) => {
        timeoutAction && clearTimeout(timeoutAction);
        timeoutAction = setTimeout(() => {
          dispatch(setStopLoader(false));
        }, 500);
        setPatients(res);
      });
    } else {
      dispatch(setStopLoader(false));
      setPatients([]);
    }
  }, [
    formik.values.patient?.name,
    formik.values.patient?.surname,
    formik.values.patient?.cf
  ]);

  useEffect(() => {
    if (!!formik.values.patient?.id) {
      setValidations(validationsTemplateGenFarWithIdPatient);
      getDeliveriesPatient(formik.values.patient?.id, template)
        .then((res) => setDeliveries(res))
        .catch((error) => {
          dispatch(
            setErrorTypeAction(
              t('sg.msg.errorDeliveryOptions')
            )
          );
        });
    } else {
      setValidationPatientOrPharmacy();
    }
  }, [formik.values.patient?.id]);
  useEffect(() => {
    stopAction = false;
    getDeliveries(selectedCustomer.id).then((list) => {
      setPharmacyDeliveries(list);
    });
    return () => {
      dispatch(setStopLoader(false));
    };
  }, []);
  const { formStructureDeliveryOptionsRow } = UseTemplateGenFar(
    formik.values?.delivery === optionDelivery(t).pharmacy.value
      ? pharmacyDeliveries
      : deliveries
  );
  const updatePatientAction = (callback) => {
    updatePatient(formik.values.patient).then(
        (res) => {
          callback && callback()
        },
        (err) => {
          const message = err?.response?.data?.Message;
          if (message === "ERR_CF_DUPLICATE") {
            dispatch(setErrorTypeAction(t('sg.msg.cfAlreadyRegistered')))
          } else if (message === "ERR_CF_INCOHERENT") {
            dispatch(setErrorTypeAction(t('sg.msg.cfWrong')))
          } else if (message === "ERR_EMAIL_DUPLICATE") {
            dispatch(setErrorTypeAction(t('sg.msg.emailAlreadyRegistered')))
          } else if (message === "ERR_EMAIL_INCOHERENT") {
            dispatch(setErrorTypeAction(t('sg.msg.emailWrong')))
          } else {
            dispatch(
                setErrorTypeAction(t('sg.msg.errorUpdatePatient'))
            );
          }
        }
    );
  }
  const showFinalize =
    formik.values.patient?.id ||
    formik.values?.delivery === optionDelivery(t).pharmacy.value;
  const submitForm = () => {
    if (showFinalize) {
      if (formik.values?.delivery === optionDelivery(t).pharmacy.value) {
        createOrder({
          ...formik.values,
          deliveryData: pharmacyDeliveries.find(
            (ele) => ele.id.toString() === formik.values.deliveryType.toString()
          )
        });
      } else {
        const callback = () => {
          createOrder({
            ...formik.values,
            deliveryData: deliveries.find(
                (ele) =>
                    ele.id.toString() === formik.values.deliveryType.toString()
            )
          });
        }
        updateRegistry? updatePatientAction(callback) : callback()
      }
    } else {
      createPatient({ ...formik.values.patient }, customer.id).then(
        (res) => {
          formik.setFieldValue("patient", { ...res });
        },
        (err) => {
          const message = err?.response?.data?.Message;
          if (message === "ERR_CF_DUPLICATE") {
            dispatch(setErrorTypeAction(t('sg.msg.cfAlreadyRegistered')))
          } else if (message === "ERR_CF_INCOHERENT") {
            dispatch(setErrorTypeAction(t('sg.msg.cfWrong')))
          } else if (message === "ERR_EMAIL_DUPLICATE") {
            dispatch(setErrorTypeAction(t('sg.msg.emailAlreadyRegistered')))
          } else if (message === "ERR_EMAIL_INCOHERENT") {
            dispatch(setErrorTypeAction(t('sg.msg.emailWrong')))
          } else {
            dispatch(setErrorTypeAction(t('sg.msg.errorInsertPatient')));
          }
        }
      );
    }
  };

  const resetPatient = () => {
    resetDataChangeDelivery(formik.values.delivery);
  };
  const resetDataChangeDelivery = (delivery) => {
    formik.setValues({ delivery });
    setDeliveries([]);
    setSelectedPatient(undefined);
  };
  useEffect(() => {
    // formik.values.delivery === "pharmacy" && resetPatient();
    setValidationPatientOrPharmacy();
  }, [formik.values.delivery]);

  const setValidationPatientOrPharmacy = () => {
    formik.values.delivery === "patient"
      ? setValidations(validationsTemplateGenFarWithPatient)
      : setValidations(validationsTemplateGenFar);
  };

  return (
    <div className={'templates-wrap template-gen-far'}>
      <Form autocomplete="off" onSubmit={formik.handleSubmit}>
        <HeadingH4>{t("sg.delivery")}</HeadingH4>
        <FormElements
          style={{ maxHeight: "64px" }}
          changeValue={(name, val) => {
            resetDataChangeDelivery(val);
            formik.setFieldValue(name, val);
          }}
          structure={formStructureConsegnaRow(t)}
          formik={formik}
        ></FormElements>
        {formik.values.delivery === "patient" && (
          <>
            <HeadingH4>{t("sg.nominative")}</HeadingH4>
            <FormElements
              structure={
                formik.values?.patient?.id
                  ? formStructureNominative(t).map((ele) => ({
                    ...ele,
                    disabled:
                      selectPatient &&
                      !selectPatient.cf &&
                      ele.name === "patient.cf"
                        ? false
                        : true
                  }))
                  : formStructureNominative(t)
              }
              formik={formik}
              rightTemplate={
                formik.values?.patient?.id && (
                  <>
                    <Col xs="auto" className={"d-flex align-items-center"}>
                      <IconSVG
                        className={"pt-4 pointer"}
                        tintColor={configuration.theme.primary}
                        icon="close"
                        size="20px"
                        onClick={resetPatient}
                      />
                    </Col>
                  </>
                )
              }
            ></FormElements>
            {/* {formik.values?.patient?.id && (
								<Icon icon="close" clickAction={resetPatient} />
							)} */}

            {patients && !!patients.length && (
              <BoxSearchPatients patients={patients} setPatient={setPatient} />
            )}
            <HeadingH4 className={"mt-3"}>{t("sg.patient.deliveryAddress")}
            </HeadingH4>
            <FormElements
              structure={formStructureIndirizzoConsegnaRow(t)}
              formik={formik}
            ></FormElements>
            <HeadingH4 className={"mt-3"}>{t("sg.patient.contacts")}</HeadingH4>
            <FormElements
              structure={formStructureContattiRow(t)}
              formik={formik}
            ></FormElements>

            {isUpdatePatient && <div className={"update-registry-check py-5 mb-5"}>
              <Checkbox name="updateRegistry"
                        value={updateRegistry}
                        label={t("sg.updateRegistry")}
                        onChange={() => setUpdateRegistry(!updateRegistry)}
                        checked={updateRegistry}/>
            </div>}

          </>
        )}


        {((pharmacyDeliveries &&
            pharmacyDeliveries.length &&
            formik.values?.delivery === optionDelivery(t).pharmacy.value) ||
          (deliveries && !!deliveries.length)) && (
          <>
            <HeadingH4 className={"mt-3"}>{t('sg.optionsDelivery')}</HeadingH4>
            <FormElements
              structure={formStructureDeliveryOptionsRow}
              formik={formik}
            ></FormElements>
          </>
        )}
        <HeadingH4>Note</HeadingH4>
        <FormElements
          structure={formStructureNoteRow}
          formik={formik}
        ></FormElements>
        <Row className="justify-content-end mt-3">
          <Col xs="auto">
            <BtnSecondary onClick={goBack}>
              {isPharmacy ? t("action.cancel") : t("action.back")}
            </BtnSecondary>
          </Col>
          <Col xs="auto">
            <BtnPrimary type="submit" data-test="order-finalize">
              {showFinalize ? t("sg.finalize") : t("sg.proceed")}
            </BtnPrimary>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TemplateGenFar;
