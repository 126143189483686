import React from "react";

const Error = ({
	message,
	children,
	className = "",
	success = false,
	...rest
}) => {
	const classList = ["shared-error"];
	if (success) classList.push("success");
	return (
		<p className={`${classList} ${className}`} {...rest}>
			{message ? message : children}
		</p>
	);
};

export default Error;
