import React from "react";
import HeadingH4 from "../../../../shared/ui/headings/headingH4/HeadingH4";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function TotalDelivery({ qta = 1, price, specifiedQuantity }) {
	const { t } = useLanguageForShared();

	const total = qta * price;
	return (
		<HeadingH4>
			{specifiedQuantity ? (
				<span>
					<span className="me-3">{t("sg.totDeliver")}</span>
					{qta > 1 ? `Q.tà ${qta} x €${price.toFixed(2)}` : null}
					<span className="ms-5">€{total.toFixed(2)}</span>
				</span>
			) : (
				<span>
					<span className="me-5">{t("sg.delivery")}</span>
					<span className="">€{price?.toFixed(2)}</span>
				</span>
			)}
		</HeadingH4>
	);
}
