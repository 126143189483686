import { required } from "../../../../../../../shared/utils/forms/utils/validationType";
import { modeChoose } from "../../../../../../../shared_lsi_sg/modeDataForm";
import { createModeValidation } from "./commonFunctionValidateTemplate";

export const useValidationVentilo = (t: any, dataForm: any) => {
  const validations = [
    {
      name: "pathology",
      validations: [
        {
          name: required,
          params: {
            name: t("common.pathologies")
          }
        }
      ]
    },
    {
      name: "mode",
      validations: [
        {
          name: required,
          params: {
            name: t("prescription.template.mode.label")
          }
        }
      ]
    },
    ...(modeChoose[dataForm?.mode] ? createModeValidation(dataForm?.mode, t) : [])
  ];
  return validations;
};
