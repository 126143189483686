import { modeChoose } from "../../../../../../../shared_lsi_sg/modeDataForm";
import { createModeValidation } from "./commonFunctionValidateTemplate";
import { required } from "../../../../../../../shared/utils/forms/utils/validationType";
import { concentratorType } from "../../../../../../../shared_lsi_sg/concentratorData";

export const useValidationConcentrator = (t: any, dataForm: any) => {
  const validations = [
    {
      name: "concentratorType",
      validations: [
        {
          name: required,
          params: {
            name: t("common.concentrator")
          }
        }
      ]
    },
    ...(dataForm?.concentratorType === concentratorType.fixed ? [
      {
        name: "pathology",
        validations: [
          {
            name: required,
            params: {
              name: t("common.pathologies")
            }
          }
        ]
      },
      {
        name: "flowRest",
        validations: [
          {
            name: required,
            params: {
              name: t("prescription.template.flowRest")
            }
          }
        ]
      },
      {
        name: "underStressFlow",
        validations: [
          {
            name: required,
            params: {
              name: t("prescription.template.underStressFlow")
            }
          }
        ]
      },
      {
        name: "nightFlow",
        validations: [
          {
            name: required,
            params: {
              name: t("prescription.template.nightFlow")
            }
          }
        ]
      }
    ] : []),
    ...(dataForm?.concentratorType === concentratorType.transportable ? [
      {
        name: "pathology",
        validations: [
          {
            name: required,
            params: {
              name: t("common.pathologies")
            }
          }
        ]
      },
      {
        name: "flowRest",
        validations: [
          {
            name: required,
            params: {
              name: t("prescription.template.flowRest")
            }
          }
        ]
      },
      {
        name: "underStressFlow",
        validations: [
          {
            name: required,
            params: {
              name: t("prescription.template.underStressFlow")
            }
          }
        ]
      },
      {
        name: "nightFlow",
        validations: [
          {
            name: required,
            params: {
              name: t("prescription.template.nightFlow")
            }
          }
        ]
      }
    ] : [])
  ];

  return validations;

};
