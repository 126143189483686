import {addErrorMessage, addSuccessMessage} from "../../shared/redux/errors/errorSlice";


export const setSuccessErrorAction = (title, body) => dispatch => {
    dispatch(addSuccessMessage({title, body}))
};

export const setErrorTypeAction = (title, body) => dispatch => {
    dispatch(addErrorMessage({title, body}))
};
