import * as requestFromServer from "./customerCrud";
import { setErrorTypeAction } from "../errors/errorsActions";
import {setCustomers, setProviders, setSelectedCustomer} from "./customerSlice";
import { GetAllProducts } from "../products/productsActions";
import {GetCartAction} from "../cart/cartActions";
import routes from "../../app/routes/auth";
import {getProviders} from "./customerCrud";

export const GetCustomerAction = (t) => (dispatch, getState) => {
	return requestFromServer
		.getCustomers()
		.then((response) => {
			dispatch(setCustomers(response));
			const user = getState().auth.user;
			if(user.role === 'backoffice'){
				routes().firstPage = '/richieste'
			}
			const idCustomer = localStorage.getItem('customerId');
			const initCustomer = idCustomer && response.find(ele => ele.id === idCustomer) ? response.find(ele => ele.id === idCustomer) : response[0];
			dispatch(setSelectedCustomer(initCustomer))
			dispatch(InitCustomerAction(t));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t("sg.msg.errorLoadData")));
		});
};
export const InitCustomerAction = (t) => (dispatch, getState) => {
			dispatch(GetCartAction(t));
			dispatch(GetAllProducts(t));
};

export const setProvidersAction = () => (dispatch, getState) => {
	if(!getState().customer.providers.length){
		getProviders().then(res => {
			dispatch(setProviders(res));
		})
	}
};
