import React from "react";

import TemplateGenFar from "../sample/templateGenFar/TemplateGenFar";
import TemplateGenPub from "../sample/templateGenPub/TemplateGenPub";
import {templateRdoType} from "../../../redux/order/orderData";


export function DetailOrderTemplate({customer, templateRDO, createOrder,  goBack, order, patient }) {
	return (
		<>
			{templateRDO === templateRdoType.GENFAR ? (
				<TemplateGenFar
					goBack={goBack}
					order={order}
					customer={customer}
					template={templateRDO}
					createOrder={createOrder}
				/>
			) : (
				<TemplateGenPub goBack={goBack} patient={patient} order={order} createOrder={createOrder} />
			)}

		</>

	);
}
