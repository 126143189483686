import { checkIsActive } from "../../_helpers";
import HeaderComponent from "../../../libraries/header/HeaderComponent";
import React, { useEffect } from "react";
import { OrganizationModel } from "../../../libraries/header/models/Organization.model";
import { HeaderMenuModel } from "../../../libraries/header/models/Header.model";
import { useDispatch, useSelector } from "react-redux";
import { TemplateModel } from "../../../libraries/header/models/TemplateModel";
import userManager from "../../../app/utils/oidcUserManager";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { usePermissions } from "../../../app/shared/utils/usePemissions";
import { selectCartLength } from "../../../redux/cart/cartSlice";
import {selectCustomerRole, selectCustomerType} from "../../../app/modules/Auth/_redux/authRedux";
import routes from "../../../app/routes/auth";
import * as routeName from "../../../app/routes/routeNameAuthType";
import { useRoute } from "../../../app/utils/useRoute";
import { resetErrors } from "../../../redux/errors/errorsSlice";
import Icon from "../../../app/shared/ui/icon/Icon";
import NotifyBadge from "../../../shared/bootstrap/notifyBadge/NotifyBadge";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";
import {
  resetPatient,
  selectEmptySubNav,
  selectHomeDelivery,
  selectPatient
} from "../../../redux/patient/patientSlice";
import PatientDetailTemplate from "./subHeaderTemplate/PatientDetailTemplate";
import InnerPatientDetailTemplate from "./subHeaderTemplate/InnerPatientDetailTemplate";
import { resetIsLsi } from "../../../redux/order/orderSlice";
import {selectSelectedCustomer, setSelectedCustomer} from "../../../redux/customer/customerSlice";
import {customerTypeName} from "../../../redux/customer/customerData";

interface HeaderWrapperModel {
  logout: () => void;
  user: any;
  changeOrganization?: (id: any) => void;
  currentOrganization?: any;
  organizations?: any,
  logo: any
}

const HeaderWrapper: React.FC<HeaderWrapperModel> = ({
                                                       logout,
                                                       user,
    organizations,
                                                       currentOrganization,
                                                       logo
                                                     }) => {
  const history = useHistory();
  const { t } = useLanguageForShared();
  const patient = useSelector(selectPatient);
  const homeDelivery = useSelector(selectHomeDelivery);
  const emptySubNav = useSelector(selectEmptySubNav);
  const customer_role = useSelector(selectCustomerRole);
  const isBo = customer_role === customerTypeName.bo;
  useEffect(() => {
    window.scrollTo(0, 0);
    history.listen(route => {
      console.log(route);
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    });
  }, []);
  const goProfile = () => {
    window.open(userManager.settings.authority + "/Identity/Account/Manage", "_blank");
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url);
  };
  const { issetPermission } = usePermissions();
  const cartLength = useSelector(selectCartLength);
  const customerType = useSelector(selectCustomerType);

  const listRoutes = (routes().list?.filter((ele) => ele.navTitle) || [])
    .filter(
      (r) =>
        !r.roles ||
        (r.roles.includes(customerType) &&
          (r.permissions && r.permissions.length
            ? issetPermission(r.permissions)
            : true))
    )
    .map((r) => (r.name === routeName.cart ? { ...r, notify: cartLength } : r));

  const templateVoices = {
    [routeName.cart]: <div>
      {!!cartLength && cartLength !== "" && <NotifyBadge notify={cartLength} />}
      {t(`header.nav.voices.${routeName.cart}`)}
    </div>
  };
  const changeOrg = (data: any) => {

    const org = organizations.find((ele: any) => ele.id === data);
    localStorage.setItem('customerId', org.id)
    window.location.reload()
    dispatch(setSelectedCustomer(org))
  }
  const { changeRoute } = useRoute();
  const clickItemMenuHandler = (route) => {
    dispatch(resetErrors());
    dispatch(resetIsLsi());
    dispatch(resetPatient());
    changeRoute(route.name);
  };
  const isPatientTemplate = patient || homeDelivery;
  return <HeaderComponent logout={logout} languages={[]}
                          voices={listRoutes.map(r => ({ ...r, label: r.navTitle.label }))} user={user}
                          templateVoices={templateVoices}
                          subHeaderHtml={isPatientTemplate ? <PatientDetailTemplate patient={isPatientTemplate} /> : emptySubNav ? <div></div> : null}
                          innerTemplateSubNav={isPatientTemplate ?
                            <InnerPatientDetailTemplate patient={isPatientTemplate} /> : emptySubNav ? null : null}
                          currentPage={listRoutes.find(voice => checkIsActive(window.location, voice.path))?.name as string}
                          currentOrganization={isBo? undefined : currentOrganization}
                          organization_id={isBo? undefined:  currentOrganization?.id}
                          changeOrganization={changeOrg}
                          organizations={isBo? [] : organizations}
                          goProfile={goProfile}
                          changePage={clickItemMenuHandler}
                          logo={logo}
                          backHostnames={[process.env.REACT_APP_URL_LSI]}
                          editProfile={true}
                          showExpand={!emptySubNav}/>;
};

export default HeaderWrapper;
