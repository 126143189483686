export const historical = "historical";
export const products = "products";
export const selectProduct = "selectProduct";
export const selectEditProduct = "selectEditProduct";
export const orderPage = "orderPage";
export const patientsPage = "patientsPage";
export const containersPage = "containersPage";
export const patientPage = "patientPage";
export const patientEditPage = "patientEditPage";
export const selectedPatientPage = "selectedPatientPage";
export const redirectPatientPage = "redirectPatientPage";
export const redirectActiveOrder = "redirectActiveOrder";
export const redirectEditOrder = "redirectEditOrder";
export const orderShowPage = "orderShowPage";
export const cart = "cart";
export const sample = "sample";

export const redirectPath = "/error/error-v1";
export const firstPage = "/prodotti";
