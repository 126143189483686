import React from "react";
import { useSelector } from "react-redux";
import {
  dispatchEventModal,
  selectBtnList,
  selectShow,
  selectTemplateBody,
  selectText,
} from "./modalSlice";
import modalEvents from "./modalEvent";
import ModalMsg from "../../ui/modal/ModalMsg";

const ModalApp = () => {
  const show = useSelector(selectShow);
  const buttonList = useSelector(selectBtnList);
  const text = useSelector(selectText);
  const selectedTemplate = useSelector(selectTemplateBody);

  const template = selectedTemplate
    ? modalEvents.templates[selectedTemplate]
    : undefined;

  return (
    <ModalMsg
      showModal={show}
      text={text}
      btn={buttonList}
      templateBody={template}
      clickAction={(action) => dispatchEventModal(action)}
    />
  );
};
export default ModalApp;
