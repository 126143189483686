import React from "react";
import { Col, Row } from "react-bootstrap";
import Caption from "../../../shared/ui/headings/caption/Caption";
import HeadingH4 from "../../../shared/ui/headings/headingH4/HeadingH4";
import I from "../../../shared/ui/icon/I";
import { optionDelivery } from "../../sample/templateGenFar/templateGenFarData";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import RowDelivery from "./summary/RowDelivery";
import { dateformat_ggMMMyyyy } from "../../../utils/dateUtils";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function DetailDelivery({ orderDetail, order, updateDateHandler, setOrderInEdit }) {
  const { t } = useLanguageForShared();

  const isPharmacy = orderDetail?.delivery === optionDelivery(t).pharmacy.value;

  return (
    <section>
      <h3 className={"title-section"}>{t("sg.delivery")}</h3>

      <div className="list-key-value gap-2 align-items-baseline mb-5">
        <span className={"label-item"}>{t("sg.patient.delivery")}</span>
        {!isPharmacy && (
          <div className={"d-flex gap-2 align-items-center"}>
            <div className={"d-flex gap-3"}>
              {orderDetail?.deliveryData?.title || "-"}
              {orderDetail?.deliveryData?.dateDelivery && (
                <u>
                  {dateformat_ggMMMyyyy(orderDetail.deliveryData.dateDelivery)}
                </u>
              )}
              {orderDetail?.deliveryData?.price && (
                <b>+ € {orderDetail.deliveryData.price.toFixed(2)}</b>
              )}
            </div>
          </div>
        )}
        <div className={"d-flex gap-2 align-items-center"}>
          <span className={"label-item"}>{t("sg.patient.to")}</span>
          <span>
          {isPharmacy
            ? optionDelivery(t).pharmacy.label
            : `${orderDetail?.patient?.surname?.toUpperCase()} ${
              orderDetail?.patient?.name
            }`}
          </span>
        </div>
      </div>

      <div className="list-key-value">
        {!isPharmacy && (
          <span>
            <span className={"label-item"}>{t("sg.patient.address")}</span>
            {orderDetail?.patient
              ? Object.values(orderDetail?.patient?.domicile).join(" ")
              : "-"}
          </span>
        )}
        {orderDetail?.patient?.phone &&
          <span>
            <span className={"label-item"}>{t("sg.patient.phone")}</span>
            <span>{orderDetail?.patient?.phone || "-"}</span>
          </span>}
        {orderDetail?.patient?.mobile &&
          <span>
            <span className={"label-item"}>{t("sg.patient.mobile")}</span>
            <span>{orderDetail?.patient?.mobile || "-"}</span>
          </span>}
        {orderDetail?.patient?.email &&
          <span>
            <span className={"label-item"}>{t("sg.patient.email")}</span>
            <span>{orderDetail?.patient?.email || "-"}</span>
          </span>}
      </div>

    {/*  <RowDelivery orderDetail={orderDetail} order={order}
                   updateDateHandler={updateDateHandler} /> */}{/*da vedere che fine fa*/}

      <div className="list-key-value">
            <span>
              <span
                className={"label-item"}>{t("sg.patient.deliveryNote")}</span>
              {orderDetail?.note || "-"}
            </span>
      </div>

    </section>
  );
}
